import React from 'react';
import style from './successmessage.module.scss'
import formatLocalCurrency from 'utils/formatLocalCurrency'
type dataType = {
    icon: string;
    Total: string;
    "Airtime Amount": string;
    "Phone Number": string;
    "Renewal Status": string;
    "Transaction Reference": string;
};

const data: dataType = {
    icon: "/Transactions/mtn.svg",
    "Airtime Amount": "N1,000",
    "Phone Number": "08011111111",
    "Renewal Status": "Off",
    "Transaction Reference": "7838732738226383",
    Total: "N1,000",
};

const SuccessMessage = ({
    item
}: {
    item: any
}) => {
    const objectKeys = Object.keys(item) as Array<keyof dataType>;

    return (
        <div className='w-[100vw] h-[100vh] flex items-center justify-center'>
            <main className={style.message}>
                <svg className={style.svg} width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.1642 8.74414L8.44604 31.2284" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M30.1642 31.2284L8.44604 8.74414" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <section className={style.image__container}>
                    <img src={data.icon} alt={data['Transaction Reference']} className='w-[6rem] h-[6rem]' />
                </section>
                <section className={style.info__container}>
                    {item.category ? <>
                        <div className={style.info} >
                            <p className={`${style.key} `}>{`${item.category} Amount`}</p>
                            <p className={`${style.right} `}>
                                {`${item.transation_type === 'debit' || item.trasaction_type === 'debit' ? '-' : ""}${item.amount ?
                                    formatLocalCurrency(item.amount) : formatLocalCurrency(item?.transactions_amount)}`}
                            </p>
                        </div>
                        <div className={style.info} >
                            <p className={`${style.key} `}>Phone Number</p>
                            <p className={`${style.right} `}>
                                {
                                    item.transactions_mobile_number  || 'N/A'
                                }

                            </p>
                        </div>
                        <div className={style.info} >
                            <p className={`${style.key} `}>Phone Number</p>
                            <p className={`${style.right} `}>
                                {
                                    item.transactions_mobile_number  || 'N/A'
                                }

                            </p>
                        </div>

                    </> :

                        objectKeys.map((key) => {
                            if (key in item) {
                                return (
                                    key === "icon" ? null : <div className={style.info} key={key}>
                                        <p className={`${style.key} ${key === "Total" ? style.total : ''}`}>{key}</p>
                                        <p className={`${style.right} `}>{data[key]}</p>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </section>
            </main>
        </div>
    );
};

export default SuccessMessage;
