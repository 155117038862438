import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
//import ServiceInputDropdown from 'components/form/Services/DropdownInput';
import ServicesInput from "components/form/Services/Input";
//import ServicesPlainDropdown from 'components/form/Services/PlainDropdown';

import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useSelectAirtimeProviderQuery } from "features/Services/AirtimeService/AirtimeServiceApiSlice";
import {
  setVariations,

} from "features/Services/ServicesSlice";
import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useDisclosure } from "@chakra-ui/react";
import SelectBeneficiaryModal from "components/Beneficiaries/SelectBeneficiaryModal";
import useRenderRenewalStatus from "hooks/useRenderRenewalStatus";

const AirtimeForm = ({
  control,
  setValue,
}: {
  control: any;
  setValue: any;
}) => {
  //dispatch
  const dispatch = useAppDispatch();
  const dataServices = useAppSelector((state) => state.dataService as any);
  const { airtime } = useAppSelector((state) => state.beneficiary as any);
  //Set Item
  const [serviceID, setServiceID] = useState("");
  //variation
  const [variation, setVariation] = useState<any[]>([]);
  const { data } = useSelectAirtimeProviderQuery(serviceID , {
    skip: !serviceID,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const airtimeOptions = dataServices?.dataProviders?.map((item: any) => ({
    ...item,
    value: item.serviceID,
    label: item.name,
    image: item.image,
  }));
  //SELECT PROVIDER
  useEffect(() => {
    if (data) {
      // setDefaultPlan(data.content?.varations[0]?.name)
      setVariation(data.content?.varations);
      //dispatch variation
      dispatch(setVariations(data.content?.varations));
    }
  }, [data, dispatch]);

  const getItem = (item: string) => {
    const service_id = dataServices?.dataProviders?.find(
      (provider: any) => provider.name === item
    )?.serviceID;
    setServiceID(service_id);
  };


  const renewalStatusData = useRenderRenewalStatus();


  return (
    <form className="flex items-center justify-center w-full">
      <div className={style.form}>
        {/* <ServiceInputDropdown
                    rules={{
                        required: 'Field cannot be empty.'
                    }}
                    data={dataServices?.dataProviders?.map((item: any) => item.name)} getItem={getItem} setValue={setValue} control={control} name='serviceID' defaultValue={dataServices[0]?.name} title={'Select Network'} placeholder={'Select network'} /> */}

        <DropdownWithImage
          options={airtimeOptions}
          title={"Select Network"}
          placeholder={"Select network"}
          control={control}
          name={"serviceID"}
        />
        <ServicesInput
          maxLength={11}
          rules={{
            required: "Field cannot be empty.",

            // pattern: {
            //     value: /^N(\d{2,3}(,\d{3})*|\d{1,3}(,\d{3})*\.\d{1,2})$/,
            //     message: 'Amount must be between 50 and 200,000',
            //   },
          }}
          label={"Enter Amount"}
          name={"variation_amount"}
          placeholder={"Enter Airtime Amount"}
          onChange={() => {
            return null;
          }}
          type={""}
          control={control}
        />
        <div className="relative w-full">
          <div className="absolute top-[-1.6875rem] flex justify-between items-center w-full">
            <p className="text-gray-900 text-[0.75rem] font-semibold">
              Min: N50{" "}
            </p>
            <p className="text-gray-900 text-[0.75rem] font-semibold">
              Max: N200,000.00
            </p>
          </div>
        </div>

        <ServicesInput
          setValue={setValue}
          rules={{
            minLength: {
              value: 11,
              message: "Phone number should have at least 11 digits.",
            },
            required: "Field cannot be empty.",
          }}
          maxLength={11}
          defaultValue={airtime}
          label={"Enter Phone Number"}
          name={"billersCode"}
          placeholder={"08011111111"}
          onChange={() => {
            return null;
          }}
          value={""}
          control={control}
        />
        <p className="hover:font-bold cursor-pointer" onClick={onOpen}>
          Select Beneficiary
        </p>
        <DropdownWithImage
          setValue={setValue}
          control={control}
          options={renewalStatusData}
          defaultValue={renewalStatusData[0]}
          name="renewal"
          title={"Renewal Status"}
          placeholder={"Set renewal status"}
        />
      </div>
      <SelectBeneficiaryModal
        category="Airtime"
        isOpen={isOpen}
        onClose={onClose}
      />
    </form>
  );
};

export default AirtimeForm;
