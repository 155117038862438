import {authApi} from 'app/ApiSlice'

const RefreshApiSlice = authApi.injectEndpoints({

    endpoints: (builder) => ({
        refresh: builder.query({
            query: (body) => ({
                url: `/v1/auth/refresh`,
                body,
                method: "POST"
            }),
            providesTags: ['Auth'],
         keepUnusedDataFor: 60 * 60 * 24,



        }),


    })

})


export const {useRefreshQuery} = RefreshApiSlice