import React from 'react'
import style from './currencybutton.module.scss'


const CurrencyButton = () => {
    return (<div className={style.currencybutton}>
        <div className={style.currencybutton__left}>
           <span>₦</span>
        </div>
        <p className={style.currencybutton__right}>
            NGN
        </p>

    </div>)
}

export default CurrencyButton