import React from "react";
import style from "./topmenu.module.scss";

import { useAppDispatch } from "hooks/redux";
import { setSearch  as setSearchAction} from "features/Transactions/TransactionSlice";
import { useDebounce } from "react-use";

const TransactionSearch = () => {
  const [search, setSearch] = React.useState("");





  const dispatch = useAppDispatch();


  const [, cancel] = useDebounce(
    () => {
      dispatch(setSearchAction(search));


    },
    2000,
    [search]
  );







  return (
    <section className={style.transaction__search}>
      <img
        src={"/Transactions/search.svg"}
        alt="search"
        className="w-[1rem] h-[1.5rem]"
      />
      <input
        width={"100%"}
        type="text"
        placeholder="Search anything on Transactions"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </section>
  );
};

export default TransactionSearch;
