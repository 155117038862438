import React from "react";
//data={data} handleSelect={handleSelect}
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Icon,
} from "@chakra-ui/react";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Dropdown = ({
  data,
  handleSelect,
  value,
}: {
  data: string[];
  handleSelect: (item: string) => void;
  value: string;
}) => {
  return (
    // <div id="dropdown" className="cursor-pointer max-w-[12.5rem] md:w-[21.875rem] top-[4rem] left-0 min-h-[10rem] h-max absolute z-50 overflow-y-auto bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
    // {data?.map((item: any) => {
    //   return (
    //     <ul
    //       onClick={() => handleSelect(item)}
    //       className="py-4 text-[1.4rem] text-gray-700 dark:text-gray-200"
    //       aria-labelledby="dropdownDefaultButton"
    //     >
    //       <li>
    //         <p className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
    //           {item}
    //         </p>
    //       </li>
    //     </ul>
    //   );
    // })}
    // </div>
    <Menu>
      <MenuButton
        bg={"var(--gray-bg)"}
        size={"sm"}
        as={Button}
        rightIcon={<Icon as={MdOutlineKeyboardArrowDown} />}
      >
        {value}
      </MenuButton>
      <MenuList>
        {data?.map((item: any) => (
          <MenuItem key={item} onClick={() => handleSelect(item)}>
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
