import React, { useState } from "react";
import style from "./beneficiaries.module.scss";
import Draggable from "react-draggable";
import { useDeleteBeneficiaryMutation } from "features/Beneficiaries/BeneficiariesApiSlice";
import DeleteDialog from "components/Dialog/Dialog";
import { SVGS } from "constants/SVGS";
import ModalContainer from "components/Modals/ModalContainer";
import { useDisclosure } from "@chakra-ui/react";

const Beneficiary = ({ item }: { item: any }) => {
  const [isSwapped, setIsSwapped] = useState(false);
  const [deleteBeneficiary, { isSuccess, isLoading }] =
    useDeleteBeneficiaryMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleStop = (e: any, data: any) => {
    const threshold = 100;

    if (Math.abs(data.x) > threshold) {
      setIsSwapped(true);
      onOpen();
    }
  };

  //   onClick: () => void;
  // handleClose: () => void;
  // isLoading: boolean;
  // message: String;
  // message2?: String | undefined;

  const handleClose = () => {
    setIsSwapped(false);
    onClose();
  };

  // HANDLE DELETE CLICK
  const onClick = async () => {
    const res = await deleteBeneficiary({
      beneficiary_number: item.number,
    }).unwrap();

    if (res) {
      handleClose();
    }

    try {
    } catch (err: any) {
      console.log(err);
      handleClose();
    }
  };

  return (
    <div>
      {isSwapped && !isSuccess ? (
        <ModalContainer onClose={onClose} isOpen={isOpen} size="lg">
          <DeleteDialog
            message2={"Are you sure you want to delete?"}
            isLoading={isLoading}
            message={"Delete Beneficiary"}
            onClick={onClick}
            handleClose={handleClose}
          />
        </ModalContainer>
      ) : null}
      <Draggable position={{ x: 0, y: 0 }} onStop={handleStop} axis="x">
        <div className={style.item}>
          <div className={style.item__left}>
            <img
              src={
                SVGS[item.category as string]
                  ? SVGS[item.category as string]
                  : "/Beneficiaries/thunder.svg"
              }
              alt={item.name}
            />
          </div>
          <div className={style.item__right}>
            <div className={style.item__top}>
              <h3>{item.number}</h3>
              <p>{item.provider}</p>
            </div>
            <div className={style.item__bottom}>
              <p>{`${item.name} | ${item.time}`}</p>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default Beneficiary;
