import style from './nameinfo.module.scss'
import { Link } from 'react-router-dom'
import {MdOutlineArrowForwardIos} from 'react-icons/md'
import React from 'react'




const TitleLink = ({
    textColor , title , path
}: {
    textColor?: string, title: string , path: string

}) => {


    return (

            <div className={style.header} >
                <h3>
                    {title}
                </h3>
                <Link to={path} style={{color: textColor}}>
                    <span>View All </span>

                        <MdOutlineArrowForwardIos />


                   </Link>



            </div>


    )

 }

 export default TitleLink;