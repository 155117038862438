import React, { useState } from "react";
import TransactionInfo from "components/nameinfo/TransactionInfo";
import style from "./transaction.module.scss";
import Modal from "components/successpage/Modal";
import SuccessMessage from "./SuccessMessage";
import CurrencyConverter from "utils/CurrencyConverter";
import formatDateToDDMMYYYY from "utils/formatDDMMYYYY";
import { Services } from "dashboardIcons";
import { Text } from "@chakra-ui/react";
// {
//     img : '/Transactions/software.svg',
//     name: 'Figma Subscription',
//     company :'Apple. Inc',
//     type : 'Mobile',
//     amount : '10000',
//     date : '14 Apr 2022',
//     time   : 'at 8:00 PM',
//     reference : '123456789',
//   },
interface Prop {
  item: any;
  handleClick: () => void;
}
const Item: React.FC<Prop> = ({ item, handleClick = () => {} }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const svg: any = {
    "Airtime": '/Services/sv-1.svg',
    "Data": '/Services/sv-2.svg',
    "Internet": '/Services/sv-3.svg',
    "Electricity": '/Services/sv-4.svg',
    "Cable": '/Services/sv-5.svg',
    "Gift": '/Services/sv-6.svg',
    "Wallet": '/Services/sv-7.svg',
    "Education": '/Services/sv-7.svg',
    "Insurance": '/Services/sv-8.svg',
    // "Airtime": '/Services/sv-9.svg',
    "Shop": "/Services/sv-10.svg",


  }
  const  serviceType: string = item.service_type?.split('|')[0] as string




  return (
    <>
      <div className={style.list_item}>
        <TransactionInfo
          borderRadius="five"
          title={item.category}
          description={item.service_type}
          image={
            svg[serviceType] ? svg[serviceType] : svg['Education']
          }

        />
        <div className={style.date_and_time}>
          <p className={style.date}>{formatDateToDDMMYYYY(item?.created_at)}</p>
          <p className={style.time}>
            {/* get time from item.created_at using number formatter */}
            {item.created_at && new Date(item.created_at).toLocaleTimeString()}
          </p>
        </div>

        <p className={style.amount}>{CurrencyConverter(item.amount)}</p>

        <Text textTransform={'capitalize'} className={`${item.status}`}>{item.status}</Text>
      </div>

      {open ? (
        <Modal handleClose={handleClose} open={open}>
          <SuccessMessage item={item} />
        </Modal>
      ) : null}
    </>
  );
};

export default Item;
