import { authApi } from "app/ApiSlice";


const subscriptionApi = authApi.injectEndpoints({
    endpoints: (builder) => ({


        createSubscription: builder.mutation({
            query: (data) => ({
                url: '/v1/subscription/subscribe',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Subscriptions']
        }),



        getSubscriptions: builder.query({
            query: () => ({
                url: '/v1/payment/subscription',
                method: 'GET'
            }),
            providesTags: ['Subscriptions']
        }),



        getSubscription: builder.query({
            query: (id) => `/v1/subscription/subscribe/${id}`,
        }),



        updateSubscription: builder.mutation({
            query: (data) => ({
                url: '/v1/subscription/subscribe',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Subscriptions']
        }),



        deleteSubscription: builder.mutation({
            query: (data) => ({
                url: `/v1/subscription/delete`,
                method: 'DELETE',
                body: data
            }),
            invalidatesTags: ['Subscriptions']
        })
    })
})

export const {
    useCreateSubscriptionMutation,
    useUpdateSubscriptionMutation,
    useDeleteSubscriptionMutation,
    useGetSubscriptionQuery,
    useGetSubscriptionsQuery,
} = subscriptionApi