import React, { useState, useContext, useEffect } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import Card from "components/creditcard/Card";
import ActionText from "components/ActionText/ActionText";

import {
  Icon,
  Center,
  Flex,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";


import { FaPlus } from "react-icons/fa";
import NewCardModal from "./NewCardModal";
import { useAppSelector } from "hooks/redux";
import { Grid } from "@chakra-ui/react";
import { FaCreditCard } from "react-icons/fa";

const MyCardsContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { paymentOptions = {} as any } = useAppSelector(
    (state) => state.wallet
  );
  const [cards, setCards] = useState<any[]>([]);

  useEffect(() => {
    if (paymentOptions) {
      //unique cards only by signature
      const uniqueCards = paymentOptions?.cards?.information.filter(
        (item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.signature === item.signature)
      );
      setCards(uniqueCards);
    }
  }, [paymentOptions]);

  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);
  const [defaultCard, setDefaultCard] = useState<string>("");
  const handleSetCard = (id: string) => {
    setDefaultCard(id);
  };

  useEffect(() => {
    setCurrentPagename("Saved Debit Cards");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FFF") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FFF") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);

  console.log(cards, "cards");

  return (
    <div>
      {cards?.length < 1 ? (
        <Flex
          justify={"center"}
          flexDir={"column"}
          height={{
            base: "10rem",
            lg: "15rem",
            "2xl": "20rem",
          }}
          gap={"1rem"}
        >
          <Center bg={"white"} padding={"2rem"}>
            <Icon
              as={FaCreditCard}
              fontSize={"5rem"}
              color={"var(--primary)"}
            />
          </Center>
          <Center>
            <Text
              fontSize={{
                base: "1.3rem",
                lg: "1.5rem",
                "2xl": "2rem",
              }}
            >
              You don't have any saved debit cards
            </Text>
          </Center>
        </Flex>
      ) : null}
      <Grid
        as={"main"}
        className=" py-[2.875rem] px-[2.5rem]"
        gap={{
          base: "1rem",
          lg: "2rem",
        }}
        gridTemplateColumns={{
          base: "repeat(1, minmax(0 , 1fr))",
          lg: "repeat(2, minmax(0 , 1fr))",
        }}
      >
        {cards?.map((item: any, index: number) => (
          <Card
            key={index}
            {...item}
            expiry_date={item?.exp_month + " / " + item.exp_year}
            account={"XXXX XXXX XXXX " + item.last4}
            name={item.account_name || "N/A"}
            onClick={handleSetCard}
            value={defaultCard}
            signature={item.signature}
          />
        ))}
      </Grid>

      {cards?.length > 0 ? (
        <div className="grid place-content-center py-[2.5rem] md:py-[3.813rem]">
          <ActionText text={"Swap to Delete"} />
        </div>
      ) : null}
      <Flex
        justify={"flex-end"}
        px={{
          base: "2rem",
          lg: "3rem",
          "2xl": "4rem",
        }}
      >
        <Button
          bg={"var(--primary)"}
          color={"var(--white)"}
          colorScheme="messenger"
          borderRadius={"50%"}
          height={{
            base: "3rem",
            lg: "4rem",
            "2xl": "5rem",
          }}
          width={{
            base: "3rem",
            lg: "4rem",
            "2xl": "5rem",
          }}
          onClick={onOpen}
        >
          <Icon as={FaPlus} fontSize={"2xl"} color={"text-primary"} />
        </Button>
      </Flex>
      <NewCardModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
};

export default MyCardsContainer;
