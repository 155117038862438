import React from 'react'
// import Modal from 'components/successpage/Modal'

const DeleteDialog = ({
    onClick, handleClose , isLoading , message , message2
}: {
    onClick: () => void;
    handleClose : () => void;
     isLoading: boolean
    message: String
    message2?: String

}) => {
  return (


                <div className="p-[2rem] lg:px-[4rem] py-[4rem] text-center">
                    <h1 className='text-gray-900 text-[1.5rem] mb-5'>{message}</h1>
                    <h3 className="mb-10 lg:mb-20 text-[1rem] font-normal text-gray-500 dark:text-gray-400">{message2}</h3>
                    <div className='flex justify-between gap-[2rem]'>
                        <button onClick={onClick} data-modal-hide="popup-modal" type="button" className="text-white bg-red hover:opacity-[.8] focus:ring-4 focus:outline-none font-normal rounded-lg text-[1rem] inline-flex items-center px-4 py-2 text-center mr-2">
                            {isLoading ? "Deleting..." : `Delete`}
                        </button>
                        <button onClick={handleClose} data-modal-hide="popup-modal" type="button" className="px-4 py-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-[1rem] font-normal hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">cancel</button>
                    </div>
                </div>





  )
}

export default DeleteDialog