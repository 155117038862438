import React, { useEffect } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router/Router';


function App() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  useEffect(() => {

    scrollToTop()
  }, []);

  return (
    <RouterProvider router={router} />

  );
}


export default App;
