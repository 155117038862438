import React  from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
import { RxCalendar } from "react-icons/rx";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Text,
  Icon,

  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { FaRegFileImage } from "react-icons/fa";



const FileInput = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  setValue ,
  open,
  rules,
}: {
  control: any;
  defaultValue: any;
  name: string;
  label: string;
  placeholder?: string;
  setValue?: (value: any  , name : string) => void;
  open?: boolean
  rules?: any
}) => {

  const {formState:{
    errors
  }, fieldState: {
    error
  } ,field: {
    onChange , value ,
  } } = useController({
    control,
    name,
    // defaultValue,
    rules,





  });







  return (
    <FormControl>
      <Grid>

        <FormLabel>
          <Text color={'var(--dark-text'} fontWeight={'500'} fontSize={'.875rem'}>{label}</Text>
        </FormLabel>
    <FormControl isInvalid={
        error?.message ? true : false
    }>
        <InputGroup>
        <Input

           onChange={onChange}
           value={value}
          _placeholder={{
            color: 'var(--text-1)',
            fontWeight:'500'
          }}

          color = {value? 'var(--dark-text)': 'var(--text-1)'}

          borderColor={value  ? "var(--primary)" : "#F2F2F2"}
          fontSize={'.875rem'}
          borderRadius={'0.625rem'}
          fontWeight={'500'}
          className="w-full rounded-[.625rem]  hover:border-primary border "

          type="file"
          accept="image/*"
          multiple={false}
          size={'lg'}
          pt={'.5rem'}
          focusBorderColor="#246BFD"


        />
        <InputRightElement>
          <Icon as={FaRegFileImage} color={'#A2A2A8'}  />
        </InputRightElement>



</InputGroup>
        <FormErrorMessage >
            {error?.message}
        </FormErrorMessage>
        </FormControl>



      </Grid>
    </FormControl>
  );
};


export default FileInput