import React from 'react'
import style from './banner.module.scss'
import { motion } from 'framer-motion'

const bannerImages: Array<string> = [
    '/Banner/banner1.png',
    '/Banner/banner2.png',

]
const bannerImagesMobile: Array<string> = [
    '/Banner/banner_mobile1.png',
    '/Banner/banner_mobile2.png',

]



function Banner() {
    const [index, setIndex] = React.useState<number>(0)
    const handlePrev = () => {
        if (index > 0) setIndex(index => index - 1)

    }
    const handleNext = () => {
        if (index < (bannerImages.length - 1)) setIndex(index => index + 1)



    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (index < (bannerImages.length - 1)) {
                setIndex(index => index + 1)
            } else {
                setIndex(0)
            }
        }, 5000)
        return () => clearInterval(interval)


    }, [index])

    const selectCurrent = () => {
        setIndex(index)

    }




    return (
        <section className={style.banner}>
            <div className={style.image__container}>
                <motion.img

                    src={bannerImages[index]}
                    className={`${style.image} hidden md:block `}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}


                />
                <motion.img

                    src={bannerImagesMobile[index]}
                    className={`${style.image} md:hidden block `}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}


                />


            </div>
            <div className={'absolute grid grid-cols-2 gap-1 bottom-[1.25rem] left-[3.125rem] md:bottom-[2.1875rem] md:left-[5.625rem] z-10 cursor-pointer'}>
  {
    bannerImages.map((image, idx) => {
      return (
        idx === index ? <p onClick={selectCurrent} className='bg-white h-[0.8rem] w-9 rounded'></p> :
          <p onClick={selectCurrent} className='bg-white h-[0.8rem] w-[0.8rem] rounded-full'></p>
      )
    })
  }
</div>

            <div className={style.next__and__prev}>

                <div className={style.left} onClick={handlePrev}>
                    <div className={style.prev__btn}>
                        <div >
                            <img src={'/GiftCard/prev.svg'} alt="prev" />
                        </div>

                    </div>
                </div>
                <div className={style.right} onClick={handleNext}>
                    <div className={style.next__btn}>
                        <div>
                            <img src={'/GiftCard/next.svg'} alt="next" />
                        </div>

                    </div>
                </div>



            </div>

        </section>
    )
}

export default Banner


