import React  from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
import { RxCalendar } from "react-icons/rx";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  Text,
  Icon,

  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { useEffect } from "react";


const DateInput = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  setValue ,
  open,
  disabled
}: {
  control: any;
  defaultValue: any;
  name: string;
  label: string;
  placeholder?: string;
  setValue?: (value: any  , name : string) => void;
  open?: boolean
  disabled?: boolean
  // defaultValue: any;
}) => {

  const { field: {
    onChange , value ,
  } } = useController({
    control,
    name,
    // defaultValue,





  });







  return (
    <FormControl>
      <Grid>

        <FormLabel>
          <Text color={'var(--dark-text'} fontWeight={'500'} fontSize={'.875rem'}>{label}</Text>
        </FormLabel>

          <Grid pos={'relative'}>
        <Input
          as={DatePicker}
          placeholderText={'Select Date'}
           onChange={onChange}
           value={value}
          selected={value ? value : defaultValue? new Date(defaultValue) : new Date()}

          _placeholder={{
            color: 'var(--text-1)',
            fontWeight:'500'
          }}
          dateFormat={'dd/MM/yyyy'}
          color = {open? 'var(--dark-text)': 'var(--text-1)'}

          borderColor={value && open ? "var(--primary)" : "#F2F2F2"}
          fontSize={'.875rem'}
          borderRadius={'0.625rem'}
          fontWeight={'500'}
          className="w-full rounded-[.625rem]  hover:border-primary border "
          padding={'1.25rem 25px 1.25rem 25px'}
          maxDate={new Date()}
          isDisabled= {disabled}




        />

          <Icon right={'5'} bottom={'30%'} as={RxCalendar} color={'#A2A2A8'} pos={'absolute'} />

        </Grid>



      </Grid>
    </FormControl>
  );
};


export default DateInput