function maskString(input: string): string {
  if (input === undefined || input === null || input === '') {
    return "#X.XX";
  }

  // Remove commas from the input string
  input = input.replace(/,/g, '');

  // Extract the first character and the portion after the decimal point
  const firstChar = input.charAt(0);
  const decimalIndex = input.indexOf(".");
  const integerPart = decimalIndex !== -1 ? input.slice(1, decimalIndex) : input.slice(1);
  const decimalPart = decimalIndex !== -1 ? input.slice(decimalIndex) : "";

  // Create masks for the integer and decimal parts
  const integerMask = "X".repeat(integerPart.length);
  const decimalMask = "X".repeat(decimalPart.length - 1); // Exclude the decimal point from masking

  // Combine the first character, integer mask, decimal point (if it exists), and decimal mask (if it exists)
  return firstChar + integerMask + (decimalIndex !== -1 ? "." : "") + decimalMask;
}

export default maskString;
