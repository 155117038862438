import React from 'react';
import style from './menu.module.scss'
import { SidebarData } from '../sidebar/data';
import NavLink from '../links/NavLink';


const Menu = () => {
    //slice from 0 to the last 2 items
    const len = SidebarData.length
    const lastSet = SidebarData.slice(len-2, len)

    const firstSet = SidebarData.slice( 0 , len -2 )

    return (
        <section className={style.menu}>
            <section className={style.menu__first}>
                {firstSet.map((item, index) => (
                    <NavLink key={index} {...item} />
                ))}
             </section>
             <section className={style.menu__last}>
                {lastSet.map((item, index) => (
                    <NavLink key={index} {...item} />
                ))}
             </section>

            </section>
    )




}

export default Menu;