// TableBody.tsx
import React from 'react';
import formatLocalCurrency from 'utils/formatLocalCurrency'
import formatTimestamp from 'utils/formatTimestamp'
// import styles from './body.module.scss'
import NameInfo from 'components/nameinfo/NameInfo';







type TableBodyProp = {

    name: string,

    amount: string,

    date: string,
    image?: string,

}

interface TableBodyProps {
    data?: TableBodyProp[];

}


const TableBody: React.FC<TableBodyProps> = ({ data }) => {





    return (

        data?.length === 0 ? null : <section className='pb-[.93rem] flex flex-col  gap-[.75rem]'>
            {data?.map((item: any, index: number) => (

                <Tr item={item} key={index} />
            ))}
        </section>
    );
};

export default TableBody;







const Tr = ({ item }: {

    item: {
        first_name: string,
        last_name: string

        user_image?: string,
        description?: string,
        amount: string,
        type?: string,
        created_at: string


    }
}) => {





    return (
        <>
            <div className={`flex items-center justify-between pb-[.93rem] border-bottom border-[#FAFAFA] border-b-[1px]`}>
                <div>
                    <NameInfo title={`${item?.first_name} ${item?.last_name}`} description={formatTimestamp(item?.created_at)} image={item?.user_image as string} />

                </div>
                <div className={`text-[1rem] text-red text-right font-[600] `} data-th="Amount">{`-${formatLocalCurrency(item?.amount)}`}</div>
            </div>




        </>
    )
}