import React from "react";
import style from "./fundingsummary.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import CurrencyConverter from "utils/CurrencyConverter";
import { setAmountToFund } from "features/Wallet/WalletSlice";

interface summary {
  title: string;
  buttonText: string;
  onClick?: () => void;
}

const FundingSummary: React.FC<summary> = ({
  title,
  buttonText,
  onClick = () => {},
}) => {
  const { cart } = useAppSelector((state) => state.giftCardService);
  //reduce cart to get total - quantity * price
  const total = cart.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(setAmountToFund(CurrencyConverter(total)));
  }, [total, dispatch]);

  return (
    <div className="p-[1rem] lg:p-[2rem] bg-white rounded-[0.2rem] border border-[#D1D1D8]">
      <h3 className={style.fundingsummary__title}>{title}</h3>
      <section className={style.fundingsummary__section}>
        <div className="flex items-center justify-between py-[2rem] border-b border-b-[#D1D1D8]">
          <p>Amount</p>
          <h4>{CurrencyConverter(total)}</h4>
        </div>

        <div className="flex items-center justify-between py-[2rem]">
          <p className={style.total}>TOTAL</p>
          <h4>{CurrencyConverter(total)}</h4>
        </div>

        <button className={style.fundingsummary__button} onClick={onClick}>
          {buttonText}
        </button>
      </section>
    </div>
  );
};

export default FundingSummary;
