import {toast} from 'react-hot-toast'

function copyToClipboard(text: string): void {
    // Create a text area element to hold the text to be copied
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Set the text area to be invisible
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";

    // Append the text area to the document
    document.body.appendChild(textarea);

    // Select the text in the text area
    textarea.select();

    try {
      // Execute the copy command
      document.execCommand("copy");
      toast.success('Copied to clipboard!' , {
        position:  'top-center',
        duration: 2000

      })
    } catch (err) {
      console.error("Unable to copy text to clipboard:", err);
    } finally {
      // Remove the temporary text area
      document.body.removeChild(textarea);
    }
  }

  export default copyToClipboard
