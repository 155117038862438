
import  { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useRenderRenewalStatus = () => {
  const location = useLocation();
  const { pathname } = location;
  const [status, setStatus] = useState<any[]>([
    { value: "Off", label: "Off" },
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "3 Months", label: "3 Months" },
    { value: "6 Months", label: "6 Months" },
    { value: "12 Months", label: "12 Months" },
  ]);

  useEffect(() => {
    // includes /subscriptions
    if (pathname.includes("subscriptions")) {
      // do something
      //remove the first item in status
      setStatus([
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
        { value: "3 Months", label: "3 Months" },
        { value: "6 Months", label: "6 Months" },
        { value: "12 Months", label: "12 Months" },
      ]);
    } else {
      setStatus([
        { value: "Off", label: "Off" },
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
        { value: "3 Months", label: "3 Months" },
        { value: "6 Months", label: "6 Months" },
        { value: "12 Months", label: "12 Months" },
      ]);
    }
  }, [pathname]);

  return status
};

export default useRenderRenewalStatus;
