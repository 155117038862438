export const SVGS: Record<string, string> = {
    "Airtime": '/Services/sv-1.svg',
    "Data": '/Services/sv-2.svg',
    "Internet": '/Services/sv-3.svg',
    "Electricity": '/Services/sv-4.svg',
    "Cable TV": '/Services/sv-5.svg',
    "Gift Card": '/Services/sv-6.svg',
    "Wallet Funding": '/Services/sv-7.svg',
    "Education": '/Services/sv-7.svg',
    "Insurance": '/Services/sv-8.svg',
    "Aitime To Cash": '/Services/sv-9.svg',
    "Shopping": "/Services/sv-10.svg",
    "Wallet": "/Services/sv-7.svg",

  };