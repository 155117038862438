
const PhoneNumberFormatter = (phoneNumber: string): string => {
    // Remove any characters that are not digits or '+'
    const formattedNumber = phoneNumber.replace(/[^0-9+]/g, '');

    // Limit the length to 11 characters
    return formattedNumber.slice(0, 11);
  };




export default PhoneNumberFormatter;