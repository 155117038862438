

import style from './services.module.scss'
import Categories from '../categories/Categories'
import React, { useContext, useEffect } from 'react'
import DashboardContext from 'context/Dashboard/DashboardContext'
import { useSearchParams } from 'react-router-dom'
import DataService from './Data'
import Airtime from './Airtime'
import CableService from './CableTV'
import ElectricityService from './Electricity'
import InsuranceService from './Insurance'
import EducationService from './Eduction'
import GiftCardContainer from './GiftCard/GiftCardContainer'
import InternetService from './Internet'
import Heading from './Heading/Heading'


type dataType = {
   bgColor: string;
   icon: any;
   title: string
}

const data: dataType[] = [
   {
      title: "Airtime",
      icon: '/Services/sv-1.svg',
      bgColor: 'rgba(122, 85, 72, 0.12)'
   },
   {
      title: "Data",
      icon: '/Services/sv-2.svg',
      bgColor: 'rgba(0, 188, 211, 0.12)'
   },
   {
      title: "Internet",
      icon: '/Services/sv-3.svg',
      bgColor: 'rgba(255, 152, 31, 0.12)'

   },
   {
      title: "Electricity",
      icon: '/Services/sv-4.svg',
      bgColor: 'rgba(255, 211, 0, 0.12)'

   },
   {
      title: "Cable TV",
      icon: '/Services/sv-5.svg',
      bgColor: 'rgba(157, 40, 172, 0.12)'

   },
   {
      title: "Gift Card",
      icon: '/Services/sv-6.svg',
      bgColor: 'rgba(103, 58, 179, 0.12)'

   },

   {
      title: "Education",
      icon: '/Services/sv-7.svg',
      bgColor: 'rgba(0, 150, 137, 0.12)'

   },
   {
      title: "Insurance",
      icon: '/Services/sv-8.svg',
      bgColor: 'rgba(74, 175, 87, 0.12)'

   },
   {
      title: "Airtime to Cash",
      icon: '/Services/sv-9.svg',
      bgColor: 'rgba(96, 125, 138, 0.12)'

   },
   {
      title: "Shopping",
      icon: '/Services/sv-10.svg',
      bgColor: 'rgba(255, 192, 45, 0.12)'

   },




]
const SubscriptionsContainer = () => {
   const {
      setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
   } = useContext(DashboardContext)
   let [searchParams,] = useSearchParams();
   const category = searchParams.get('category')

   useEffect(() => {
      let category
      if (category === undefined || category === null || category === "") {
         setCurrentPagename("Subscriptions")
      }





      setCurrentPagename("Subscriptions")
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setBgContentColor ? setBgContentColor("#FAFAFA") : null
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null

   }, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor, category])



   let component = <main className={style.servicescontainer} >
      <div className='grid place-content-center'>
         <div className='max-w-[63ch] '>
            <Heading title={'Subscriptions'} desc={'Making payments has never been easier. '} desc2='
Set up all reoccurring payments and let JaraPay do the rest.' />
         </div>
      </div>
      <Categories title='' data={data.slice(0, 5)} />


   </main>

   if (category === "data") {
      component = <DataService />
   }
   if (category === "airtime") {
      component = <Airtime />
   }

   if (category === "cable tv") {
      component = <CableService />
   }
   if (category === "electricity") {
      component = <ElectricityService />

   }
   if (category === "insurance") {
      component = <InsuranceService />


   }
   if (category === "education") {
      component = <EducationService />


   }
   if (category === "gift card") {
      component = <GiftCardContainer />
   }
   if (category === "internet") {
      component = <InternetService />
   }







   return (
      component
   )
}


export default SubscriptionsContainer;