import React from 'react'
import style from './backbutton.module.scss'
import { Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const BackButton = ({
    text, link
}: {
    text: string,
    link?: string
}) => {


    return (
        <Link to={link ? link : ''} className={style.backbutton_container}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.3334 14.3198C23.3334 14.7628 23.0042 15.1289 22.5771 15.1868L22.4584 15.1948L4.95837 15.1948C4.47512 15.1948 4.08337 14.8031 4.08337 14.3198C4.08337 13.8768 4.41255 13.5108 4.83964 13.4528L4.95837 13.4448L22.4584 13.4448C22.9416 13.4448 23.3334 13.8366 23.3334 14.3198Z" fill="#212121" />
                <path d="M12.6339 20.7281C12.9763 21.0691 12.9775 21.6231 12.6365 21.9656C12.3266 22.2769 11.8405 22.3062 11.4974 22.0527L11.3991 21.9682L4.34076 14.9402C4.02852 14.6293 4.00011 14.1415 4.25556 13.7984L4.34071 13.7002L11.399 6.67102C11.7415 6.33002 12.2955 6.33116 12.6365 6.67358C12.9465 6.98487 12.9737 7.47103 12.7188 7.81305L12.6339 7.91101L6.19855 14.3205L12.6339 20.7281Z" fill="#212121" />
            </svg>

            <Text as={'span'}
            maxWidth={{lg:'30ch' , base:'25ch' , '2xl':'35ch'}}
            >{text}</Text>

        </Link>
    )


}

export default BackButton