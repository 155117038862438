import React from "react";
// import Modal from 'components/successpage/Modal'
import { Button } from "@chakra-ui/react";




const SignOut = ({
  signOut,
  handleClose,
  isLoading,
}: {
  signOut: () => void;
  handleClose: () => void;
  isLoading: boolean;
}) => {
  return (



            <div className="p-[20px] lg:px-[60px] py-[40px] text-center">
              <h1 className="text-gray-900 text-[24px] mb-5  ">
                Sign out of Jarapay
              </h1>
              <h3 className="mb-10 lg:mb-20 text-[1rem] font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to sign out?
              </h3>
              <div className="flex justify-between gap-8  items-center">
                <Button
                  colorScheme="messenger"
                  bg={"var(--primary)"}
                  onClick={signOut}
                  data-modal-hide="popup-modal"
                  type="button"
                  isLoading={isLoading}
                  loadingText="Signing out..."
                  className="text-white bg-primary hover:bg-primary-300 focus:ring-4 focus:outline-none  font-normal rounded-lg text-[1rem] inline-flex items-center px-6 py-4 text-center mr-2"
                >
                  { `Yes, I'm sure`}
                </Button>
                <Button
                  variant={"outline"}
                  colorScheme="red"
                  onClick={handleClose}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="px-10 py-4 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-[1rem] font-normal  hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </Button>
              </div>
            </div>


  );
};

export default SignOut;
