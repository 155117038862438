import React from 'react'
import Beneficiaries from 'container/Beneficiaries/BeneficiariesContainer'
import OtherDashboard from 'container/dashboard/OtherDashboard'

const BeneficiariesPage = () => {
  return (
    <OtherDashboard>
        <Beneficiaries />
    </OtherDashboard>


  )
}

export default BeneficiariesPage