import React, { useEffect, useContext, } from 'react'
import DashboardContext from 'context/Dashboard/DashboardContext'
import style from './beneficiaries.module.scss'
import Beneficiary from './Beneficiary'
import ActionText from 'components/ActionText/ActionText'
import { useAppSelector } from 'hooks/redux'




const Beneficiaries = () => {

  const {
    setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
  } = useContext(DashboardContext)




  useEffect(() => {
    setCurrentPagename('Saved Beneficiaries')
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FAFAFA") : null
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null

  }, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor])

  const beneficiary = useAppSelector((state: any) => state.beneficiary)




  return (
    <div className={style.beneficiaries}>
      <main className={style.beneficiaries__container} >
        {
          beneficiary?.beneficiary?.map((item: any, index: number) => <Beneficiary key={index} item={item} />)
        }
      </main>
      <div className='grid place-content-center py-[2.5rem] md:py-[3.8125rem]'>
        {beneficiary?.beneficiary ? <ActionText text={'Swipe left to delete'} /> : null}
      </div>
    </div>
  )
}

export default Beneficiaries