import ReactPaginate from  'react-paginate'
import styles from './pagination.module.scss'
import React from 'react'


export default function Pagination({pageCount, onPageChange}:any) {
    return (
    <ReactPaginate
    previousLabel={'Prev'}
    nextLabel={'Next'}
    pageCount={pageCount}
    onPageChange={onPageChange}
    containerClassName={styles.pagination}
    previousLinkClassName={styles.previous}
    nextLinkClassName={styles.next}
    disabledClassName={styles.disabled}
    activeClassName={styles.active}
    pageClassName={styles.page}
    breakLabel={'...'}
    breakClassName={styles.break}
    pageLinkClassName={styles.page_link}




    />
  );
}