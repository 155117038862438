import LeftMenu from "components/topmenu/leftmenu/LeftMenu";
import RightMenu from "components/topmenu/rightmenu/RightMenu";
import style from './topmenu.module.scss'
import { useContext, useState, useEffect } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import { MdMenu, MdClose } from "react-icons/md";
import Sidebar from "components/sidemenu/sidebar/SideBar";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import TransactionSearch from "./TransactionSearch";
import {useAppSelector} from 'hooks/redux'
import {
    Drawer,
    DrawerBody,
    useDisclosure,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Box,
  } from '@chakra-ui/react'



const TopMenu = () => {
    const {user} = useAppSelector(state => state.auth as any)

    const { currentPagename } = useContext(DashboardContext);

    const handleClick = (e: any) => {
        e.stopPropagation();
        onOpen()


    }

    const {isOpen , onClose , onOpen} = useDisclosure()





    return (
        <div>


        <div className={style.topmenu}>
            <section className={style.topmenu__mobile}>
                {
                    isOpen ? <MdClose size="30" color="#929EAE" onClick={handleClick} /> : <MdMenu color="#929EAE" size="30" onClick={handleClick} />

                }
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />


                        <DrawerBody>
                            <Sidebar />
                        </DrawerBody>


                    </DrawerContent>
                </Drawer>
                {/* <AnimatePresence>
                    {open && (
                        <motion.div
                            className={style.sidemenu}
                            initial={{ x: '-100%' }}
                            animate={{ x: '0%' }}
                            exit={{ x: '-100%' }}
                            transition={{ duration: 0.7 }}

                        >
                            <Box  h={"100%"} minH={'max-content'}  >
                                <Box   overflow={'auto'}    >

                            <Sidebar />
                            </Box>

                            </Box>
                        </motion.div>
                    )}
                </AnimatePresence> */}

            </section>

            <LeftMenu name={currentPagename} />
            <RightMenu name={`${user?.data?.first_name ? user?.data?.first_name: ''  }  ${user?.data?.last_name  ? user?.data?.last_name : ''}`} image={user?.data?.user_image } />
        </div>
        {
            currentPagename === "Transactions" ? <TransactionSearch /> : null
        }
        </div>
    )

}


export default TopMenu;