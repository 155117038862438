/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react'
import DashboardContext from 'context/Dashboard/DashboardContext'
import { useForm } from 'react-hook-form'
import { useVerifyBvnMutation } from 'features/BVN/BvnApiSlice'
import Form from './Form/Form'
import { Toaster, toast } from 'react-hot-toast'
import LoadingSpinner from 'components/Spinner/Spinner'
import formatDateToDDMMYYYY from 'utils/formatDDMMYYYY'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@chakra-ui/react'

const BvnVerification = () => {
    const {
        setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
    } = useContext(DashboardContext)

    useEffect(() => {
        setCurrentPagename('Verify BVN')
        setBgContentColor ? setBgContentColor("#FAFAFA") : null
        setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
        setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null
    }, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor])

    const { handleSubmit, control } = useForm({
        defaultValues: {
            date_of_birth: '',
            bvn: ''
        }
    })

    const [bvn, setBvn] = useState('')

    const onChange = (e: any) => {
        setBvn(e.target.value)
    }

    const navigate = useNavigate()
    const [verifyBvn, { isLoading }] = useVerifyBvnMutation()

    const onSubmit = async (data: any) => {
        try {
            const res = await verifyBvn({
                bvn: data.bvn,
                date_of_birth: formatDateToDDMMYYYY(data.date_of_birth)
            }).unwrap()
            if (res?.data?.virtual_account) {
                toast.success('Successfully verified BVN')
                navigate('/')
            }
        } catch (error) {
            toast.error('Verification failed. Please check details and try again')
        }
    }

    return (
        <>
            <Toaster containerStyle={{
                fontSize: '1rem'
            }}/>
            {
                isLoading ? <LoadingSpinner /> : null
            }
            <form onSubmit={handleSubmit(onSubmit)} className='grid place-content-center place-items-center pt-[2rem] 2xl:pt-[4rem]'>
                <h1 className='text-gray-900 font-bold text-center text-[1.2rem] mb-[25px] 2xl:mb-[42px] lg:text-[2rem]'>
                    Verify your BVN
                </h1>
                <section className='mb-[30px]'>
                    <Form bvn={bvn} control={control} onChange={onChange} />
                </section>
                <Grid placeContent={'center'} placeItems={'center'}>
                    <button type="submit" className='btn-primary' >Get Virtual Account</button>
                </Grid>
            </form>
        </>
    )
}

export default BvnVerification
