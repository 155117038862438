import React, { useEffect, useContext } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import style from "./rewards.module.scss";
import Header from "components/Transactions/Header";
import RewardList from "components/Transactions/RewardList";
import { header, data } from "./data";
import RedeemCard from "components/creditcard/RedeemCard";
import { useGetRewardsQuery } from "features/Profile/ProfileApiSlice";
import { useAppDispatch } from "hooks/redux";
import { setRewardsData } from "features/Profile/ProfileSlice";

import { Grid, Box } from "@chakra-ui/react";
import LoadingSpinner from "components/Spinner/Spinner";

const RewardsContainer = () => {
  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  const { data: rewards, isLoading } = useGetRewardsQuery(undefined);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (rewards) {
      dispatch(setRewardsData(rewards));
    }
  }, [rewards, dispatch]);

  useEffect(() => {
    setCurrentPagename("Reward & Referral");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FFF") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FFF") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);
  return (
    <Box as="main"   py={"2rem !important"} className={style.container}>
      {isLoading ? <LoadingSpinner /> : null}
      <RedeemCard
        amount={rewards?.data?.reward_wallet?.balance}
        referral_id={rewards?.data?.referral_id}
      />
      <Grid

        maxW={{ base: "90vw", lg: "100%" }}
        overflowX={"auto"}
        className="grid w-full pt-[3.4375rem] pl-[1.5rem] md:pl-[4.6875rem] gap-[0.625rem]  "
      >
        <Header data={header} />
        <RewardList handleClick={() => {}} data={data} />
      </Grid>
    </Box>
  );
};

export default RewardsContainer;
