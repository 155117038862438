import React from 'react'
import style from './transaction.module.scss'


interface  Props {
    data : string[]
}
const Header: React.FC<Props> = ({
    data
}) => {
  return (
    <div className={style.header}>
       {
        data.map((item, index) => {
            return <h1 key={index}>{item}</h1>
        })
       }
    </div>
  )
}

export default Header