

import chartDateConverter from "utils/Date/ChartDateConverter"


export const ExtractDate = (date: Date | string , setDate : any )=>  {
    setDate((item:any) => [...item , chartDateConverter(date as string)])


}

export function filterDatesByRange(dateArray: any, rangeInDays: number) {
    const currentDate = new Date(); // Current date
    const cutoffDate = new Date();
    cutoffDate?.setDate(currentDate?.getDate() - rangeInDays);


    return dateArray?.filter((item : any) => {
      const itemDate = new Date(item.date);
   
      return itemDate >= cutoffDate && itemDate <= currentDate;
    });
  }






