import React , {useContext , useEffect} from 'react'
import ActionText from 'components/ActionText/ActionText'
import JaraLink from 'components/link/JaraLink'
import DashboardContext from 'context/Dashboard/DashboardContext'

const data = [
  {
    link : '',
    title : 'Live Chat',
    icon : '/Help/live_chat.svg',
  },
  {
    link : 'https://jarapay.co/contact/',
    title : 'Contact Form',
    icon : '/Help/contact_form.svg',
  },
  {
    link : 'https://www.youtube.com/@JaraPay',
    title : 'How To Videos',
    icon : '/Help/how_to_videos.svg',
  },
  {
    link : 'https://jarapay.co/help-center/',
    title : "FAQ's",
    icon : '/Help/faq.svg',
  }

]
const HelpContainer = () => {
  const {
    setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
} = useContext(DashboardContext)




useEffect(() => {
    setCurrentPagename('Support Center')
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FAFAFA") : null
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null

}, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor])


  return (
    <main className='p-5 md:px-[4.75rem] py-[3.375rem] '>
      <section className='grid gap-2 2xl:gap-[2.188rem] xl:gap-[1.563rem] lg:gap-[1.25rem]'>
        {
          data.map((item, index) => (
            <JaraLink key={index} link={item.link} title={item.title} icon={item.icon } />
          ))
        }
      </section>


    </main>
  )
}

export default HelpContainer