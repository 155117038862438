import PinRegistration from "container/register/PinRegistration";
import React from "react";



const PinRegistrationPage = () => {
    return (
        <PinRegistration />
    )

}

export default PinRegistrationPage