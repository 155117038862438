import React from 'react'
import style from './creditcard.module.scss'
import copyToClipboard from 'utils/copyToClipboard'





const CreditCard = ({
    data
}:{
    data :{account_number: string,
    bank: string,
    name: string
}
}) => {
    

    return (
        <section className={style.creditcard} >
            <article>
                <h3>
                    Account Number
                </h3>
                <p className={style.account_number}>
                    {data?.account_number ? data.account_number : "N/A"}
                    <span onClick={()=>copyToClipboard(data.account_number)} className='cursor-pointer'>
                        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.6601 12.8642L20.6801 18.0148C19.8401 22.4629 18.1801 24.2619 15.0601 23.8923C14.5601 23.843 14.0201 23.7321 13.4401 23.5596L11.7601 23.0667C7.59006 21.8468 6.30006 19.3086 7.28006 14.158L8.26006 8.9952C8.46006 7.94784 8.70006 7.03603 9.00006 6.2844C10.1701 3.30252 12.1601 2.5016 15.5001 3.47502L17.1701 3.95557C21.3601 5.16311 22.6401 7.71373 21.6601 12.8642Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.06 23.8923C14.44 24.4098 13.66 24.8411 12.71 25.2231L11.13 25.8638C7.15998 27.441 5.06997 26.1225 3.77997 21.2308L2.49997 16.3637C1.21997 11.4719 2.27997 8.88433 6.24997 7.30713L7.82997 6.6664C8.23997 6.50622 8.62997 6.37068 8.99997 6.28442C8.69997 7.03605 8.45997 7.94787 8.25997 8.99522L7.27997 14.1581C6.29997 19.3086 7.58998 21.8469 11.76 23.0667L13.44 23.5596C14.02 23.7321 14.56 23.843 15.06 23.8923Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>

                </p>
            </article>
            <article>
                <h3>
                    Bank Name
                </h3>
                <p>
                    {data?.bank ? data?.bank : 'N/A' }
                </p>

            </article>
            <article>
                <h3>
                    Account Name
                </h3>
                <p>
                    {data?.name  ? data.name : "N/A"}
                </p>
            </article>
        </section>
    )

}

export default CreditCard;
