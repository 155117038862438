import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  cart: any[];
  quantity: number;
  total: number;
  price: number;
  products: any[];
  product: any;
  page: number;
  third_party_email: string;
  name: string;
  fullData: any;


} = {
  quantity: 0,
  total: 0,
  price: 0,
  products: [],
  cart: [],
  product: {},
  page: 1,
  third_party_email: "",
  name: "",
  fullData: {},
};

const giftCardSlice = createSlice({
  name: "giftCardService",
  initialState,
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
      localStorage.setItem("cart", JSON.stringify(state.cart));
      
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setThirdPartyEmail: (state, action) => {
      state.third_party_email = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setFullData: (state, action) => {
      state.fullData = action.payload;
    },

    addProductToCart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.cart.find(
        (p: any) => p.id === product.id
      ) as any;
      if (existingProduct) {
        existingProduct.quantity += 1;
        //remove existing product
        const productLeft = state.cart.filter(
          (p: any) => p.id !== product.id
        ) as any;
        state.cart = [...productLeft, existingProduct];
        localStorage.setItem("cart", JSON.stringify(state.cart));
      } else {
        state.cart.push(product);
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },

    retrieveProductFromCart: (state) => {
      const cart = JSON.parse(localStorage.getItem("cart") || "[]");
      state.cart = cart;
    },
    increaseCartQuantity: (state, action) => {
      const productId = action.payload;
      const product = state.cart.find((p: any) => p.id === productId) as any;
      product.quantity += 1;
      //remove existing product
      state.cart = state.cart.map((p: any) =>
        p.id === productId ? product : p
      ) as any;

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    decreaseCartQuantity: (state, action) => {
      const productId = action.payload;
      const product = state.cart.find((p: any) => p.id === productId) as any;
      product.quantity = +product.quantity === 1 ? 1 : +product.quantity - 1;
      state.cart =  state.cart.map((p: any) =>
        p.id === productId ? product : p
      ) as any;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    removeProductFromCart: (state, action) => {
      const productId = action.payload;
      const productLeft = state.cart.filter(
        (p: any) => p.id !== productId
      ) as any;
      state.cart = productLeft;
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
});

export default giftCardSlice.reducer;
export const {
  setQuantity,
  setTotal,
  setPrice,
  setProducts,
  setCart,
  setProduct,
  setPage,
  addProductToCart,
  removeProductFromCart,
  retrieveProductFromCart,
  increaseCartQuantity,
  decreaseCartQuantity,
  setThirdPartyEmail,
  setName,
  setFullData ,
  // setCart,
  // setProduct,
  // setPage,

} = giftCardSlice.actions;
