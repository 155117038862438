import React from 'react'
import style from './applybox.module.scss'


interface  Props{
    btnText : string;
    onClick : () => void;
}

const ApplyBox: React.FC<Props> = ({
    btnText,
    onClick
}) => {
  return (
    <div className={style.applybox}>
        <input type="text"  />
        <button onClick={onClick}>
            {
                btnText
            }
        </button>
    </div>
  )
}

export default ApplyBox