import React from 'react';
import style from './sidebar.module.scss'
import Menu from '../menu/Menu';

export const Sidebar = () => {
    return (
        <nav className={`${style.sidebar}`} aria-label='side menu'>
            <section className={style.logo}>
                <img src='/jarapay.svg' width={130} height={35} alt='logo'className='w-[150px] h-[35px]' />
            </section>
            <section className={style.menu}>
            <Menu/>
            </section>



        </nav>
    );

}


export default Sidebar