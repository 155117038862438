import { createBrowserRouter } from "react-router-dom";
import CreatePinPage from "app/auth/create-pin/page";
import ResetPinPage from "app/auth/reset-pin/page";
import SignUpPage from "app/auth/signup/page";
import Home from "app/page";
import SigninPage from "app/auth/sign-in/page";
import CompleteRegistrationPage from "app/auth/signup/complete/page";
import PinRegistrationPage from "app/auth/signup/pin/page";
import SecurityRegistrationPage from "app/auth/signup/security/page";
import FundWalletPage from "app/fund_wallet/page";
import ServicesPage from "app/services/page";
import WalletTransferPage from "app/wallet_transfer/page";
import TransactionsPage from "app/transactions/page";
import ProfilePage from "app/profile/page";
import BeneficiariesPage from "app/beneficiaries/page";
import React from "react";
import SubscriptionsPage from "app/subscriptions/page";
import HelpPage from "app/help/page";
import SecurityPage from "app/security/page";
import TransactionPinPage from "app/security/transaction-pin/page";
import RewardsPage from "app/referral_and_cashback/page";
import MyCardsPage from "app/my-cards/page";
import BvnVerificationPage from "app/bvn-verification/page";
import ElectricityTokenPage from "app/electricity-token/page";
import Layout from "components/AuthLayout/Layout";
import ChangeTransactionPinPage from "app/change-transaction-pin/page";
import RequestPinReset from "app/auth/request-pin-reset/page";
import WalletFundingSuccess from "app/fund_wallet/success";
import WalletFundingError from "app/fund_wallet/error";
import AddDebitCardSuccess from "app/my-cards/success";
import AddCardError from "app/my-cards/error";
import Error from "components/Error/Error";
import CartContainer from "components/GiftCard/Cart/CartContainer";
import GiftCardPage from "app/gift/giftpage";




export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/sign-in",
    element: <SigninPage />,
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },

  {
    path: "/sign-up/complete-registration",
    element: <CompleteRegistrationPage />,
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/sign-up/pin-registration",
    element: <PinRegistrationPage />,
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/sign-up/security-registration",
    element: <SecurityRegistrationPage />,
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },

  {
    path: "/services",
    element: (
      <Layout>
        <ServicesPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/services/gift/cart",
    element: (
      <Layout>
        <GiftCardPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/wallet-transfer",
    element: (
      <Layout>
        <WalletTransferPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },

  {
    path: "/fund-wallet",
    element: (
      <Layout>
        <FundWalletPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/transactions",
    element: (
      <Layout>
        <TransactionsPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/profile",
    element: (
      <Layout>
        <ProfilePage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/beneficiaries",
    element: (
      <Layout>
        <BeneficiariesPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/subscriptions",
    element: (
      <Layout>
        <SubscriptionsPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/help",
    element: (
      <Layout>
        <HelpPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/security",
    element: (
      <Layout>
        <SecurityPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/security/transaction-pin",
    element: (
      <Layout>
        <TransactionPinPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/referral-and-cashback",
    element: (
      <Layout>
        <RewardsPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/my-cards",
    element: (
      <Layout>
        <MyCardsPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "*",
    element: (
      <Layout>
        {" "}
        <Home />{" "}
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/bvn-verification",
    element: (
      <Layout>
        <BvnVerificationPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/electricity-token",
    element: (
      <Layout>
        {" "}
        <ElectricityTokenPage />{" "}
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },
  {
    path: "/change-transaction-pin",
    element: (
      <Layout>
        {" "}
        <ChangeTransactionPinPage />
      </Layout>
    ),
    errorElement: (
      <Error svgImage="/jarapay_error.gif" title={"Error"} description={""} />
    ),
  },

  {
    path: "/create-pin",
    element: <CreatePinPage />,
  },
  {
    path: "/reset-pin",
    element: <ResetPinPage />,
  },
  {
    path: "/request-pin-reset",
    element: <RequestPinReset />,
  },
  {
    path: "/wallet/success",
    element: <WalletFundingSuccess />,
  },
  {
    path: "/wallet/error",
    element: <WalletFundingError />,
  },
  {
    path: "/cards/success",
    element: <AddDebitCardSuccess />,
  },
  {
    path: "/cards/error",
    element: <AddCardError />,
  },
]);
