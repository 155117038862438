import React from 'react';
import style from './form.module.scss'
import ServiceInputDropdown from 'components/form/Services/DropdownInput';
import ServicesInput from 'components/form/Services/Input';


export const DataForm = () => {


    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='MTN' title={'Select Network'} placeholder={''} />
                <ServicesInput defaultValue='1 GB - N1000' label={'Select Network Plan'} name={'network'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='08011111111' label={'Enter Phone Number'} name={''} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <p>
                    Select Beneficiary
                </p>
                <ServiceInputDropdown defaultValue='Auto Renewal' name='renewal' title={'Renewal Status'} placeholder={''} /> */}
            </div>
        </div>

    )


}

export const InternetForm = () => {


    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='Smile' title={'Select Provider'} placeholder={''} />
                <ServicesInput defaultValue='Prepaid' label={'Select Bundle'} name={'network'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='1212121212' label={'Registered Smile Email'} name={''} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <p>
                    Select Beneficiary
                </p>
                <ServicesInput defaultValue='N10,000' label={'Select Account'} name={''} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServiceInputDropdown defaultValue='Auto Renewal' name='renewal' title={'Renewal Status'} placeholder={''} /> */}
            </div>
        </div>

    )


}

export const CableForm = () => {


    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='MTN' title={'Select Network'} placeholder={''} />
                <ServicesInput defaultValue='1 GB - N1000' label={'Select Network Plan'} name={'network'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='121212121212' label={'Smart Card or Phone Number'} name={'network'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <p>
                    Select Beneficiary
                </p>
                <ServiceInputDropdown defaultValue='Auto Renewal' name='renewal' title={'Renewal Status'} placeholder={''} /> */}
            </div>
        </div>

    )


}


export const ElectricityForm = () => {


    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='Ikeja' title={'Select Provider'} placeholder={''} />
                <ServicesInput defaultValue='Prepaid' label={'Select Meter Type'} name={'meter'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='121212121212' label={'Meter Number'} name={'meter_number'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <p>
                    Select Beneficiary
                </p>
                <ServicesInput defaultValue='N10 ,000' label={'Enter Amount'} name={'amount'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServiceInputDropdown defaultValue='Auto Renewal' name='renewal' title={'Renewal Status'} placeholder={''} />
                <div className='flex gap-[1.4rem] items-center '>
                    <input type="checkbox" name="third_party" id="checkbox" checked />
                    <label htmlFor="checkbox">
                        Send to third party recipient (optional)
                    </label>
                </div>
                <div>
                    <ServicesInput defaultValue='Email' label={'Family or Friend Email'} name={'email'} placeholder={''} onChange={() => { return null }} value={''} type={'email'} />
                    <p className='pt-[1rem] text-gray-600 text-[1.6rem] font-[500] '>
                        We will send meter token to recipient for FREE
                    </p>
                </div>
                <div>
                    <ServicesInput defaultValue='Email' label={'Family or Friend Email'} name={'email'} placeholder={''} onChange={() => { return null }} value={''} type={'email'} />
                    <p className='pt-[1rem]  text-gray-600 text-[1.6rem] font-[500]'>
                        You will be charge N10 SMS gateway fee
                    </p>
                </div> */}
            </div>
        </div>

    )


}

export const InsuranceForm = () => {
    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='Ikeja' title={'Select Provider'} placeholder={''} />
                <ServiceInputDropdown name='bundle' defaultValue='Prepaid' title={'Select Bundle'} placeholder={''} />
                <ServicesInput defaultValue='1212121212' label={'Registered Smile Email'} name={'smile'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='121212121212' label={'Smart Card or Phone Number'} name={'network'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <p>
                    Select Beneficiary
                </p>

                <ServicesInput defaultValue='N10,000' label={'Select Account'} name={'account'} placeholder={''} onChange={() => { return null }} value={''} type={''} />

                <ServiceInputDropdown defaultValue='Auto Renewal' name='renewal' title={'Renewal Status'} placeholder={''} /> */}
            </div>
        </div>

    )
}

export const InsuranceForm1 = () => {
    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='Ikeja' title={'Insurance Package'} placeholder={'select option'} />
                {/* //!TODO: ADD A BUTTON @ THE END OF THE FORM */}
                {/* <ServicesInput defaultValue='' label={'Enter Phone Number'} name={'network'} placeholder={'Enter Phone Number or Select Contact'} onChange={() => { return null }} value={''} type={''} /> */}


                {/* <ServicesInput defaultValue='' label={'Insured Name'} name={'account'} placeholder={'insured name'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Chasis'} name={'account'} placeholder={'chasis number'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Plate Number'} name={'account'} placeholder={'plate number'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Vehicle Make'} name={'account'} placeholder={'vehicle make'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Vehicle Colour'} name={'account'} placeholder={'vehicle colour'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Vehicle Model'} name={'account'} placeholder={'vehicle model'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Year of Make'} name={'account'} placeholder={'year of make'} onChange={() => { }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Contact Address '} name={'account'} placeholder={'contact address'} onChange={() => { }} value={''} type={''} /> */}

            </div>
        </div>

    )

}

export const InsuranceForm2 = () => {
    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='Ikeja' title={'Insurance Package'} placeholder={'Select Option'} />
                <ServicesInput defaultValue='' label={'Enter Phone Number'} name={'smile'} placeholder={'Enter Phone Number or Select Contact'} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Full Name'} name={'network'} placeholder={'full name'} onChange={() => { return null }} value={''} type={''} /> */}


                {/* <ServicesInput defaultValue='' label={'Home Address'} name={'account'} placeholder={'home address'} onChange={() => { return null }} value={''} type={''} /> */}

                {/* <ServiceInputDropdown defaultValue='' name='renewal' title={'Selected Hospital'} placeholder={'Select Option'} /> */}
                {/* //! Open Gallery */}
                {/* <ServicesInput defaultValue='' label={'Passport Photo'} name={'account'} placeholder={'select image'} onChange={() => { return null }} value={''} type={''} /> */}
                {/* //! TODO: SELECT DATE */}
                {/* <ServicesInput defaultValue='' label={'Date of Birth'} name={'account'} placeholder={'select date'} onChange={() => { return null }} value={''} type={''} /> */}
                {/* //! TODO: Textarea */}
             {/* <ServicesInput defaultValue='' label={'Extra Information'} name={'account'} placeholder={'extra information'} onChange={() => { return null }} value={''} type={''} /> */}

            </div>
        </div>

    )

}


export const InsuranceForm3 = () => {
    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='' title={'Insurance Package'} placeholder={'Select Option'} />
                <ServiceInputDropdown name='bundle' defaultValue='' title={'Phone'} placeholder={'phone'} />
                <ServicesInput defaultValue='' label={'Full Name'} name={'network'} placeholder={'full name'} onChange={() => { return null }} value={''} type={''} />


                <ServicesInput defaultValue='' label={'Home Address'} name={'account'} placeholder={'home address'} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Next of Kin'} name={'network'} placeholder={'next of kin'} onChange={() => { return null }} value={''} type={''} />


                <ServicesInput defaultValue='' label={'Next of Kin Phone'} name={'account'} placeholder={'next of kin phone'} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Business Occupation'} name={'network'} placeholder={'business occupation'} onChange={() => { return null }} value={''} type={''} /> */}



            </div>
        </div>

    )

}


export const InsuranceForm4 = () => {
    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='provider' defaultValue='' title={'Insurance Package'} placeholder={'Select Option'} />
                <ServiceInputDropdown name='bundle' defaultValue='' title={'Enter Phone Number'} placeholder={'Enter Phone Number or Select Contact'} />
                <ServicesInput defaultValue='' label={'Full Name'} name={'network'} placeholder={'full name'} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Address of insured property'} name={'account'} placeholder={'address of insured property'} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='' label={'Type of Building'} name={'network'} placeholder={'type of building'} onChange={() => { return null }} value={''} type={''} />

                <ServicesInput defaultValue='' label={'Business/Occupation'} name={'network'} placeholder={'business/occupation'} onChange={() => { return null }} value={''} type={''} />

                   {/* //! TODO: SELECT DATE */}
                   {/* <ServicesInput defaultValue='' label={'Date of Birth'} name={'account'} placeholder={'yyyy-mm-dd'} onChange={() => { return null }} value={''} type={''} /> */}



            </div>
        </div>

    )

}




export const EducationForm = () => {

    return (
        <div className='flex items-center justify-center w-full'>
            <div className={style.form}>
                {/* <ServiceInputDropdown name='exam__type' defaultValue='Ikeja' title={'Exam Type'} placeholder={''} />
                <ServiceInputDropdown name='exam' defaultValue='Prepaid' title={'Select Exam'} placeholder={''} />
                <ServicesInput defaultValue='1212121212' label={'Quality'} name={'quality'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
                <ServicesInput defaultValue='121212121212' label={'Enter Phone Number'} name={'mobile'} placeholder={''} onChange={() => { return null }} value={''} type={''} />
 */}

            </div>
        </div>
    )

}
