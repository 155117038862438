import React, { useState, useEffect, useRef } from "react";
import style from "./shop.module.scss";
import CardList from "../Card/CardList";
import { Link } from "react-router-dom";
import CurrencyConverter from "utils/CurrencyConverter";
import { Grid, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toast } from "react-hot-toast";
import { addProductToCart } from "features/Services/GiftCardService/GiftCardServiceSlice";

//import { useGetGiftCardProductQuery } from "features/Services/GiftCardService/GiftCardApiSlice";

interface Props {
  data: any;
  handleClick: (value: any) => void;
  handleNext: () => void;
}

const GiftCardShop: React.FC<Props> = ({ data, handleClick, handleNext }) => {
  const { products } = useAppSelector((state) => state.giftCardService);
  //   const { data: productData } = useGetGiftCardProductQuery(data.id, {
  //     skip: !data.id,
  //   });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const [quantity, setQuantity] = useState<number>(1);
  const handleAddQuantity = () => {
    setQuantity((prev) => prev + 1);
  };
  const handleRemoveQuantity = () => {
    setQuantity((prev) => (prev > 0 ? prev - 1 : 1));
  };

  const paragraphs: string[] = data?.description.split("\r\n") as any;

  const otherInfo = [
    "Delivery to Email",
    " Delivery to third party email",
    "Delivery to third party phone",
  ];

  const indexOfDataIdInProducts = products?.findIndex(
    (item: any) => item.id === data.id
  );
  const slicedProduct = products?.slice(
    indexOfDataIdInProducts + 1,
    indexOfDataIdInProducts + 4
  );

  const threeProducts = products?.slice(0, 3);

  const dispatch = useAppDispatch();

  const handleAddToCart = (e: any) => {
    e.stopPropagation();
    dispatch(addProductToCart({ ...data, quantity: quantity }));
    toast.success("Product added to cart");
    handleNext();
  };

  return (
    <main className={style.shop} ref={scrollContainerRef}>
      <section className={style.shop__top}>
        <div className={style.shop__top__left}>
          <div className={style.shop__top__left__image_container}>
            <img src={data.image_path} alt={data.name} />
          </div>
          <div className="hidden md:block">
            <button className={style.top_btn}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H12"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.48 2.81945L19.76 3.38945C19.9 3.66945 20.25 3.92945 20.56 3.98945L20.94 4.04945C22.08 4.23945 22.35 5.07945 21.53 5.90945L21.18 6.25944C20.95 6.49944 20.82 6.95944 20.89 7.27944L20.94 7.48945C21.25 8.86945 20.52 9.39944 19.32 8.67944L19.06 8.52944C18.75 8.34944 18.25 8.34944 17.94 8.52944L17.68 8.67944C16.47 9.40944 15.74 8.86945 16.06 7.48945L16.1099 7.27944C16.1799 6.95944 16.05 6.49944 15.82 6.25944L15.47 5.90945C14.65 5.07945 14.92 4.23945 16.06 4.04945L16.44 3.98945C16.74 3.93945 17.1 3.66945 17.24 3.38945L17.52 2.81945C18.06 1.72945 18.94 1.72945 19.48 2.81945Z"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className={style.top_btn__text}>
                Instant Code Delivery Via Mail
              </span>
            </button>
          </div>
          {/* <div className = "block md:hidden">
                    <Steps information={information} additionalInfo={otherinfo} />
                    </div> */}
        </div>
        <div className={style.shop__top__right}>
          <article className={style.heading}>
            <h3>{data.name}</h3>
            <p>{CurrencyConverter(data.price)}</p>
          </article>
          <article className={style.quantity}>
            <svg
              onClick={handleRemoveQuantity}
              width="11"
              height="2"
              viewBox="0 0 11 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2412 1L1 1"
                stroke="#3C4242"
                stroke-width="1.03964"
                stroke-linecap="round"
              />
            </svg>
            <span>{quantity}</span>

            <svg
              onClick={handleAddQuantity}
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.86181 1.37891V10.6201M10.4824 5.99951L1.24121 5.99951"
                stroke="#3C4242"
                stroke-width="1.03964"
                stroke-linecap="round"
              />
            </svg>
          </article>

          <article className={style.action}>
            <button className={style.add__to__cart} onClick={handleAddToCart}>
              Add to cart
            </button>
            <button className={style.link__to__live}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 14.8V7.19995V7.19666C21 6.07875 21 5.51945 20.7822 5.09204C20.5905 4.71572 20.2841 4.40973 19.9078 4.21799C19.48 4 18.9203 4 17.8002 4H6.2002C5.08009 4 4.51962 4 4.0918 4.21799C3.71547 4.40973 3.40973 4.71572 3.21799 5.09204C3 5.51986 3 6.07985 3 7.19995V18.671C3 19.7367 3 20.2696 3.21846 20.5432C3.40845 20.7813 3.69644 20.9197 4.00098 20.9194C4.35115 20.919 4.76744 20.5861 5.59961 19.9203L7.12357 18.7012C7.44844 18.4413 7.61084 18.3114 7.79172 18.219C7.95219 18.137 8.12279 18.0771 8.29932 18.0408C8.49829 18 8.70652 18 9.12256 18H17.8001C18.9202 18 19.48 18 19.9078 17.782C20.2841 17.5902 20.5905 17.2844 20.7822 16.908C21 16.4806 21 15.9212 21 14.8032V14.8Z"
                    stroke="#246BFD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span>Live Chat</span>
            </button>
          </article>
          <div className="md:hidden">
            <button className={style.top_btn}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H12"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.48 2.81945L19.76 3.38945C19.9 3.66945 20.25 3.92945 20.56 3.98945L20.94 4.04945C22.08 4.23945 22.35 5.07945 21.53 5.90945L21.18 6.25944C20.95 6.49944 20.82 6.95944 20.89 7.27944L20.94 7.48945C21.25 8.86945 20.52 9.39944 19.32 8.67944L19.06 8.52944C18.75 8.34944 18.25 8.34944 17.94 8.52944L17.68 8.67944C16.47 9.40944 15.74 8.86945 16.06 7.48945L16.1099 7.27944C16.1799 6.95944 16.05 6.49944 15.82 6.25944L15.47 5.90945C14.65 5.07945 14.92 4.23945 16.06 4.04945L16.44 3.98945C16.74 3.93945 17.1 3.66945 17.24 3.38945L17.52 2.81945C18.06 1.72945 18.94 1.72945 19.48 2.81945Z"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className={style.top_btn__text}>
                Instant Code Delivery Via Mail
              </span>
            </button>
          </div>
          <Grid fontSize={".875rem"} color={"#667479"} gap={".5rem"}>
            <Text
              fontWeight={"600"}
              py=".65rem"
              w={"100%"}
              borderBottom={"1px solid #EBEEEF"}
            >
              Voucher Redeem Instructions
            </Text>
            <Grid gap={".2rem"} pr={"1rem"}>
              {paragraphs?.map((item: string, index: number) => (
                <Text key={index}>{item}</Text>
              ))}
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              borderBottom={"1px solid #EBEEEF"}
              py=".65rem"
              gap={"1rem"}
            >
              <Text fontWeight={"600"} w={"100%"}>
                Other Information
              </Text>
              <Grid gap={".2rem"} pr={"1rem"}>
                {otherInfo?.map((item: string, index: number) => (
                  <Text key={index}>{item}</Text>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* <div className = "hidden md:block"> */}
          {/* <Steps information={information} additionalInfo={otherinfo} /> */}
          {/* </div> */}
        </div>
      </section>
      <section className={style.bottom}>
        <div className={style.bottom__top}>
          <h6>Whats new?</h6>
          <Link to={""}>See more Giftcards</Link>
        </div>
        <CardList
          data={slicedProduct?.length ? slicedProduct : threeProducts}
          handleClick={handleClick}
          fullData={{}}
        />
      </section>
    </main>
  );
};

export default GiftCardShop;
