import React from 'react';
import style from './fundingsummary.module.scss'
import { useAppSelector } from 'hooks/redux';
import formatLocalCurrency from 'utils/formatLocalCurrency';
import extractAmountAndData from 'utils/extractAmountAndData';

interface summary{
    title: string,
    buttonText: string,
    onClick?: ()=> void

}


const FundingSummary: React.FC<summary> = ({
    title , buttonText , onClick= ()=> {}
}) => {

    const wallet = useAppSelector(state => state.wallet)
    // const confirmDetails = useAppSelector(state => state.dataService?.dataDetails as any)
    // let amountAndData  =  extractAmountAndData(confirmDetails?.variation_code)



    return (
        <div className='p-[3.2rem] bg-white rounded-[0.2rem] border border-[#D1D1D8]'>
            <h3 className={style.fundingsummary__title}>{title}</h3>
            <section className={style.fundingsummary__section} >
                <div className='flex items-center justify-between py-[3.2rem] border-b border-b-[#D1D1D8]'>
                    <p>
                        Amount
                    </p>
                    <h4>
                        { wallet.rawAmount   }
                    </h4>
                </div>




                <div  className='flex items-center justify-between py-[3.2rem]'>
                    <p className={style.total}>
                        TOTAL
                    </p>

                     <h4>
                        { wallet.rawAmount  }
                    </h4>
                </div>

                <button className={style.fundingsummary__button} onClick={onClick}>
                    {
                        buttonText
                    }
                </button>




            </section>

        </div>
    )

}

export default FundingSummary