


import React   from 'react';
import style from './wallettransfer.module.scss'
import WalletInfo from 'components/walletTransfer/WalletInfo/WalletInfo';

const SelectWallet = ({
    wallet , handleSelectWallet
}: {
    wallet : boolean ,
    handleSelectWallet : () => void
}) => {



    return (
        <section className={`${style.selectwallet} `}  >
            {/* //!!TODO: */}
            <WalletInfo />

        {
            wallet ? null : <button type="button" className="  btn-primary  transition-all" onClick={handleSelectWallet}>
                Continue
            </button>
        }

        </section>
    )

}

export default SelectWallet;