import React from 'react'
import style from './creditcard.module.scss'
import copyToClipboard from 'utils/copyToClipboard'
import { useDisclosure } from '@chakra-ui/react'
import ModalContainer from 'components/Modals/ModalContainer'
import RedeemDialog from './RedeemDialog'
import CurrencyConverter from 'utils/CurrencyConverter'

const RedeemCard = ({
    amount, referral_id
}: {
    amount: string,
    referral_id: string
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <div className={style.card}>
            <section className={style.redeem__card}>
                <section className={style.top}>
                    <h2>{CurrencyConverter(amount)}</h2>
                    <p>Wallet Balance</p>
                </section>
                <section className={style.bottom}>
                    <article>
                        <h3>Referral ID</h3>
                        <p onClick={()=>copyToClipboard(referral_id)} ><span>{referral_id} </span><img src='/Rewards/copy.svg' alt='copy' className='h-[2.4rem] w-[2.4rem]' />  </p>
                    </article>
                    <button onClick={onOpen}>
                        Redeem
                    </button>
                </section>

            </section>
<ModalContainer size='lg' isOpen={isOpen} onClose={onClose} >
    <RedeemDialog amount={amount as string} onClose={onClose}  />
</ModalContainer>
        </div>
    )
}

export default RedeemCard