import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    dataProviders : [],
    dataDetails: [],
}


const dataSlice = createSlice({
    name : "data",
    initialState,
    reducers : {
        setDataProviders : (state, action) => {
            state.dataProviders = action.payload
        },
        setDataDetails : (state, action) => {
            state.dataDetails = action.payload

        }
    }

})

export const {setDataProviders , setDataDetails} = dataSlice.actions
export default dataSlice.reducer
