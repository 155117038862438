import React from 'react'
import Pin from 'components/form/Auth/Pin/Pin'
import style from './form.module.scss'
import Heading from '../Heading/Heading'



 interface PinFormProps {
     amount: string
 }

const PinForm : React.FC<PinFormProps> = ({
    amount
}) => {
    return (
        <div className={style.pin__form}>
    <Heading title=' Enter Your PIN' desc={'  Please enter your transaction PIN to confirm transfer'}  />
    <Pin tokenNumber={4} title='' />
    <button className='btn-primary 2xl:mt-[16.25rem] xl:mt-[12.5rem] lg:mt-[9.375rem] md:mt-[6.25rem] mt-[3.125rem]'>
        {amount}
    </button>
</div>

    )

}

export default PinForm







