import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Image,
  Grid,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { loadBeneficiary } from "features/Beneficiaries/BeneficiariesSlice";


type Props = {
  isOpen: boolean;
  onClose: () => void;
  category: string;
};

const SelectBeneficiaryModal = ({ isOpen, onClose, category }: Props) => {
  const dispatch = useAppDispatch();

  const { beneficiary } = useAppSelector((state) => state.beneficiary);

  const [beneficiaries, setBeneficiaries] = useState<any[]>([]);

  useEffect(() => {
    const _beneficiaries = beneficiary.filter(
      (beneficiary: any) =>
        beneficiary?.category?.toLowerCase() === category?.toLowerCase()
    );
    // set unique numbers data , item.number must not appear  twice

    const __beneficiaries = _beneficiaries?.reduce((acc: any, item: any) => {
      const existingItem = acc.find(
        (accItem: any) => accItem.number === item.number
      );

      if (!existingItem) {
        return [...acc, item];
      }
      return acc;
    }, []);

    setBeneficiaries(__beneficiaries);
  }, [beneficiary, category]);

  const addBeneficiary = (item: any) => {

    dispatch(loadBeneficiary({ category, number: item.number }));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Beneficiary</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={"2rem"}>
          {beneficiaries?.length ? (
            <Grid gap={"1rem"}>
              {beneficiaries?.map((item: any, index: number) => {
                return (
                  <Flex
                    onClick={() => addBeneficiary(item)}
                    cursor={"pointer"}
                    key={index}
                    gap={"1rem"}
                    align={"center"}
                    bg={"gray.50"}
                    _hover={{
                      bg: "gray.100",
                    }}
                    transition={"all .2s ease-in-out"}
                    w={"100%"}
                    padding={".6rem"}
                    borderRadius={".5rem"}
                  >
                    <Image
                      src="/Beneficiaries/thunder.svg"
                      alt="Benefiaciary"
                      width={{
                        base: "1rem",
                        lg: "1.5rem",
                      }}
                      height={{
                        base: "1rem",
                        lg: "1.5rem",
                      }}
                    />
                    <Grid gap={".5rem"}>
                      <Flex
                        align={"center"}
                        gap={"1rem"}
                        fontSize={{
                          base: ".76rem",
                          lg: ".875rem",
                        }}
                      >
                        <Text fontWeight={"500"} color={"var(--dark-text)"}>
                          {item?.number}
                        </Text>
                        <Text
                          fontSize={"sm"}
                          fontWeight={"500"}
                          color={"var(--dark-text)"}
                        >
                          {item?.provider}
                        </Text>
                      </Flex>
                      <Flex
                        gap={".2rem"}
                        align={"center"}
                        fontSize={{
                          base: ".76rem",
                          lg: ".875rem",
                        }}
                      >
                        <Text fontWeight={"500"} color={"var(--gray-text)"}>
                          {item?.name}
                        </Text>
                        <Text>|</Text>
                        <Text color={"var(--gray-text)"}>{item?.time}</Text>
                      </Flex>
                    </Grid>
                  </Flex>
                );
              })}
            </Grid>
          ) : (
            <Grid
              height={"15rem"}
              placeContent={"center"}
              placeItems={"center"}
            >
              <Text
                fontSize={"lg"}
                fontWeight={"500"}
                color={"var(--gray-text)"}
              >
                {" "}
                No Beneficiaries
              </Text>
            </Grid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectBeneficiaryModal;
