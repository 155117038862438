import React , {useState , useEffect} from 'react';
import FundViaTable from './FundViaTable/FundViaTable';
import AccountNumberTable from './AccountNumberTable/AccountNumberTable';
import FundingSummary from './FundingSummary/FundingSummary';
import {useAppSelector} from 'hooks/redux'





// const data :{
//     title: string;
//     description: string;
//     svg: string;
// }[] = [
//     {
//         title: "Fund with Squadco",
//         description: "Pay with card, bank transfer or USSD.",
//         svg: '/PaymentOptions/squad.svg'
//     },
//     {
//         title: "Fund with Flutterwave",
//         description: "Pay with card, bank transfer or USSD.",
//         svg: '/PaymentOptions/squad.svg'
//     },
//     {
//         title: "Fund with Bitcoin",
//         description: "Pay with card, bank transfer or USSD.",
//         svg: '/PaymentOptions/squad.svg'

//     },
//     {
//         title: "Fund with USSD (click to see all bank code list)",
//         description : "*bank_code*000*898+354173+amount#",
//         svg: '/PaymentOptions/squad.svg'
//     }



// ]

const PaymentOptions = ({
    handleClick
}: {
   handleClick?: any
}) => {
    const wallet = useAppSelector(state => state.wallet)
    const [cardData , setCardData] = useState<any[]>([])
    const {user = {}} = useAppSelector((state: any) => state.auth as any)




    useEffect(()=> {
        if(user?.data?.virtual_account){
            //filter empty bank
            setCardData(user.data.virtual_account)
        }

    } , [user])


    return (
        <div className='pt-[47px] lg:flex grid xl:gap-[78px] gap-[38px]  '>
            <div className='w-full grid gap-[5rem]'>
            <AccountNumberTable  account={ cardData } />
                <FundViaTable  data={wallet?.channels } />

            </div>
            <div className = 'xl:w-[380px] xl:min-w-[380px] lg:min-w-[max-content] h-[max-content] w-full'>
                <FundingSummary onClick={handleClick}  title='Payment Summary' buttonText={'Fund Wallet'} />
            </div>


        </div>
    )


}

export default PaymentOptions;