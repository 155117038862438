import React from "react";



const HomeIcon = () => {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7002 5.68471L11.9002 2.32637C10.5918 1.40971 8.5835 1.45971 7.32519 2.43471L3.15019 5.69304C2.31686 6.34304 1.65852 7.67637 1.65852 8.72633V14.4763C1.65852 16.6013 3.38352 18.3347 5.50852 18.3347H14.4918C16.6168 18.3347 18.3418 16.6097 18.3418 14.4847V8.83466C18.3418 7.70971 17.6168 6.32637 16.7002 5.68471ZM10.6252 15.0013C10.6252 15.343 10.3418 15.6263 10.0002 15.6263C9.6585 15.6263 9.37517 15.343 9.37517 15.0013V12.5013C9.37517 12.1597 9.6585 11.8763 10.0002 11.8763C10.3418 11.8763 10.6252 12.1597 10.6252 12.5013V15.0013Z" fill="#929EAE" />
        </svg>

    )

}

export default HomeIcon;