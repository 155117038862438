import React from 'react'
import style from './categories.module.scss'
import Category from 'components/category/Category'

const Categories = ({
    data, title
}: {
    data: any[], title: string
}) => {


    return (
        <section className={style.categories}>
            <h2>
                {title}
            </h2>
            <div className={style.categories__container}>
                {
                    data.map((item, index) => {
                        return (
                            <Category key={index} title={item.title} bgColor={item.bgColor} icon={item.icon} sub={title === ""} />
                        )
                    })
                }

            </div>
        </section>
    )

}

export default Categories;