import ResetPin from "container/resetpin/ResetPin";
import React from "react";





const  ResetPinPage = () => {
    return (
        <ResetPin />
    )

}

export default ResetPinPage;