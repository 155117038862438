import React from "react";

import { useController } from "react-hook-form";
import { Grid, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
} from "@chakra-ui/react";

interface InputProps {
  title: string;
  placeholder: string;
  type?: string;

  control: any;
  name: string;
  rules?: any;
  errorMessage?: string;
  fontWeight?:string | number
  fontSize?: string
  color? :string

  defaultValue?: string;
  options: any[];
  open?: boolean
}
const DropdownWithImage: React.FC<InputProps> = ({
  control,
  options,
  name,
  title,
  errorMessage,
  placeholder,
  defaultValue,
  fontWeight,
  fontSize,
  color,
}) => {
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules: {
      required: true,
    },
    defaultValue: defaultValue,
  });

  return (
    <Grid gap={".5rem"}>
      <Text as={"label"} color={color} fontWeight={fontWeight || 500} fontSize={fontSize || '.875rem'}>{title}</Text>
      <FormControl isInvalid={errors[name] ? true : false}>
        {/* <Select
        useBasicStyles= {true}
        size={'lg'}
          {...field}
          options={options}
          focusBorderColor="blue.500"
          errorBorderColor="red.500"


        /> */}

        <Menu>
          <MenuButton
            _hover={{
              bg: "none",
            }}
            borderColor={field.value ? "var(--primary)" : "#F2F2F2"}
            className={`md:w-[25.25rem] h-full rounded-[.625rem]  hover:border-primary `}
            textAlign={"left"}
            justifyContent={"space-between"}
            px={"1.25rem"}
            width={{
              base: "100%",
            }}
            variant={"outline"}
            fontSize={".875rem"}
            size={"lg"}
            as={Button}
            rightIcon={<TiArrowSortedDown />}
            textTransform={"capitalize"}
            py={"1.5rem"}
          >
            <Flex gap=".5rem" align={"center"}
            fontWeight={
                field.value ? "600" : "normal"
            }
            color={
                field.value ? "var(--dark-text)" : "var(--text-1)"
            }


             >
              {field.value?.image ? (
                <Avatar src={field.value?.image} width={"1rem"} h={"1rem"} />
              ) : null}
              <Text  fontSize={'.875rem'}>{field.value?.label || placeholder}</Text>
            </Flex>
          </MenuButton>
          <MenuList w={'100%'}>
            {options?.map((item, index) => (
              <MenuItem w={'100%'} key={index} onClick={() => field.onChange(item)}>
                <Flex gap=".5rem" align={"center"}>
                  {item.image ? (
                    <Avatar src={item.image} width={"1rem"} h={"1rem"} />
                  ) : null}
                  <Text>{item.label}</Text>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <FormErrorMessage fontSize={'.7rem'}>{errorMessage}</FormErrorMessage>
      </FormControl>
    </Grid>
  );
};

export default DropdownWithImage;
