function formatPrice(value: string): string {
    // Remove all non-numeric characters except for the decimal point
    const priceValue = value.replace(/[^\d.]/g, '');
    //split by dots
    const parts = priceValue.split('.');

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'decimal',

    }).format(Number(parts[0]));

    if (parts.length > 1) {
        //dont alow more than 2 numbers
        let formattedPart = parts[1].replace(/^(\d{2})\d+/, '$1');




        return `N${formattedValue}.${formattedPart}`;
    } else {
        return `N${formattedValue}`;
    }


}

export default formatPrice