

import React from 'react'


const FundWalletIcon = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.16667 12.4999C2.325 12.4999 0.833336 13.9916 0.833336 15.8332C0.833336 16.4582 1.00834 17.0499 1.31667 17.5499C1.89167 18.5166 2.95 19.1666 4.16667 19.1666C5.38334 19.1666 6.44167 18.5166 7.01667 17.5499C7.325 17.0499 7.5 16.4582 7.5 15.8332C7.5 13.9916 6.00834 12.4999 4.16667 12.4999ZM5.40834 16.4416H4.79167V17.0916C4.79167 17.4332 4.50834 17.7166 4.16667 17.7166C3.825 17.7166 3.54167 17.4332 3.54167 17.0916V16.4416H2.925C2.58334 16.4416 2.3 16.1582 2.3 15.8166C2.3 15.4749 2.58334 15.1916 2.925 15.1916H3.54167V14.5999C3.54167 14.2582 3.825 13.9749 4.16667 13.9749C4.50834 13.9749 4.79167 14.2582 4.79167 14.5999V15.1916H5.40834C5.75 15.1916 6.03334 15.4749 6.03334 15.8166C6.03334 16.1582 5.75834 16.4416 5.40834 16.4416ZM17.9167 10.4166H15.8333C14.9167 10.4166 14.1667 11.1666 14.1667 12.0832C14.1667 12.9999 14.9167 13.7499 15.8333 13.7499H17.9167C18.15 13.7499 18.3333 13.5666 18.3333 13.3332V10.8332C18.3333 10.5999 18.15 10.4166 17.9167 10.4166ZM13.7743 4.50005C14.0243 4.74172 13.816 5.11672 13.466 5.11672L6.56603 5.10838C6.16603 5.10838 5.96603 4.62505 6.24936 4.34172L7.70769 2.87505C8.941 1.65005 10.9327 1.65005 12.166 2.87505L13.741 4.46672C13.7493 4.47505 13.766 4.49172 13.7743 4.50005Z" fill="#929EAE"/>
<path d="M18.2245 15.5499C17.7162 17.2666 16.2495 18.3333 14.2495 18.3333H8.83284C8.50784 18.3333 8.29949 17.9749 8.43284 17.6749C8.68284 17.0916 8.84117 16.4333 8.84117 15.8333C8.84117 13.3083 6.78282 11.2499 4.25782 11.2499C3.62449 11.2499 3.00782 11.3833 2.44115 11.6333C2.13282 11.7666 1.75782 11.5583 1.75782 11.2249V9.99992C1.75782 7.73325 3.12449 6.14992 5.24949 5.88325C5.45782 5.84992 5.68281 5.83325 5.91615 5.83325H14.2495C14.4662 5.83325 14.6745 5.84159 14.8745 5.87492C16.5578 6.06659 17.7745 7.09159 18.2245 8.61659C18.3078 8.89159 18.1078 9.16659 17.8245 9.16659H15.9162C14.1078 9.16659 12.6745 10.8166 13.0662 12.6916C13.3412 14.0583 14.6078 14.9999 15.9995 14.9999H17.8245C18.1162 14.9999 18.3078 15.2833 18.2245 15.5499Z" fill="#929EAE"/>
</svg>

    )

}


export default FundWalletIcon