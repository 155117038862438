import { VStack, Center } from "@chakra-ui/react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import React from "react";
const Loader = () => {
  return (
    <>
      <Center
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(255, 255, 255, 0.8)"
        zIndex="9999"
      >
        <VStack>
          <ReactLoading type="spinningBubbles" color="#246BFD" />
        </VStack>
      </Center>
    </>
  );
};

export default Loader;
