import React from 'react';

const BeneficiariesIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4579 11.4417H11.8853C12.2236 11.4417 12.5041 11.1667 12.5041 10.8251C12.5041 10.4751 12.2236 10.2001 11.8853 10.2001H10.4579V8.75841C10.4579 8.41675 10.1856 8.14175 9.84736 8.14175C9.50907 8.14175 9.22855 8.41675 9.22855 8.75841V10.2001H7.8094C7.47112 10.2001 7.19059 10.4751 7.19059 10.8251C7.19059 11.1667 7.47112 11.4417 7.8094 11.4417H9.22855V12.8834C9.22855 13.2251 9.50907 13.5001 9.84736 13.5001C10.1856 13.5001 10.4579 13.2251 10.4579 12.8834V11.4417ZM16.1151 7.52142C16.309 7.51919 16.5202 7.51675 16.712 7.51675C16.9183 7.51675 17.0833 7.68341 17.0833 7.89175V14.5917C17.0833 16.6584 15.4249 18.3334 13.3705 18.3334H6.81105C4.66584 18.3334 2.91666 16.5751 2.91666 14.4084V5.42508C2.91666 3.35841 4.58333 1.66675 6.63779 1.66675H11.0437C11.2582 1.66675 11.4233 1.84175 11.4233 2.05008V4.73341C11.4233 6.25841 12.6691 7.50841 14.179 7.51675C14.5317 7.51675 14.8426 7.51938 15.1147 7.52169C15.3264 7.52349 15.5146 7.52508 15.6807 7.52508C15.7981 7.52508 15.9504 7.52332 16.1151 7.52142ZM16.3417 6.30525C15.6634 6.30775 14.8639 6.30525 14.2889 6.29942C13.3763 6.29942 12.6247 5.54025 12.6247 4.61858V2.42191C12.6247 2.06275 13.0562 1.88441 13.3029 2.14358C13.7494 2.61249 14.363 3.25703 14.9737 3.89863C15.583 4.53868 16.1896 5.17581 16.6247 5.63275C16.8656 5.88525 16.689 6.30442 16.3417 6.30525Z" fill="#929EAE" />
        </svg>
    )
}

export default BeneficiariesIcon





