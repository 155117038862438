import OtherDashboard from "container/dashboard/OtherDashboard";
import React from 'react'
import RewardsContainer from "container/Rewards/RewardsContainer";

const RewardsPage = () => {
  return (
    <OtherDashboard>
        <RewardsContainer />
    </OtherDashboard>
  )
}

export default RewardsPage;
