import SecurityRegistration from "container/register/Security";
import React from "react";




const SecurityRegistrationPage = () => {

    return(
        <SecurityRegistration />
    )
}

export default SecurityRegistrationPage