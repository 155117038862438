import React from 'react'
import style from './heading.module.scss'


interface HeadingProps {
    title: string
    desc: string
    desc2?: string
}

/**
 * Functional component that renders a section with a heading and two paragraphs.
 *
 * @component
 * @example
 * ```tsx
 * <Heading title="Hello" desc="This is a description" desc2="This is another description" />
 * ```
 *

 * @param {string} props.title - The title to be displayed in the heading.
 * @param {string} props.desc - The description to be displayed in the first paragraph.
 * @param {string} [props.desc2] - An optional second description to be displayed in the second paragraph.
 * @returns {JSX.Element} The rendered component.
 */

const Heading: React.FC<HeadingProps> = ({
    title, desc , desc2
}) => {
    return (
        <section className={style.heading}>
            <h1>
                {
                    title
                }
            </h1>
            <p>
                {
                    desc
                }
            </p>
            <p>
                {
                    desc2
                }
            </p>
        </section>
    )
}


export default Heading