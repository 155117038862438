import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import MyCardsContainer from 'container/MyCards/MyCardsContainer'

const  MyCardsPage = () => {
  return (
   <OtherDashboard>
    <MyCardsContainer />
   </OtherDashboard>
  )
}

export default MyCardsPage