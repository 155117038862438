import React from 'react';
import style from './leftmenu.module.scss'
import DashboardContext from 'context/Dashboard/DashboardContext';
import CardSearch from './CardSearch';
import {Text} from '@chakra-ui/react'



const LeftMenu = ({
    name
}:{name:string}) => {
    const dashboardContext = React.useContext(DashboardContext);
    const {currentPagename} = dashboardContext;
    return(
        <section className={style.leftmenu}>
            {
                currentPagename === "card" ? <CardSearch /> :  <Text fontSize={{
                    base:'1rem' , lg:'1.2rem' , '2xl':'1.563rem'
                }} as={'h1'} className={style.leftmenu__title} data-testid="leftmenu-title" aria-label={name} >
                  {name}
              </Text>
            }

        </section>
    )

}

export default LeftMenu;