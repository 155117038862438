import React, { FC } from 'react';
import { CheckedIcon, NotCheckedIcon, CheckedLine , UnCheckedLine } from './TrckerImages/Icons'
import styles from './ProgressTracker.module.scss'
interface ProgressTrackerProps {
    levels: string[],
    steps: string[],


}


const ProgressTracker: FC<ProgressTrackerProps> = ({
    levels, steps,
}) => {
    return (
        <div className='flex items-center'>
            {
                levels.map((level, index) => {
                    //check if index is the last level
                    const isLastLevel = index === levels.length - 1;
                    //get step by index
                    const step = steps[index];
                    return (
                        step ?
                            <div key={index} className='relative flex items-center' >
                                <div>
                                    <CheckedIcon />
                                    <h3 className={`${styles.title} ${isLastLevel ? styles.titleLastLevel : ""
                                        }`}> {level} </h3>
                                </div>
                                {isLastLevel ? null : <CheckedLine />}

                            </div>
                            :
                            <div key={index} className='relative flex items-center' >
                                <div>
                                    <NotCheckedIcon />
                                    <h3 className={`${styles.title} ${isLastLevel ? styles.titleLastLevel : ""
                                        }`}> {level} </h3>
                                </div>
                                {isLastLevel ? null : <UnCheckedLine />}


                            </div>
                    )



                })
            }

        </div>
    )

}

export default ProgressTracker;
