import React  , {useState , useEffect , useContext} from 'react'
import VerifyOwnership from './VerifyOwnership'
import SetNewPin from './SetNewPin'
import DashboardContext from 'context/Dashboard/DashboardContext'

const ChangeTransactionPin = () => {
    const [step , setStep] = useState<number>(1)
    const handleStep = ()=>{
        setStep(step+1)
    }

    const {
        setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
    } = useContext(DashboardContext)




    useEffect(() => {
        setCurrentPagename('Confirm OTP')
        if(step === 2){
            setCurrentPagename('Set New PIN')
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgContentColor ? setBgContentColor("#FAFAFA") : null
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null

    }, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor, step])
  return (
    <div >
        {
            step === 1? <VerifyOwnership onClick={handleStep} /> : <SetNewPin onClick={()=>{}} />
        }
    </div>
  )
}

export default ChangeTransactionPin