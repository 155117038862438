import Input from "components/form/Auth/Input";

import { Link, useNavigate } from "react-router-dom";
import ContainerWrapper from "../container/Container";
import React from "react";
import { useForm } from "react-hook-form";
import { useResetLoginPinMutation } from "features/Auth/AuthApiSlice";
import { Toaster, toast } from "react-hot-toast";

import Spinner from "components/Spinner/Spinner";
// import { useAppDispatch } from "hooks/redux";
// import { login } from "features/Auth/AuthSlice";

const RequestOTP = () => {
  // const location = useLocation();

  //   const from = location.state?.from || "/";

  const { control, handleSubmit } = useForm({});

  const navigate = useNavigate();
  // const dispatch = useAppDispatch()
  const [sendOtpToUser, { isLoading }] = useResetLoginPinMutation();

  const onSubmit = async (data: any) => {
    sessionStorage.setItem("mail", data.email);
    const formData = new FormData();
    formData.append("email", data.email as string);
    try {
      const res = await sendOtpToUser(formData).unwrap();
      if (res) {
        // sessionStorage.setItem("mail", data.email);
        sessionStorage.setItem("id", res.data);
        toast.success("OTP has been sent to your email");
        navigate("/reset-pin");
      }
    } catch (error: any) {
      if (error.status === 401) {
        toast.error("We could not find your account");
      }
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="2xl:pt-[3.1875rem] md:pt-[3.1875rem] p-[1.25rem] md:flex md:flex-col md:gap-[4.375rem] lg:gap-0 gap-0 lg:grid grid place-items-start justify-center lg:px-0 h-[100vh] max-w-full"
      >
        <img
          src="/jarapay.svg"
          alt="jarapay"
          width={130}
          height={35}
          className="lg:w-[8.125rem] h-[2.1875rem] 2xl:mb-[7.0625rem] mb-[2.5rem]"
        />
        <section className="max-w-[max-content]">
          {/* Welcome Message */}
          <section className="flex gap-[2.8125rem] flex-col items-start">
            <div className="">
              <h1 className="text-[rgb(27,33,45)] text-[1.875rem] font-semibold pb-[0.3125rem] lg:pb-[0.5rem] font-nunito">
                Reset Login PIN
              </h1>
              <h6 className="font-[400] text-[#78778B] text-[1rem]">
                Please enter your registered email address
              </h6>
            </div>
            {/* Input Boxes */}
            <div className="flex flex-col gap-[0.9375rem] w-full">
              <Input
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please Enter a Valid Email.",
                  },
                }}
                name="email"
                control={control}
                title="Email Address"
                type="email"
                placeholder="Email"
              />
            </div>
          </section>

          {/* Sign In Button */}
          <div className="grid place-items-center">
            <button
              disabled={isLoading}
              className=" disabled:cursor-not-allowed btn-primary   transition-all mt-[1.5625rem] xl:mb-[1.25rem] mb-[3rem] 2xl:mb-[3rem]"
            >
              Send
            </button>
          </div>

          {/* Reset Pin Link */}
          {/* <div className="text-gray-900 text-[1.4rem] lg:w-[40.4rem] font-[500] mb-[2rem]  2xl:mb-[4.8rem]  flex place-items-center justify-center">
                        <Link to="/reset-pin" className="text-dark text-[1.4rem] font-[500]  block">
                            Reset Login PIN?
                        </Link>
                    </div> */}

          {/* Sign Up Link */}
          <div className="flex items-center justify-center gap-[0.125rem] lg:w-[25.25rem]">
    <p className="text-[#929EAE] text-[0.875rem] font-[500] text-center">
        {"Have an account?"}
    </p>
    <h6 className="text-dark text-[0.875rem] font-[500] text-center relative">
        <Link to={"/sign-in"} className="hover:font-bold">
            Login
        </Link>
        <span className="absolute top-[1.25rem] left-[0.125rem]">
            <img
                src="/Auth/rule.png"
                alt="rule"
                width={96}
                height={10}
                className="w-[6rem] h-[0.625rem]"
            />
        </span>
    </h6>
</div>

        </section>
      </form>
    </ContainerWrapper>
  );
};

export default RequestOTP;
