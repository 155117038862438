import React  from 'react'
import { Navigate , useLocation , Outlet} from 'react-router-dom'
import { getCookie } from 'utils/utilsCookies'



const Layout = ({
    children
}: {
    children: React.ReactNode
}) => {
    const isAuth = getCookie('jara_access')


    const location = useLocation()
    return isAuth ? (
        <>
         <Outlet />
         {children}
        </>

      ) : (
        <Navigate to="/sign-in" replace state={{ from: location.pathname }} />
      );
}

export default Layout