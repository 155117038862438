import React, { useEffect } from 'react';
import Heading from './Heading/Heading';
import BackButton from 'components/buttons/backbutton/BackButton';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker2';
import style from './generalservicestyle.module.scss'
import PinForm from './Forms/EnterPin';
import ConfirmForm from './Forms/ConfirmForm';
import { setAmountToFund } from 'features/Wallet/WalletSlice';
import ServicesPayment from 'components/paymentOptions/ServicesPayment';
import DashboardContext from 'context/Dashboard/DashboardContext';
import { useGetInternetProvidersQuery } from 'features/Services/InternetService/InternetServiceApiSlice';
import { setInternetProviders } from 'features/Services/InternetService/InternetServiceSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useForm } from 'react-hook-form'
import InternetForm from '../services/Form/InternetForm';
import { setDataDetails } from 'features/Services/DataService/DataSlice';
import { useVerifyInternetServiceMutation } from 'features/Services/InternetService/InternetServiceApiSlice';
import LoadingSpinner from 'components/Spinner/Spinner';
import { toast } from 'react-hot-toast';
import formatLocalCurrency from 'utils/formatLocalCurrency';

import { setSuccess  , setPin} from 'features/Services/ServicesSlice';
import { useCreateSubscriptionMutation } from 'features/Subscription/SubscriptionApiSlice';


const InternetService = () => {
    const [step, setStep] = React.useState<'1' | "2" | "3" | "4">("1")
    const [steps, setSteps] = React.useState<string[]>(["1"])
    const { setCurrentPagename } = React.useContext(DashboardContext)
    const dispatch = useAppDispatch()
    const { setValue, control, handleSubmit } = useForm()
    const { formVariation } = useAppSelector((state: any) => state.services as any)
    const [verifyInternetService, { isLoading }] = useVerifyInternetServiceMutation()
    const { internetProviders } = useAppSelector((state: any) => state.internetService as any)
    const { dataDetails } = useAppSelector((state: any) => state.dataService as any)
    const wallet = useAppSelector(state => state.wallet as any);


    //pay mutation
    const [Subscribe, { isLoading: payLoading, isSuccess }] = useCreateSubscriptionMutation()
    const { pin } = useAppSelector((state: any) => state.services as any)

    useEffect(() => {
        dispatch(setSuccess(isSuccess))
        dispatch(setPin([]))

    }, [isSuccess, dispatch])



    const onSubmit = async (data: any) => {
        if (step === "1") {
            const serviceID = internetProviders?.find(
              (item: any) => item?.name === data?.provider?.label
            );

            try {
              const res = await verifyInternetService({
                serviceID: serviceID?.serviceID,
                billersCode: data?.email,
              }).unwrap();
              if (res?.data?.content?.error) {
                toast.error(res?.data?.content?.error);
              } else {
                dispatch(
                  setDataDetails({
                    ...serviceID,
                    ...data,
                    ...formVariation,
                    accountName: res?.data?.content?.Customer_Name,
                    accountId: res?.data?.content?.AccountList?.Account[0]?.AccountId,
                    variation_amount: data?.bundle?.variation_amount,
                  })
                );
                handleNext();
              }
            } catch (error: any) {
              console.log(error);
            }
          }
        if (step === "2") {
            handleNext()
        }

        if (step === "4") {

            const data: any = {
                'category_sub':'Internet',
                serviceID: dataDetails?.serviceID,
                variation_code: dataDetails?.bundle?.variation_code,
                billersCode: dataDetails?.accountId,
                pin: pin?.join(""),
                variation_amount: dataDetails?.bundle?.variation_amount,
                slug_id: wallet.paymentSlug,
              };
              if (wallet.paymentSlug === 2 && wallet.signature) {
                data.signature = wallet.signature;
              }

            try {
                await Subscribe(data).unwrap()
                dispatch(setPin([]));
                dispatch(setSuccess(true));






            } catch (error: any) {
                toast.error('Internet subscription failed')

            }
        }


    }

    const { data } = useGetInternetProvidersQuery(undefined)

    useEffect(() => {
        if (data) {
            dispatch(setInternetProviders(data?.content))
        }
    }, [data, dispatch])



    const handleNext = () => {
        setSteps([...steps, step])


        if (step === "1") {
            setStep("2")

        }
        if (step === "2") {
            setStep("3")

        }
        if (step === "3") {
            setStep("4")
        }

    }

    const handleBack = () => {
        setSteps(steps.slice(0, -1))


        if (step === "1") {
            setStep("1")

        }

        if (step === "2") {
            setStep("1")

        }
        if (step === "3") {
            setStep("2")
        }
        if (step === "4") {
            setStep("3")

        }



    }

    let text = "All Subscriptions"
    if (step === "1") {
        setCurrentPagename("Internet")
    }
    if (step === "2") {
        text = "Internet"
        setCurrentPagename("Confirm ")
    }
    if (step === "3") {
        text = "Confirm"
        setCurrentPagename("Payment Options")

    }
    if (step === "4") {

        setCurrentPagename("Transaction PIN")
        text = "Payment options"


    }

    return (
        <>
            {
                isLoading || payLoading ? <LoadingSpinner /> : null
            }
            <form onSubmit={handleSubmit(onSubmit)} className={style.data} >
                <div className='pl-[2.5rem]' onClick={handleBack}>
                    <BackButton text={
                        text

                    } link={
                        step === "1" ? "/subscriptions" : "/subscriptions?category=internet"
                    } />
                </div>

                <section className={style.data__section}>

                    {step === "1" ? <Step1 steps={steps} control={control} setValue={setValue} /> : null}

                    {step === "2" ? <Step2 steps={steps} /> : null}

                    {step === "3" ? <Step3 steps={steps} handleNext={handleNext} /> : null}



                    {
                        step === "4" ? <Step4 /> : null
                    }

                    <div className='grid place-content-center place-items-center'>
                        {(step !== "4" && step !== "3") ? <button type={'submit'} className='btn-primary'>Continue</button> : null}

                    </div>

                </section>

            </form>
        </>
    )

}

export default InternetService




const Step1 = ({
    steps, control, setValue
}: {
    steps: string[], control: any, setValue: any
}) => {
    return (
        <div>
            <Heading title={"Create Internet Subscription"} desc="Put your data bills on reoccurring, It's safe and we " desc2='always deliver.' />
            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Internet Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>

            <InternetForm control={control} setValue={setValue} />
        </div>
    )
}




const Step2 = ({
    steps
}: {
    steps: string[]
}) => {

    const [toggle, setToggle] = React.useState<boolean>(true)

    const handleToggle = () => {
        setToggle(!toggle)

    }
    const { dataDetails } = useAppSelector((state: any) => state.dataService as any)
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(
        setAmountToFund(
          formatLocalCurrency(dataDetails?.variation_amount as string)
        )
      );
    }, [dataDetails ,  dispatch]);
    const confirmData = [
        {
          title: "Account ID",
          value: dataDetails?.accountId,
        },
        {
          title: "Account Name",
          value: dataDetails?.accountName,
        },
        {
          title: "Bundle Amount",
          value: formatLocalCurrency(dataDetails?.bundle?.variation_amount),
        },
        {
          title: "Phone Number",
          value: dataDetails?.accountId,
        },
        {
          title: "Renewal Status",
          value: dataDetails?.renewal?.value,
        },
        {
          title: "Service Charge",
          value: "No",
        },

        {
          title: "Total",
          value: formatLocalCurrency(dataDetails?.bundle?.variation_amount),
        },
      ];

    return (
        <div>
            <Heading title={"Confirm Internet Subscription"} desc="Please confirm your internet subscription" />

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Internet Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>
            <ConfirmForm handleSaveBeneficiary={handleToggle} saveBeneficiary={
                toggle
            } data={confirmData} src={dataDetails?.image} />
        </div>
    )

}


const Step3 = ({
    steps, handleNext
}: {
    steps: string[], handleNext: () => void
}) => {
    return (

        <div>

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`} >
                <ProgressTracker levels={["Internet Options", "Confirm Details", "Select Payment", "Enter Pin & Confirm"]} steps={steps} />
            </div>
            <ServicesPayment title={'Confirm Subscription'} onClick={handleNext} />

        </div>
    )
}


const Step4 = () => {
    const confirmDetails = useAppSelector(state => state.dataService?.dataDetails as any)
    return (
        <div className='grid place-content-center place-items-center pt-[2.5rem]' >

            <PinForm amount={
                `Create Subscription`
            }
                successMessage={{
                    title: "Internet Subscription Created",
                    message: <>{`Your have successfully created internet subscription  of `}<span>{
                        formatLocalCurrency(confirmDetails?.variation_amount)
                    }</span></>,
                    buttonText: 'Dashboard',
                    link: '/dashboard'
                }}
            />
        </div >
    )
}