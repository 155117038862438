import { authApi } from "app/ApiSlice";
import { store } from "app/Store/Store";



const AirtimeServiceApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
        airtimeProvider : builder.query({
            query : () => `/v1/bill/airtime/provider`
        }),
        selectAirtimeProvider: builder.query({
            query: (id : string)=> ({
                url: `/v1/bill/airtime/service?service_id=${id}`

            })
        }),
        buyAirtime: builder.mutation({
            query: (data) => ({
                url : '/v1/bill/airtime/buy',
                body: data,
                method: 'POST',
            }),
            invalidatesTags: ['Profile'],






            



        })


    })

})

export const {useAirtimeProviderQuery , useBuyAirtimeMutation , useSelectAirtimeProviderQuery} = AirtimeServiceApiSlice