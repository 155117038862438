import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServicesInput from "components/form/Services/Input";

//import { useAppSelector, useAppDispatch } from "hooks/redux";

import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useSelectInsuranceProviderQuery } from "features/Services/Insurance/InsuranceApiSlice";
import DateInput from "components/form/Services/DateInput";
import FileInput from "components/form/Services/FileInput";

const PersonalAccidentForm = ({
  control,
  setValue,
  provider,
}: {
  control: any;
  setValue: any;
  provider: any;
}) => {
  const { data: insuranceType, isLoading } = useSelectInsuranceProviderQuery(
    { ...provider },
    {
      skip: !provider,
    }
  );
  const [insurancePackageOptions, setInsurancePackageOptions] = useState<any[]>(
    []
  );
  const [extraFields, setExtraFields] = useState<any[]>([]);

  useEffect(() => {
    if (insuranceType) {
      setExtraFields(insuranceType?.extra_fields_insurance?.content);
      setInsurancePackageOptions(
        insuranceType?.insurance?.content?.varations?.map((item: any) => {
          return {
            ...provider,
            ...item,
            label: item?.name,
            value: item?.variation_code,
            image: item?.image,
          };
        })
      );
    }
  }, [insuranceType]);

  return (
    <form className="flex items-center justify-center relative w-full">
      <div className={style.form}>
        <DropdownWithImage
          rules={{ required: "Field cannot be empty." }}
          control={control}
          options={insurancePackageOptions}
          name="variation"
          defaultValue={insurancePackageOptions[0]}
          title={"Insurance Package"}
          placeholder={"select option"}
        />
        <ServicesInput
          name="phone"
          control={control}
          label="Phone Number"
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          placeholder={"phone number"}
          type={"number"}
          maxLength={13}
        />

        <ServicesInput
          name="full_name"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Full Name"}
          placeholder={"full name"}
        />
        <ServicesInput
          name="address"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Address"}
          placeholder={"address"}
        />
        <ServicesInput
          name="next_kin_name"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Next Kin Name"}
          placeholder={"next kin name"}
        />
        <ServicesInput
          name="next_kin_phone"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Next Kin Phone"}
          placeholder={"next kin phone"}
        />
        <ServicesInput
          name="business_occupation"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Business or Occupation"}
          placeholder={"business or occupation"}
        />
      </div>
    </form>
  );
};

export default PersonalAccidentForm;
