import { authApi } from "app/ApiSlice";

const TransactionApiSlice = authApi.injectEndpoints({
  endpoints: (builder) => ({
    //Transaction Endpoint
    getFunding: builder.query({
      query: (page) => `/v1/payment/funding-history-web`,
    }),
    getFundingByPage: builder.query({
      query: ({ page }) => `/v1/payment/funding-history?page=${page}`,
      // transformResponse(baseQueryReturnValue, meta, arg) {

      //     arg.dispatch(setTransactions(baseQueryReturnValue))

      // }
    }),
    //Transaction Endpoint
    getTransactions: builder.query({
      query: (page) => `/v1/payment/trasaction-history-web`,
    }),
    getTransactionById: builder.query({
      query: (request_id) => `/v1/payment/view-history?request_id=${request_id}`,
    }),
    getTransactionsByPage: builder.query({
      query: ({ page }) => `/v1/payment/trasaction-history?page=${page}`,
      // transformResponse(baseQueryReturnValue, meta, arg) {

      //     arg.dispatch(setTransactions(baseQueryReturnValue))

      // }
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionsByPageQuery,
  useGetFundingByPageQuery,
  useGetFundingQuery,
  useGetTransactionByIdQuery ,
} = TransactionApiSlice;
