import React from "react";
import { Text, Button, Grid, Flex } from "@chakra-ui/react";
import { useRedeemRewardMutation } from "features/Profile/ProfileApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { toast } from "react-hot-toast";
const RedeemDialog = ({
  amount,
  onClose,
}: {
  amount: string;
  onClose: any;
}) => {
  const [redeemReward, { isLoading }] = useRedeemRewardMutation();

  const handleRedeem = async () => {
    try {
      await redeemReward({ amount }).unwrap();
      toast.success("Reward redeemed successfully");
      onClose();
    } catch (error) {
      toast.error("Error redeeming reward");
      onClose();
    }
  };

  return (
    <Grid padding={"1rem"} borderRadius={".5rem"} bg={"white"} gap={"1.5rem"}>
      {isLoading ? <LoadingSpinner /> : null}
      <Text
        fontSize={{
          base: "1rem",
          lg: "1.25rem",
          "2xl": "1.5rem",
        }}
        textAlign={"center"}
        fontWeight={"bold"}
        color={"var(--dark-text)"}
      >
        Redeem Commission
      </Text>
      <Text
        textAlign={"center"}
        fontSize={".875rem"}
        color={"var(--gray-text)"}
      >
        Are you sure you want to redeem your commission?
      </Text>
      <Flex justifyContent={"center"} gap={"5rem"} mt={"2rem"}>
        <Button onClick={onClose} colorScheme="red" variant={"outline"}>
          Cancel
        </Button>
        <Button
          onClick={handleRedeem}
          bg={"var(--primary)"}
          colorScheme={"blue"}
        >
          Redeem
        </Button>
      </Flex>
    </Grid>
  );
};

export default RedeemDialog;
