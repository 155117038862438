import FundWalletContainer from "container/fundwallet/FundWalletContainer";
import OtherDashboard from "container/dashboard/OtherDashboard";
import React from "react";



const FundWalletPage = () => {
    return (
        <OtherDashboard >
            <FundWalletContainer />
        </OtherDashboard>
    )

}

export default  FundWalletPage
