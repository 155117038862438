import React from 'react';
import { Heading, Text, Grid, Flex, Button, Image, useBreakpointValue } from '@chakra-ui/react';
import { useDeleteSubscriptionMutation } from 'features/Subscription/SubscriptionApiSlice';
import {toast} from 'react-hot-toast'


interface Prop {
  item: any;
  onDelete?: () => void; // Function to handle deletion
  onClose: () => void;
}

const DeleteSubscription: React.FC<Prop> = ({ item, onDelete , onClose }) => {
  const headingSize = useBreakpointValue({ base: 'md', lg: 'lg' });
  const textSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  const [deleteSubscription , {isLoading}] = useDeleteSubscriptionMutation();
  const onSubmit = async()=> {
    try {
      await deleteSubscription({id:item.id}).unwrap();
      toast.success('Subscription deleted successfully');
      onClose()

    } catch (error) {
      toast.error('Failed to delete subscription');
    }
  }


  return (
    <Flex direction="column" alignItems="center" justifyContent="center" gap={'1rem'} >
      <Heading size={headingSize} mb={4}>
        Delete Subscription
      </Heading>
      <Text fontSize={textSize} mb={8}>
        Are you sure you want to delete this subscription?
      </Text>
      <Grid gridTemplateColumns="1fr 1fr" gap={4} >
        <Button onClick={onSubmit} colorScheme="red" width="100%" isLoading={isLoading} loadingText={'Deleting...'}>
          Confirm Delete
        </Button>
        <Button variant="outline"  width="100%" onClick={onClose}>
          Cancel
        </Button>
      </Grid>
    </Flex>
  );
};

export default DeleteSubscription;
