import React, { useState } from "react";
import Input from "components/form/Auth/Input";
import style from "./mailgiftcard.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { setThirdPartyEmail } from "features/Services/GiftCardService/GiftCardServiceSlice";
import { useAppDispatch } from "hooks/redux";
import { Checkbox } from "@chakra-ui/react";

const MailGiftCard = () => {
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleShow = () => setShow(!show);
  const { control, watch } = useForm();

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  React.useEffect(() => {
    if (watch("email") && validateEmail(watch("email"))) {
      dispatch(setThirdPartyEmail(watch("email")));
    }
  }, [dispatch, watch("email")]);

  return (
    <form className={style.mail}>
      <div className={style["form-toggle"]} onClick={handleShow}>
        <Checkbox isChecked={show}  />
        <span>Send to a third-party recipient (optional)</span>
      </div>

      <AnimatePresence>
        {show && (
          <motion.div
            className={style.bottom}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5, type: "spring", ease: "easeOut" }}
          >
            <div className={style["form-group"]}>
              <Input
                title="Family or Friend Email"
                type="text"
                placeholder="Email"
                control={control}
                name={"email"}
              />
            </div>
            <p>JaraPay will send Giftcard codes to the recipient for FREE</p>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
};

export default MailGiftCard;
