

export const header = [
    "services" , "type" , "amount" , "DATE/TIME" , "REFERENCE", "action" ,
]



export const data = [
   {
    img : '/Transactions/mobile.svg',
    name: 'Iphone 13 Pro MAX',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : '-N520.84',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',

  },

  {
    img : '/Transactions/entertainment.svg',
    name: 'Netflix Subscription',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : 'N890.90',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/software.svg',
    name: 'Figma Subscription',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : '- N30000',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/technology.svg',
    name: 'Bitcoin Transaction',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : '- N3000',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/withdrawal.svg',
    name: 'Sajib Rahman',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : 'N900.00',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/instagram.svg',
    name: 'Instagram Ads',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : 'N100.00',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/payment.svg',
    name: 'UIHUT Subscription',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : '- N4000.00',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    reference : '123456789',
  },
  {
    img : '/Transactions/bank.svg',
    name: 'Citi Bank Ltd.',
    company :'City Bank',
    type : 'Withdraw',
    amount : 'N400.11',
    date : '14 Apr 2022',
    time   : 'at 7:00 AM',
    reference : '123456789',
  },





]