import { createSlice } from "@reduxjs/toolkit";


const BeneficiarySlice = createSlice({
    initialState: {
        beneficiary: [],
        addBeneficiary: false,
        airtime: null,
        tv: null,
        data: null,
        internet: null,
        electricity: null,
        education: null



    },

    name: "beneficiary",
    reducers: {
        setBeneficiary: (state, action) => {
            state.beneficiary = action.payload

        },
        setAddBeneficiary: (state, action) => {
            state.addBeneficiary = action.payload

        },
        loadBeneficiary: (state, action) => {

            const data = action.payload
            if (data.category) {
                if (data.category?.toLowerCase() === 'airtime') {
                    state.airtime = data.number

                }
                else if (data.category?.toLowerCase()   === 'tv') {
                    state.tv = data.number
                }
                else if (data?.category?.toLowerCase()  === 'data') {
                    state.data = data.number

                }
                else if (data?.category?.toLowerCase() === 'internet') {
                    state.internet = data.number
                }
                else if (data.category?.toLowerCase() === 'electricity') {
                    state.electricity = data.number
                }
            }
        }


    }

})

export const { setBeneficiary, loadBeneficiary } = BeneficiarySlice.actions;
export default BeneficiarySlice.reducer;