import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import BvnVerification from 'container/BvnVerification/BvnVerification'

const BvnVerificationPage = () => {
  return (
   <OtherDashboard>
    <BvnVerification />
   </OtherDashboard>
  )
}

export default BvnVerificationPage