import { authApi } from "app/ApiSlice";


const InsuranceApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
    //Insurance Endpoint
    getInsuranceProvider: builder.query({
        query: () => `/v1/bill/insurance/provider`,


    }),

    selectInsuranceProvider: builder.query({
        query: ({name , serviceID }) => ({
            url: `/v1/bill/insurance/service?service_id=${serviceID}&option=selected_hospital`,
            method: 'GET',

        }),
    }),

    selectInsuranceProviderByService : builder.query({
        query: ({name , serviceID , option}) => ({
            url : `/v1/bill/insurance/service?name=${name}&service_id=${serviceID}&option=${option}`,
            method : 'GET',
        })
    }),


    buyInsurance: builder.mutation({
        query: (insurance) => ({
            url: `/v1/bill/insurance/buy`,
            method: 'POST',
            body: insurance
        }),
        invalidatesTags: ['Profile']


    }),


})
})

export const {useGetInsuranceProviderQuery , useSelectInsuranceProviderQuery ,
     useBuyInsuranceMutation , useSelectInsuranceProviderByServiceQuery} = InsuranceApiSlice