import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Button,
  Grid,
} from "@chakra-ui/react";

import React from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading?: boolean;
};

const DeleteDialog = ({ isOpen, onClose, onClick, isLoading }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Card</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={"2rem"}>
          <Grid
            gap={{
              base: "2rem",
              lg: "3rem",
            }}
            placeContent={"center"}
            placeItems={"center"}
          >
            <Text fontWeight={"500"} color={"var(--dark-text)"}>
              Are you sure you want to delete this card?
            </Text>
            <Flex
              gap={{
                base: "3rem",
                lg: "4rem",
              }}
            >
              <Button
                colorScheme={"red"}
                onClick={onClick}
                isLoading={isLoading}
                loadingText="Deleting..."
              >
                Yes
              </Button>
              <Button
                variant={"outline"}
                borderColor={"var(--primary)"}
                onClick={onClose}
                colorScheme="messenger"
              >
                No
              </Button>
            </Flex>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteDialog;
