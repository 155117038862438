import React from 'react';
import SelectInsurance from './SelectInsurance'
import style from './insurance.module.scss'


interface Props {
    handleClick : (value : string) => void;
    data: {

        title : string
        imageSrc : string
    }[]
    providers?: any[]

}



const ListInsurance: React.FC<Props> = ({
    data , handleClick , providers
}): JSX.Element=> {


    const  [selected , setSelected] = React.useState<string>("")
    const handleSelect = (value:string)=>{
        setSelected(value)

    }
    return (
        <div className='grid place-content-center'>
              <main className={style.insurance__container}>
            {
               providers?.map((item , index:number) => {
                    return(
                        <SelectInsurance handleClick={handleClick} key={index} item = {item} handleSelect = {handleSelect} selected={selected}  />

                    )
                })
            }
        </main>
        </div>

    )


}


 export default ListInsurance