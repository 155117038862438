import React from 'react'
import Dashboard from 'container/dashboard/OtherDashboard'
import TransactionsContainer from 'container/Transactions/TransactionsContainer'
import { useEffect } from 'react'
import { useGetTransactionsQuery , useGetFundingQuery } from 'features/Transactions/TransactionApiSlice'
import { useAppDispatch } from 'hooks/redux'
import { setTransactions , setFunding } from 'features/Transactions/TransactionSlice'



/**
 * Functional component that renders a TransactionsPage.
 *
 * @returns The rendered TransactionsPage component.
 */
const TransactionsPage = () => {
    const dispatch = useAppDispatch()

    const { data: transactions} =
    useGetTransactionsQuery(undefined);

  useEffect(() => {
    dispatch(setTransactions(transactions));
  }, [dispatch, transactions]);


  const { data: funding, } =
    useGetFundingQuery(undefined);

  useEffect(() => {
    dispatch(setFunding(funding));
  }, [dispatch, funding]);



    return (
        <Dashboard >
            <TransactionsContainer />
        </Dashboard>

    )
}

export default TransactionsPage