import React from 'react'
import SelectDropdown from 'components/form/Services/SelectDropdown'
import { useForm  } from 'react-hook-form'
import { useBreakpointValue  , Grid , Heading , Text} from '@chakra-ui/react'

interface Prop {
  item: any
}



const UpdateSubscription: React.FC<Prop> = ({item}) => {
  const {control , handleSubmit} = useForm({})
  const headingSize = useBreakpointValue({ base: 'md', lg: 'lg' });
const textSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });



  return (
    <Grid>
       <Heading size={headingSize} mb={4}>
        Update Subscription
      </Heading>
      <Text fontSize={textSize} mb={8}>
         Kindly select the field you want to update
      </Text>
      <Grid>
        <SelectDropdown  name='type' placeholder='select supscription type' control={control} title={'Select Subscription Type'} options={[]} />
      </Grid>




    </Grid>
  )
}

export default UpdateSubscription