import ServiceInputDropdown from "components/form/Services/DropdownInput";
import ServicesInput from "components/form/Services/Input";
//import ServicesPlainDropdown from 'components/form/Services/PlainDropdown';
import style from "./form.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import {
  useGetInternetServiceQuery,
  useGetInternetVariantQuery,
} from "features/Services/InternetService/InternetServiceApiSlice";
import React, { useState } from "react";
import { setFormVariation } from "features/Services/ServicesSlice";
import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useWatch } from "react-hook-form";
import { useDisclosure } from "@chakra-ui/react";
import SelectBeneficiaryModal from "components/Beneficiaries/SelectBeneficiaryModal";
import useRenderRenewalStatus from "hooks/useRenderRenewalStatus";

const InternetForm = ({
  control,
  setValue,
}: {
  control: any;
  setValue: any;
}) => {
  //use dispatch
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { internetProviders } = useAppSelector(
    (state) => state.internetService as any
  );

  const { internet } = useAppSelector((state) => state.beneficiary);

  //service name
  const [name, setName] = useState<string>(internetProviders[0]?.serviceID);
  const [variationData, setVariationData] = useState<string>("");
  const provider = useWatch({ control, name: "provider" });

  //service id
  const { data: internetServices } = useGetInternetServiceQuery(
    provider?.serviceID,
    {
      skip: !provider,
    }
  );
  const { data: internetVariantion } = useGetInternetVariantQuery(
    {
      service_id: provider?.serviceID,
      name: provider?.label,
    },
    {
      skip: !provider,
    }
  );

  const getItemByService = (name: string) => {
    const service_id = internetProviders?.find(
      (item: any) => item.name === name
    )?.serviceID;
    setName(service_id);
    setVariationData(service_id?.name);
  };

  const getItemByName = (name: string) => {
    const v_data = internetVariantion?.content?.varations?.find(
      (item: any) => item.name === name
    );
    dispatch(setFormVariation(v_data));
  };

  const internetProviderOptions = internetProviders?.map((item: any) => ({
    ...item,
    value: item.serviceID,
    label: item.name,
  }));

  const bundleOptions = internetServices?.content?.varations?.map(
    (item: any) => ({
      ...item,
      value: item.serviceID,
      label: item.name,
    })
  );

  const renewalStatusData = useRenderRenewalStatus();

  return (
    <div className="flex items-center justify-center w-full">
      <div className={style.form}>
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          name="provider"
          defaultValue=""
          title={"Select Provider"}
          placeholder={"Select Provider"}
          options={internetProviderOptions}
          control={control}
          setValue={setValue}
        />
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          name="bundle"
          defaultValue=""
          title={"Select Bundle"}
          placeholder={"Select Bundle"}
          options={bundleOptions}
          control={control}
          setValue={setValue}
        />
        <ServicesInput
          rules={{
            required: "Field cannot be empty.",
          }}
          onChange={() => {}}
          type="email"
          name="email"
          defaultValue={internet as unknown as string}
          setValue={setValue}
          label={`Registered ${
            internetVariantion?.content?.ServiceName
              ? internetVariantion?.content?.ServiceName?.split(" ")[0]
              : ""
          } Email`}
          placeholder={"Email"}
          control={control}
        />
         <p className='hover:font-bold cursor-pointer' onClick={onOpen}>
                    Select Beneficiary
                </p>
        <DropdownWithImage
          setValue={setValue}
          control={control}
          options={renewalStatusData}
          defaultValue={renewalStatusData[0]}
          name="renewal"
          title={"Renewal Status"}
          placeholder={"Set renewal status"}
        />
      </div>
      <SelectBeneficiaryModal onClose={onClose} isOpen={isOpen} category="internet" />
    </div>
  );
};

export default InternetForm;
