import React, { useState, useContext, } from 'react'
import GiftCardDashboard from './Dashboard'
import DashboardContext from 'context/Dashboard/DashboardContext';
import GiftCardShop from 'components/GiftCard/Shop/Shop';
import BackButton from 'components/buttons/backbutton/BackButton';
import CartContainer from 'components/GiftCard/Cart/CartContainer';
import PinForm from '../Form/EnterPin';
import ServicesPayment from 'components/paymentOptions/ServicesPayment';



function GiftCardContainer() {
    const [value, setValue] = useState<{
        image_path: string
        name : string
         price: string
    }>(
        {
            image_path: '',
            name: '',
            price: ''
        }

    )




    const [step, setStep] = React.useState<'1' | "2" | "3" | "4" | "5">("1")
    const [steps, setSteps] = React.useState<string[]>(["1"])
    const { setCurrentPagename } = useContext(DashboardContext)


    const handleNext = () => {
        setSteps([...steps, step])


        if (step === "1") {
            setStep("2")

        }
        if (step === "2") {
            setStep("3")

        }
        if (step === "3") {
            setStep("4")
        }
        if (step === "4") {
            setStep("5")
        }

    }

    const handleBack = () => {
        setSteps(steps.slice(0, -1))


        if (step === "1") {
            setStep("1")

        }

        if (step === "2") {
            setStep("1")

        }
        if (step === "3") {
            setStep("2")
        }
        if (step === "4") {
            setStep("3")

        }
        if (step === "5") {
            setStep("4")

        }



    }


    if (step === "1") {
        setCurrentPagename("card")


    }
    let text = "All Services"
    if (step === "2") {
        text = "Shop"
        setCurrentPagename(value.name)
    }
    if (step === "3") {
        text = value.name
        setCurrentPagename("Checkout")


    }
    if (step === "4") {
        text = "Checkout"
        setCurrentPagename("Payment Options")
    }
    if (step === "5") {
        text = "Payment Options"
        setCurrentPagename("Transaction PIN")
    }

    const handleClick = (value: {
        image_path: string
        name : string
         price: string
    }) => {
        setValue(value)
        handleNext()
    }


    return (
        <form >
        {step === "1" ? <GiftCardDashboard handleClick={handleClick} /> : null}
        <div className='lg:pt-[0.075rem] lg:pl-[0.25rem] lg:pr-[0.225rem] lg:pb-[0.325rem] px-0.25rem py-0.5rem'>
            <div onClick={handleBack}>
                {step !== "1" ? <BackButton text={text} link='/services/?category=gift card' /> : null}
            </div>
            {step === "2" ? <GiftCardShop handleNext={() => handleNext()} data={value} handleClick={(value: {
                  image_path: string
                  name : string
                   price: string
            }) => setValue(value)} /> : null}
            {step === "3" ? <CartContainer onClick={handleNext} setStep={setStep} /> : null}
            {step === "4" ? <ServicesPayment onClick={handleNext} /> : null}
            {step === "5" ? <div className='mt-[0.25rem] 2xl:mt-[0.3125rem]'>
                <PinForm amount={'Pay N50,000'} />
            </div> : null}
        </div>
    </form>

    )
}

export default GiftCardContainer