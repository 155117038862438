import React from "react";
import ModalContainer from "components/Modals/ModalContainer";
import { Text, Flex, Box, Icon } from "@chakra-ui/react";
import { HiPhone } from "react-icons/hi"; // Example icon, you can use any icon you prefer

const LiveChat = ({
  onClose,
  size,
  isOpen,
}: {
  onClose: () => void;
  size: string;
  isOpen: boolean;
}) => {
  return (
    <ModalContainer size={size} isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" align="center" p={4}>
        <Icon as={HiPhone} w={10} h={10} color="var(--primary)" mb={4} />
        <Text fontSize= {{
            base: "xl",
            md: "2xl",
            lg: "3xl",
        }} mb={2} fontWeight="bold" color="var(--primary)">
          Live Chat
        </Text>
        <Text textAlign="center" color="gray.600">
          To start a live chat, please open our mobile app. If you need help,
          feel free to contact support through the app.
        </Text>
      </Flex>
    </ModalContainer>
  );
};

export default LiveChat;
