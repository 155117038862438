import CompleteRegistration from "container/register/CompleteRegistration";
import React from "react";




const CompleteRegistrationPage = () => {
    return (
        <CompleteRegistration />
    )

}

export default CompleteRegistrationPage;