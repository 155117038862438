import React from "react";
import {
  Image,
  Container,
  Text,
  Heading,
  Flex,
  Button,
  Grid,
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";
import CurrencyConverter from "utils/CurrencyConverter";

const WalletFundingSuccess = () => {
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount");
 

  return (
    <Grid
      placeItems={"center"}
      height={"100vh"}
      width={"100%"}
      placeContent={"center"}
      bg={"var(--gray-light-bg)"}
    >
      <Container
        centerContent
        p={{
          base: "4",
          md: "8",
          lg: "12",
          xl: "16",
        }}
        bg="white"
        borderRadius="lg"
        boxShadow="md"
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          gap={{
            base: "4",
            "2xl": "6",
          }}
        >
          <Image src="/success.gif" height={"180px"} width={"180px"} />
          <Grid>
            <Heading
              as="h1"
              fontSize={{
                base: "1.2rem",
                lg: "1.5rem",
                "2xl": "2rem",
              }}
              mb={2}
              color={"var(--primary)"}
            >
              Wallet Funding Successful
            </Heading>
            <Text
              fontSize={{
                base: ".875rem",
                lg: "1rem",
              }}
              mb={4}
              color={"var(--text-1)"}
            >
              Your wallet has been funded with{" "}
              <strong> {CurrencyConverter(amount || 0)}</strong> .
            </Text>
          </Grid>

          <Button
            as={Link}
            to={"/"}
            colorScheme="messenger"
            bg={"var(--primary)"}
            size="lg"
            mt={4}
          >
            Go to Dashboard
          </Button>
        </Flex>
      </Container>
    </Grid>
  );
};

export default WalletFundingSuccess;
