

import Dashboard from 'container/dashboard/dashboard'
import HomeContainer from 'container/home/HomeContainer'
import React from 'react'




export default function Home() {



  return (

      <Dashboard >
         <HomeContainer />
     </Dashboard>


  )
}
