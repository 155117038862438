import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface AccountDeletionProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  deletionStatus: "confirm" | "processing";
  isLoading: boolean;
}

const AccountDeletion: React.FC<AccountDeletionProps> = ({
  isOpen,
  onClose,
  onDelete,
  deletionStatus,
  isLoading,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/sign-in");
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="xl" bg="gray.50" p={5}>
        <ModalBody>
          <VStack spacing={4}>
            <Icon as={FiTrash2} w={20} h={20} color="red.400" />
            <Text fontSize="lg" fontWeight="bold">
              {deletionStatus === "confirm"
                ? "Confirm Delete"
                : "Account Deletion"}
            </Text>
            <Text color={"gray.700"} textAlign="center">
              {deletionStatus === "confirm"
                ? "Are you sure you would like to delete your account? This action is not reversible."
                : "Your account deletion has been sent and is currently being processed. This action takes up to 90 days."}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          {deletionStatus === "confirm" ? (
            <>
              <Button
                isLoading={isLoading}
                loadingText="Deleting..."
                colorScheme="red"
                onClick={onDelete}
                mr={3}
              >
                Delete
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </>
          ) : (
            <Button
              onClick={handleNavigate}
              colorScheme="red"
              variant={"outline"}
              size={{
                base: "sm",
                md: "md",
                lg: "lg",
              }}
            >
              Okay
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccountDeletion;
