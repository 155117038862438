import style from "./navlink.module.scss";
import { useLocation, Link } from "react-router-dom";
import React from "react";
import SignOut from "components/SignOut/SignOut";
import { useDisclosure  , Box} from "@chakra-ui/react";
import { useLogoutMutation } from "features/Auth/LogoutApiSlice";
import { useCookie } from "react-use";
import { useAppDispatch } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { api, authApi } from "app/ApiSlice";
import ModalContainer from "components/Modals/ModalContainer";


interface SidebarData {
  name: string;
  Icon: any;
  path: string;
}

const NavLink: React.FC<SidebarData> = ({ name, Icon, path }) => {
  const pathname = useLocation().pathname;
  const [, , DeleteCookie] = useCookie("jara_access");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logout, { isLoading }] = useLogoutMutation();
  const handleLogout = async () => {
    await logout({});
    DeleteCookie();
    // dispatch(api.util.resetApiState());
    // dispatch(authApi.util.resetApiState());
    navigate("/sign-in");
  };

  const handleOpen = () => {
    if (path === "/logout") {
      onOpen();
    }
  };

  //replace all last / with empty string
  const pathName =
    pathname.length > 1 ? pathname.replace(/\/+$/g, "") : pathname;

  const isActive = path === pathName;



  return (
    <Box  onClick={handleOpen}>
    <Link

      to={path === "/logout" ? "" : path}
      className={`${style.link} ${isActive ? style.active : ""}`}
    >
      <Icon />
      <p>{name}</p>
    {  (path === "/logout") ?  (
        <ModalContainer isOpen={isOpen} onClose={onClose} size={"lg"}>
          <SignOut
            signOut={handleLogout}
            handleClose={onClose}
            isLoading={isLoading}
          />
        </ModalContainer>
      ) : null}

    </Link>
    </Box>
  );
};

export default NavLink;
