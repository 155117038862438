import style from './navbarcontainer.module.scss'
import Sidebar from 'components/sidemenu/sidebar/SideBar';
import React from 'react';



const SideBarContainer = ({
    bgColor
}:{
    bgColor?:string
}) => {

    return(
        <div className={style.container}
            style={{
                backgroundColor:bgColor
            }}
        >
             <Sidebar />
        </div>
    )




}

export default SideBarContainer;


