import React from 'react';
import SigninContainer from 'container/signin/SigninContainer';




/**
 *
 * @returns  Jsx.Element
 *
 */
const SigninPage = () => {

    return (
        <div>
            <SigninContainer />
        </div>
    )

}

export default SigninPage;