

import style from './home.module.scss'
import CashInfoContainer from '../cashInfo/CashInfoContainer'
import TableHeader from 'components/transactiontable/header/Header'
import TableBody from 'components/transactiontable/body/Body'
import TwoDTableBody from 'components/transactiontable/body/2DBody'
import TitleLink from 'components/nameinfo/Link'
// import CreditCard from 'components/creditcard/CreditCard'
import { useContext, useEffect, useState } from 'react'
import DashboardContext from 'context/Dashboard/DashboardContext'
import Chart from 'components/chart/Chart'
import React from 'react'
import CardManagement from './CardManagement'
import { useAppSelector } from 'hooks/redux'


// const cardData = [
//     {
//         accountNumber: '0216791399',
//         bankName: 'Providus Bank',
//         name: "Adewolu Olayemi"
//     },
//     {
//         accountNumber: '0216791399',
//         bankName: 'GTB Bank',
//         name: "Adewolu Olayemi"
//     }
// ]



const HomeContainer = () => {

    const { setBgContentColor } = useContext(DashboardContext)
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgContentColor ? setBgContentColor("#fff") : null

    }, [setBgContentColor])
    const [recentTransactions, setRecentTransactions] = useState<any[]>([])
    const [walletTransfer, setWalletTranfer] = useState<any[]>([])

    const { user } = useAppSelector(state => state.auth as any)
    useEffect(() => {
        if (user?.transaction_detail?.recent_transactions) {
            // Create a copy of the array
            const arr = [...user.transaction_detail.recent_transactions];

            // Sort  copied array
            const sortedTransactions = arr.sort((a, b) => {
                const dateA = a?.transactions_created_at || a.date;
                const dateB = b.transactions_created_at || b.date;

                return new Date(dateB).getTime() - new Date(dateA).getTime();
            });

            setRecentTransactions(sortedTransactions || []);
        }
    }, [user?.transaction_detail?.recent_transactions]);


    useEffect(() => {
        if (user?.transaction_detail?.transactions_history) {
            let arr = [...user?.transaction_detail?.transactions_history]
            // Sort  copied array
            const sortedTransactions = arr.sort((a, b) => {
                const dateA = a.created_at
                const dateB = b.created_at;

                return new Date(dateB).getTime() - new Date(dateA).getTime();
            });

            setWalletTranfer(sortedTransactions)

        }

    }, [user?.transaction_detail?.transactions_history])





    return (
        <main className={style.home}>
            <section className={style.home__top}>
                <CashInfoContainer />
            </section>
            <section className={style.home__mobile__card}>
                <CardManagement />



            </section>
            <section className={style.home__bottom}>
                <section className={style.home__bottom__left}>
                    <section className={style.home__bottom__left__top}>
                        {/* // TODO: Change this later  // */}
                        <Chart />
                    </section>
                    <section className={`${style.home__bottom__left__bottom} py-[1.563rem] pl-[1.563rem] pr-[1.188rem] grid gap-[1.188rem] border border-[#F5F5F5]`}>
                        <TitleLink title='Recent Transactions' path='/transactions' textColor='#246BFD' />

                        <div className={'grid gap-[1.25rem]'} >



                            {recentTransactions?.length ? <TableHeader /> : null}
                            {recentTransactions?.length ? <TableBody data={recentTransactions} /> : null}

                        </div>





                    </section>


                </section>
                <section className={style.home__bottom__right}>
                    <section className={style.home__bottom__right__top}>

                        <CardManagement />
                    </section>
                    <section className={style.home__bottom__right__bottom}>
                         <TitleLink title='Wallet Transfers' path='/transfer' textColor='#246BFD' />
                        {walletTransfer?.length ? <section className=''>

                            <TwoDTableBody data={walletTransfer?.slice(0 , 10)} />

                        </section> : null}


                    </section>

                </section>



            </section>

        </main>
    )

}

export default HomeContainer


