import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div className="grid place-content-center place-items-center">
      {" "}
      <ReactLoading
        type={"spinningBubbles"}
        color={"#246BFD"}
        height={50}
        width={50}
      />
    </div>
  );
};

export default Loading;
