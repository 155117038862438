import React, { useContext, useEffect, useRef } from "react";
// import toast , {Toaster} from 'react-hot-toast'
//import JaraLink from 'components/link/JaraLink'
import DashboardContext from "context/Dashboard/DashboardContext";
import style from "./electricitytoken.module.scss";
import { useAppSelector } from "hooks/redux";

import copyToClipboard from "utils/copyToClipboard";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { motion } from "framer-motion";
import { Image } from "@chakra-ui/react";

type dataType = {
  "Number of Units": string;
  "Meter Number": string;
  "Meter Name": string;
  Address: string;
  Units: string;
  Debt: string;
  "Date of Purchase": string;
  "Customer Name": string;
  "Phone Number": string;
  "Cost of Electricity": string;
  "Renewal Status": string;
  "Sub Total": string;
  "Convenience Fee": string;
  Total: string;
};

const animation = {
  hidden: {
    x: -1000,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      damping: 10,
      stiffness: 100,
    },
  },
};

const ElectricityToken = () => {
  const { tokenDetails } = useAppSelector(
    (state: any) => state.electricityService as any
  );
  // const {electricityDetails} = useAppSelector((state:any) => state.electricityService as any)

  let data = localStorage.getItem("transactionDetails");
  let Ddata: dataType = JSON.parse(data as string);
  const token = localStorage.getItem("electricityToken");

  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPagename("Electricity  Token");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FAFAFA") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);
  const keys = Object.keys(Ddata) as Array<keyof dataType>;

  const sectionRef = useRef<HTMLElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => sectionRef.current,
  });

  const additionalStyles = {
    // Define your additional styles here
    container: {
      // Example: Apply a background color
      display: "grid",
      placeItems: "center",
      placeContent: "center",
    },
  };

  const ComponentToPrint = () => {
    return (
      <section
        style={additionalStyles.container}
        className={` ${style.token__info}`}
        id={"printable-section"}
        ref={sectionRef}
      >
        <Image src="/jarapay.svg" height={"32px"} />

        <div
          className={style.token__digits}
          onClick={() => copyToClipboard(token as string)}
        >
          <span>{token?.replace(/(\d{4})/g, "$1-")?.slice(0, -1)}</span>
          <svg
            className="cursor-copy"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6602 10.4395L20.6802 14.6195C19.8402 18.2295 18.1802 19.6895 15.0602 19.3895C14.5602 19.3495 14.0202 19.2595 13.4402 19.1195L11.7602 18.7195C7.59018 17.7295 6.30018 15.6695 7.28018 11.4895L8.26018 7.29952C8.46018 6.44952 8.70018 5.70952 9.00018 5.09952C10.1702 2.67952 12.1602 2.02952 15.5002 2.81952L17.1702 3.20952C21.3602 4.18952 22.6402 6.25952 21.6602 10.4395Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0603 19.3896C14.4403 19.8096 13.6603 20.1596 12.7103 20.4696L11.1303 20.9896C7.16034 22.2696 5.07034 21.1996 3.78034 17.2296L2.50034 13.2796C1.22034 9.30961 2.28034 7.20961 6.25034 5.92961L7.83034 5.40961C8.24034 5.27961 8.63034 5.16961 9.00034 5.09961C8.70034 5.70961 8.46034 6.44961 8.26034 7.29961L7.28034 11.4896C6.30034 15.6696 7.59034 17.7296 11.7603 18.7196L13.4403 19.1196C14.0203 19.2596 14.5603 19.3496 15.0603 19.3896Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <article className={style.list}>
          {keys.map((item) => {
            return (
              <div className={style.item} key={item}>
                <span
                  className={`${style.item__left} ${
                    item.toLowerCase() === "total" ? style.total : ""
                  }`}
                >
                  {item}
                </span>
                <span className={style.item__right}>{Ddata[item]}</span>
              </div>
            );
          })}
        </article>
      </section>
    );
  };

  return (
    <main className="p-5 md:px-[4.75rem] py-[2.5rem] grid place-content-center">
      <motion.h1
        className="shadow-gray/[0.05] shadow-[0px_4px_60px_0px]
         bg-green text-white text-center font-bold
         rounded-[1.25rem] text-[0.875rem] p-[0.875rem] lg:p-[1rem] 2xl:p-[1rem] lg:w-[36.438rem] mb-8"
        initial="hidden"
        animate="visible"
        variants={animation}
      >
        Congrats! Your Electricity Token is Ready
      </motion.h1>
      {/* print from here */}
      <section className={style.token__info}>
        <div className={style.token__image__container}>
          <Image src="/jarapay.svg" height={"32px"} />
          {/* <div>
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.7246 5.52526L10.8246 28.7253C9.22461 30.1753 10.1246 32.8503 12.2746 33.0503L32.4996 35.0003L20.3746 51.9003C19.8246 52.6753 19.8996 53.7503 20.5746 54.4253C21.3246 55.1753 22.4996 55.2003 23.2746 54.4753L49.1746 31.2753C50.7746 29.8253 49.8746 27.1503 47.7246 26.9503L27.4996 25.0003L39.6246 8.10026C40.1746 7.32526 40.0996 6.25026 39.4246 5.57526C39.0712 5.2143 38.59 5.00675 38.0849 4.9974C37.5797 4.98805 37.0912 5.17763 36.7246 5.52526Z" fill="#FFD300" />
                        </svg>
                    </div> */}
        </div>
        <div
          className={style.token__digits}
          onClick={() => copyToClipboard(token as string)}
        >
          <span>{token?.replace(/(\d{4})/g, "$1-")?.slice(0, -1)}</span>
          <svg
            className="cursor-copy"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6602 10.4395L20.6802 14.6195C19.8402 18.2295 18.1802 19.6895 15.0602 19.3895C14.5602 19.3495 14.0202 19.2595 13.4402 19.1195L11.7602 18.7195C7.59018 17.7295 6.30018 15.6695 7.28018 11.4895L8.26018 7.29952C8.46018 6.44952 8.70018 5.70952 9.00018 5.09952C10.1702 2.67952 12.1602 2.02952 15.5002 2.81952L17.1702 3.20952C21.3602 4.18952 22.6402 6.25952 21.6602 10.4395Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0603 19.3896C14.4403 19.8096 13.6603 20.1596 12.7103 20.4696L11.1303 20.9896C7.16034 22.2696 5.07034 21.1996 3.78034 17.2296L2.50034 13.2796C1.22034 9.30961 2.28034 7.20961 6.25034 5.92961L7.83034 5.40961C8.24034 5.27961 8.63034 5.16961 9.00034 5.09961C8.70034 5.70961 8.46034 6.44961 8.26034 7.29961L7.28034 11.4896C6.30034 15.6696 7.59034 17.7296 11.7603 18.7196L13.4403 19.1196C14.0203 19.2596 14.5603 19.3496 15.0603 19.3896Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <article className={style.list}>
          {keys.map((item) => {
            return (
              <div className={style.item} key={item}>
                <span
                  className={`${style.item__left} ${
                    item.toLowerCase() === "total" ? style.total : ""
                  }`}
                >
                  {item}
                </span>
                <span className={style.item__right}>{Ddata[item]}</span>
              </div>
            );
          })}
        </article>
      </section>
      {/* End print */}
      <section className="pt-[1.875rem] grid place-content-center gap-[0.75rem] grid-cols-2">
        <button className={style.btn__print} onClick={handlePrint}>
          Print Receipt
        </button>
        <button className={style.btn__dashboard} onClick={() => navigate("/")}>
          Dashboard
        </button>
      </section>

      {/* ReactToPrint component */}
      <div className="hidden print:grid w-max items-center place-content-center place-items-center">
        <ComponentToPrint />
      </div>
    </main>
  );
};

export default ElectricityToken;
