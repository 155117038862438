
import React from 'react'
import style from './avartar.module.scss'

const Avartar = ({image , size , radius}:{image? :string| any , size?:string , radius?: 'five'  }) => {


    return(
        <section aria-label='avartar' className={`${style.avartar} ${style[size ? size: '']} ${style[radius ? radius: '']}`}>
            <img src={image ? image : '/avartar-bg.png'} alt='avartar' width={100} height={100}  />
        </section>
    )



}

export default Avartar

