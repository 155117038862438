import OtherDashboard from "container/dashboard/OtherDashboard";
import WalletTransfer from "container/wallettransfer/WalletTransferContainer";
import React from "react";


const WalletTransferPage = () => {

    return (
        <OtherDashboard>
            <WalletTransfer />
        </OtherDashboard>
    )
}

export default WalletTransferPage
