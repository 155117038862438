import { createSlice  , PayloadAction} from "@reduxjs/toolkit";



const   initialState = {
    pin : [] , add_beneficiary: false , variations:  {}, formVariation : {} , isSuccess: false
}

    const servicesSlice = createSlice({
        name : "services",
        initialState,
        reducers : {
            setPin : (state, action) => {
            state.pin = action.payload
            },
            setAddBeneficiary : (state, action:PayloadAction<boolean>) => {
                state.add_beneficiary = action.payload

            },
            setVariations : (state, action:PayloadAction<any>) => {
                state.variations = action.payload


            },
            setFormVariation : (state, action:PayloadAction<any>) => {
                state.formVariation = action.payload

            },
            setSuccess : (state, action:PayloadAction<boolean>) => {
                state.isSuccess = action.payload


            }
        }
    })

    export const {setPin , setAddBeneficiary , setVariations , setFormVariation , setSuccess} = servicesSlice.actions
    export default servicesSlice.reducer