

interface IReturnType {
    device_name: string
    os : string

}


const Device = ()  =>{
    const userAgent = navigator.userAgent;

// Detect operating system
let os = "Unknown";
if (userAgent.match(/Windows/i)) {
  os = "Windows";
} else if (userAgent.match(/Macintosh|Mac OS/i)) {
  os = "Mac OS";
} else if (userAgent.match(/iPhone|iPad|iPod/i)) {
  os = "iOS";
} else if (userAgent.match(/Android/i)) {
  os = "Android";
} else if (userAgent.match(/Linux/i)) {
  os = "Linux";
}



// Detect device name
let device = "Unknown";
if (userAgent.match(/Mobile/i)) {
  device = "Mobile";
} else if (userAgent.match(/Tablet/i)) {
  device = "Tablet";
} else {
  device = "Desktop";
}

return {
    device_name : device ,
    os
}


}

export default Device