import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker3";
import style from "./generalservicestyle.module.scss";
import ElectricityForm from "./Form/ElectricityForm";
import PinForm from "./Form/EnterPin";
import ConfirmForm from "./Form/ConfirmForm";
import DashboardContext from "context/Dashboard/DashboardContext";
import { useForm } from "react-hook-form";
import ServicesPayment from "components/paymentOptions/ServicesPayment";
import { useGetElectricityProvidersQuery } from "features/Services/ElectricityService/ElectricityServiceApiSlice";
import { setElectricityProviders } from "features/Services/ElectricityService/ElectricityServiceSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  setElectricityDetails,
  setTokenDetails,
} from "features/Services/ElectricityService/ElectricityServiceSlice";
import {
  useVerifyElectricityServiceMutation,
  usePayElectricityServiceMutation,
} from "features/Services/ElectricityService/ElectricityServiceApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { toast } from "react-hot-toast";
import formatLocalCurrency from "utils/formatLocalCurrency";
//import { setDataDetails } from 'features/Services/DataService/DataSlice';
import { setAmountToFund } from "features/Wallet/WalletSlice";
import { setSuccess, setPin } from "features/Services/ServicesSlice";
import formatDateToDDMMYYYY from "utils/formatDDMMYYYY";
import useAddBeneficiary from "hooks/useAddBeneficiary";

const ElectricityService = () => {
  //dispatch
  const dispatch = useAppDispatch();

  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = React.useContext(DashboardContext);

  //FORM CONTROL
  const { control, setValue, handleSubmit } = useForm();
  const { data } = useGetElectricityProvidersQuery(undefined);

  const wallet = useAppSelector((state) => state.wallet as any);

  //add beneficiary
  const addBeneficiary = useAddBeneficiary();

  useEffect(() => {
    if (data) {
      dispatch(setElectricityProviders(data?.content));
    }
  }, [data, dispatch]);

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
  };

  if (step === "1") {
    setCurrentPagename("Buy Electricity");
  }
  let text = "All Services";
  if (step === "2") {
    text = "Electricity";
    setCurrentPagename("Confirm");
  }
  if (step === "3") {
    text = "Confirm";
    setCurrentPagename("Payment Options");
  }
  if (step === "4") {
    setCurrentPagename("Transaction PIN");
    text = "Payment options";
  }

  const { electricityProviders, electricityService, electricityDetails } =
    useAppSelector((state: any) => state.electricityService as any);

  // API MUTATIONS
  const [verifyElectricityService, { isLoading: payLoading }] =
    useVerifyElectricityServiceMutation();
  const [payElectricityService, { isLoading }] =
    usePayElectricityServiceMutation();
  const { user } = useAppSelector((state: any) => state.auth as any);
  const { pin, add_beneficiary } = useAppSelector(
    (state: any) => state.services
  );
  const onSubmit = async (data: any) => {
    if (step === "1") {
      dispatch(setSuccess(false));
      //find image with serviceID
      const image = electricityProviders?.find(
        (item: any) => item.serviceID === electricityService?.content?.serviceID
      )?.image;

      try {
        const res = await verifyElectricityService({
          serviceID: data?.provider?.serviceID,
          billersCode: data?.meter_number,
          type: data?.meter?.variation_code?.toLowerCase(),
        }).unwrap();

        dispatch(
          setElectricityDetails({
            type: data?.meter?.variation_code?.toLowerCase(),
            provider: data?.provider?.label,
            amount: data?.amount,
            meter_number: data?.meter_number,
            third_party_email: data.third_party_email,
            third_party_phone: data?.third_party_phone,
            email: data?.email,
            renewal: data?.renewal?.value?.toLowerCase(),
            ...data?.provider,
            ...data?.bundle,
            ...res,
            image,
          })
        );

        handleNext();
      } catch (error: any) {
        if (error?.data?.error) {

          toast.error(error?.data?.error);
        }
      }
    }
    if (step === "2") {
      handleNext();
    }

    if (step === "4") {
      const data: any = {
        pin: pin.join(""),
        phone: user?.data?.mobile_number,
        serviceID: electricityService?.content?.serviceID,
        variation_code: electricityDetails?.type?.toLowerCase(),
        billersCode: electricityDetails?.meter_number,
        variation_amount: Number(
          electricityDetails?.amount?.split("N")[1]?.split(",")?.join("")
        ),
        type: electricityDetails?.type?.toLowerCase(),
        slug_id: wallet.paymentSlug,
        renewal: electricityDetails?.renewal?.toLowerCase(),
        add_beneficiary: add_beneficiary,
        third_party:
          electricityDetails?.third_party_email ||
          electricityDetails?.third_party_phone
            ? true
            : false,
        third_party_email: electricityDetails?.third_party_email,
        third_party_phone: electricityDetails?.third_party_phone,
      };
      if (wallet.paymentSlug === 2 && wallet.signature) {
        data.signature = wallet.signature;
      }

      try {
        const res = await payElectricityService(data).unwrap();
        if (res) {
          dispatch(setTokenDetails(res));
          const data = {
            "Number of Units": res?.message?.units,
            "Meter Number": res?.message?.Meter_Number,
            "Meter Name": res?.message?.customerName,
            Address: res?.message?.customerAddress,
            Units: res?.message?.units,
            Debt: formatLocalCurrency(res?.message?.amount),
            "Date of Purchase": formatDateToDDMMYYYY(
              res?.message?.transaction_date?.date
            ),
            "Customer Name": res?.message?.customerName,
            "Phone Number": res?.message?.customerPhone,
            "Cost of Electricity": formatLocalCurrency(
              res?.message?.unit_price?.toString()
            ),
            "Renewal Status": electricityDetails?.renewal,
            "Sub Total": electricityDetails?.amount,
            "Convenience Fee": formatLocalCurrency(
              electricityDetails?.service_charge
            ),
            Total: formatLocalCurrency(res?.message?.total_amount.toString()),
          };
          localStorage.setItem("transactionDetails", JSON.stringify(data));
          localStorage.setItem(
            "electricityToken",
            res?.message?.purchased_code?.split(" ")[2]
          );
          dispatch(setPin([]));
          dispatch(setSuccess(true));
          await addBeneficiary({
            service: res?.message,
            beneficiary_number: res?.message?.Meter_Number,
            provider: "Electricity",
          });
        }
      } catch (error: any) {
        if (error) {
          if (error.status === "PARSING_ERROR") {
            toast.error("An error occured");
          } else if (error.status === 403) {
            toast.error(error?.data?.error);
          } else {
            toast.error("Transaction failed. Please try again");
          }
        }
      }
    }
  };

  return (
    <>
      {isLoading || payLoading ? <LoadingSpinner /> : null}
      <form onSubmit={handleSubmit(onSubmit)} className={style.data}>
        <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
          <BackButton
            text={text}
            link={step === "1" ? "/services" : "/services?category=electricity"}
          />
        </div>

        <section className={style.data__section}>
          {step === "1" ? (
            <Step1 steps={steps} setValue={setValue} control={control} />
          ) : null}

          {step === "2" ? <Step2 steps={steps} /> : null}

          {step === "3" ? (
            <Step3 steps={steps} handleNext={handleNext} />
          ) : null}

          {step === "4" ? <Step4 /> : null}

          <div className="grid place-content-center place-items-center">
            {step !== "4" && step !== "3" ? (
              <button type={"submit"} className="btn-primary">
                Continue
              </button>
            ) : null}
          </div>
        </section>
      </form>
    </>
  );
};

export default ElectricityService;

const Step1 = ({
  steps,
  setValue,
  control,
}: {
  steps: string[];
  setValue: any;
  control: any;
}) => {
  return (
    <div>
      <Heading
        title={"Pay for Electricity"}
        desc="Pay for Electricity bills safely, conveniently & easily."
        desc2="You can pay anytime and anywhere!"
      />
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Electricity Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>

      <ElectricityForm control={control} setValue={setValue} />
    </div>
  );
};

const Step2 = ({ steps }: { steps: string[] }) => {
  const [toggle, setToggle] = React.useState<boolean>(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const { electricityDetails } = useAppSelector(
    (state: any) => state.electricityService as any
  );
  const { user } = useAppSelector((state: any) => state.auth as any);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setAmountToFund(
        electricityDetails?.service_charge
          ? formatLocalCurrency(
              (
                parseFloat(
                  electricityDetails?.amount
                    ?.split("N")[1]
                    ?.split(",")
                    ?.join("")
                ) + parseFloat(electricityDetails?.service_charge)
              )?.toString()
            )
          : electricityDetails?.amount
      )
    );
  });

  const confirmData = [
    {
      title: "Customer Name",
      value: electricityDetails?.data?.content?.Customer_Name,
    },
    {
      title: "Debt",
      value: electricityDetails?.amount,
    },
    {
      title: "Provider",
      value: electricityDetails?.provider,
    },
    {
      title: "Meter Number",
      value: electricityDetails?.meter_number,
    },
    {
      title: "Phone Number",
      value: user?.data?.mobile_number,
    },
    {
      title: "Address",
      value: electricityDetails?.data?.content?.Address,
    },
    {
      title: "Renewal",
      value: electricityDetails?.renewal,
    },
    {
      title: "Sub Total",
      value: electricityDetails?.amount,
    },
    {
      title: "Convenience Fee",
      value: electricityDetails?.service_charge
        ? electricityDetails?.service_charge
        : "N/A",
    },
    {
      title: "Total",
      value: electricityDetails?.service_charge
        ? formatLocalCurrency(
            (
              parseFloat(
                electricityDetails?.amount?.split("N")[1]?.split(",")?.join("")
              ) + parseFloat(electricityDetails?.service_charge)
            )?.toString()
          )
        : electricityDetails?.amount,
    },
  ];

  return (
    <div>
      <Heading
        title={"Confirm Electricity Purchase"}
        desc="Please confirm your light purchase"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Electricity Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm
        handleSaveBeneficiary={handleToggle}
        saveBeneficiary={toggle}
        data={confirmData}
        src={electricityDetails?.image}
      />
    </div>
  );
};

const Step3 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Electricity Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment onClick={handleNext} />
    </div>
  );
};

const Step4 = () => {
  const { electricityDetails } = useAppSelector(
    (state: any) => state.electricityService as any
  );
  return (
    <div className="grid place-content-center place-items-center pt-[2.5rem]">
      <PinForm
        amount={`Pay ${
          electricityDetails?.service_charge
            ? formatLocalCurrency(
                (
                  parseFloat(
                    electricityDetails?.amount
                      ?.split("N")[1]
                      ?.split(",")
                      ?.join("")
                  ) + parseFloat(electricityDetails?.service_charge)
                )?.toString()
              )
            : electricityDetails?.amount
        } `}
        successMessage={{
          title: "Electricity Purchase Successful",
          message: (
            <>
              {`Your have successfully purchased ${
                electricityDetails?.provider?.split(" ")[0] +
                " " +
                electricityDetails?.provider?.split(" ")[1]
              } of`}{" "}
              <span>{electricityDetails?.amount}</span>
            </>
          ),
          buttonText: "See Token",
          link: "/electricity-token",
          elect: "elect",
        }}
      />
    </div>
  );
};
