import React from "react";
import {
  Image,
  Container,
  Text,
  Heading,
  Flex,
  Button,
  Grid,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const AddDebitCardSuccess = () => {
  return (
    <Grid
      placeItems={"center"}
      height={"100vh"}
      width={"100%"}
      placeContent={"center"}
      bg={"var(--gray-light-bg)"}
    >
      <Container
        centerContent
        p={{
          base: "4",
          md: "8",
          lg: "12",
          xl: "16",
        }}
        bg="white"
        borderRadius="lg"
        boxShadow="md"
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          gap={{
            base: "4",
            "2xl": "6",
          }}
        >
          <Image src="/success.gif" height={"180px"} width={"180px"} />
          <Grid>
            <Heading
              as="h1"
              fontSize={{
                base: "1.2rem",
                lg: "1.5rem",
                "2xl": "2.5rem",
              }}
              mb={2}
              color={"var(--primary)"}
            >
              Success!
            </Heading>
            <Text
              fontSize={{
                base: ".875rem",
                lg: "1rem",
                "2xl": "1.25rem",
              }}
              mb={4}
              color={"var(--text-1)"}
            >
              Debit card has been added successfully.
            </Text>
          </Grid>

          <Button
            as={Link}
            to={"/"}
            colorScheme="messenger"
            bg={"var(--primary)"}
            size="lg"
            mt={4}
          >
            Dashboard
          </Button>
        </Flex>
      </Container>
    </Grid>
  );
};

export default AddDebitCardSuccess;
