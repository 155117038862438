import RegisterContainer from "container/register/RegisterContainer";

import React from "react";


const SignUpPage = () => {
    return (
        //<RegisterContainer />
        <RegisterContainer />
    )

}

export default SignUpPage;