


import Input from "components/form/Auth/Input";
import { Link } from "react-router-dom";

import React , {useEffect} from "react";
import {useForm , SubmitHandler} from 'react-hook-form'
import  {useSignUpMutation} from 'features/Auth/AuthApiSlice'
import Spinner from  'components/Spinner/Spinner'
import {Toaster , toast} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'





interface IDefaultValues {
    first_name: string
    last_name: string
    email: string
    mobile_number: string
    login_pin : string
    device_id: string
    referral_id : string

}

const RegisterContainer = () => {
    const defaultValues:IDefaultValues  = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        login_pin : '',
        device_id: 'web',
        referral_id : ''

    }

    const [signup , {
        isLoading
    }] = useSignUpMutation()


    const {control , handleSubmit } = useForm({
        defaultValues
    })

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      };
      useEffect(() => {

        scrollToTop()
      }, [isLoading]);

    const navigate = useNavigate()

    const onSubmit: SubmitHandler<IDefaultValues> = async(data) => {
       const formData = new FormData()
       const keysArray = [
        'first_name',
        'last_name',
        'email',
        'mobile_number',
        'login_pin',
        'device_id',
        'referral_id'
      ];

     keysArray.forEach((item : string) => {
        formData.append(item, data[item as keyof IDefaultValues ]);
      });

      try{
        const res = await signup(formData).unwrap()

        if(res.status === "success"){
            localStorage.setItem('email' , res.data?.email)
            localStorage.setItem('id' , res.data?.id)
            navigate("/sign-up/complete-registration")

        }

      }
      catch(error:any){


        const errorMessageEmail = error.data?.error?.email
        const errorMessagePhone = error.data?.error?.mobile_number

        if(errorMessageEmail){
            toast.error(error.data?.error?.email)

        }

        else if(errorMessagePhone){
            toast.error(errorMessagePhone)
        }
      }




    }


    return (
           <>
           <Toaster />
            <form onSubmit={handleSubmit(onSubmit)} className="grid h-[max-content] 2xl:h-[max-content] lg:grid-cols-2 bg-white">
                <section className="2xl:pt-[5.625rem] xl:pl-[8.4375rem] p-[1.25rem] min-w-full w-full grid place-items-center lg:place-items-start">


                    <section className="max-w-[max-content]">

                        <section className="flex gap-[1.5625rem] flex-col items-start">
                            <div className="">
                                <h1 className="text-dark text-[1.875rem] font-semibold mb-2 xl:mb-[0.3125rem] text-center lg:text-left" >
                                    Create new account
                                </h1>
                                <h6 className="font-[400] text-[#78778B] text-[1rem] text-center lg:text-left ">
                                    Welcome back! Please enter your details
                                </h6>
                            </div>
                            <div className="flex flex-col gap-[.97rem] min-w-full">
                                <Input rules={{
                                required:"First name is required!",
                                minLength: {
                                    value: 2,
                                    message: "Name is too short"

                                },
                                maxLength: {
                                    value: 20,
                                    message: 'Maximum of 20 characters'
                                }


                                }} name="first_name" control={control} title="First Name" placeholder="First Name" />
                                <Input
                                 rules = {{
                                    required: "Last Name is required",
                                    minLength: {
                                        value: 2,
                                        message: "Name is too short"

                                    },
                                    maxLength: {
                                        value: 20,
                                        message: 'Maximum of 20 characters'
                                    }

                                 }}
                                 name="last_name" control={control}  title='Last Name' placeholder="Last Name" />
                                <Input rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Please Enter a Valid Email.'

                                    }
                                }} name="email" control={control} title="Email" type="email" placeholder="Email" />
                                <Input name="mobile_number"
                                    rules ={{
                                        required: "Phone Number is Required",
                                        pattern : {value:/^\+{0,1}[0-9]{11,14}$/ , message: "Invalid Phone Number"}

                                    }}
                                    maxLength ={14}
                                 control={control} title="Phone" type="tel" placeholder="Phone" />
                                <Input  name="referral_id" control={control} title="Referal ID(optional)" placeholder="Referal ID" />
                                <Input rules={{
                                    required: "Login PIN is required",

                                    pattern: {

                                        value : /^[0-9]{6,}$/,
                                        message: "Ensure Your Login Pin Is Longer Than 5 Digits"
                                    }

                                }} maxLength = {6} name="login_pin" control={control} title="Login PIN" type="password" placeholder="Login PIN" />
                            </div>

                                <div className='grid place-items-center'>
                                    <button className="  btn-primary  transition-all">
                                        Sign Up
                                    </button>
                                </div>

                            <div className="flex items-center justify-center gap-[0.125rem] min-w-full ">
                                <p className="text-[#929EAE] text-[.875rem] font-[500] text-center">
                                    {"Already have an account?"}
                                </p>
                                <h6 className="text-dark text-[.875rem] font-[500] text-center relative">
                                    <Link to={"/sign-in"} className="hover:font-bold" >
                                        Sign in
                                    </Link>
                                    <span className="absolute top-[1.2rem] left-[.2rem]">
                                        <img src="/Auth/rule.png" alt="rule" width={96} height={10} className="w-[6rem] h-[.675rem]" />
                                    </span>
                                </h6>

                            </div>


                        </section>




                    </section>

                </section>
                <section className="hidden lg:block w-full h-[100%] 2xl:max-h-[100vh] xl:min-h-[100%]">
                    <img src="/signIn.png" alt="jarapay login" width={675} height={900} className="w-full h-full max-h-[100%] max-w-full" />



                </section>


            </form>
            {
                isLoading ? <Spinner /> : null
            }
            </>



    )


}

export default RegisterContainer;