import React, { useContext, useEffect } from 'react';
import Heading from './Heading/Heading';
import BackButton from 'components/buttons/backbutton/BackButton';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker2';
import style from './generalservicestyle.module.scss'
import CableForm from '../services/Form/CableTVForm'
import PinForm from './Forms/EnterPin';
import ConfirmForm from './Forms/ConfirmForm';

import ServicesPayment from 'components/paymentOptions/ServicesPayment';
import DashboardContext from 'context/Dashboard/DashboardContext';
import { useGetCableProviderQuery } from 'features/Services/CableService/CableApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCableProviders } from 'features/Services/CableService/CableSlice';
import { useForm } from 'react-hook-form'
import { useVerifyCableMutation } from 'features/Services/CableService/CableApiSlice';
import LoadingSpinner from 'components/Spinner/Spinner'
import { setCableDetails } from 'features/Services/CableService/CableSlice';
import formatLocalCurrency from 'utils/formatLocalCurrency';
import { setAmountToFund } from 'features/Wallet/WalletSlice';
import {toast} from 'react-hot-toast'
import { setSuccess } from 'features/Services/ServicesSlice';
import { setPin } from 'features/Services/ServicesSlice';
import { useCreateSubscriptionMutation } from 'features/Subscription/SubscriptionApiSlice';


















const DataService = () => {
    const [step, setStep] = React.useState<'1' | "2" | "3" | "4">("1")
    const [steps, setSteps] = React.useState<string[]>(["1"])
    const { setCurrentPagename } = useContext(DashboardContext)
    const dispatch = useDispatch()
    const { data: cableProvider } = useGetCableProviderQuery(undefined)
    const { cableProviders  , cableDetails} = useSelector((state: any) => state.cableService as any)
    const { formVariation, pin } = useSelector((state: any) => state.services as any)
    //Verify TV
    const [verifyCableTV, { isLoading: isLoadingVerification }] = useVerifyCableMutation()

    //Buy Cable TV
    const [subscribe, { isLoading: isLoadingBuy }] = useCreateSubscriptionMutation()

    const wallet = useSelector((state: any) => state.wallet as any)




    useEffect(() => {
        if (cableProvider) {
            dispatch(setCableProviders(cableProvider?.content))
        }

    })



    const { control, handleSubmit, setValue } = useForm();


    const handleNext = () => {
        setSteps([...steps, step])


        if (step === "1") {
            setStep("2")

        }
        if (step === "2") {
            setStep("3")

        }
        if (step === "3") {
            setStep("4")
        }

    }

    const handleBack = () => {
        setSteps(steps.slice(0, -1))


        if (step === "1") {
            setStep("1")

        }

        if (step === "2") {
            setStep("1")

        }
        if (step === "3") {
            setStep("2")
        }
        if (step === "4") {
            setStep("3")

        }



    }

    let text = "All Subscriptions"
    if (step === "1") {
        setCurrentPagename("Cable TV")
    }
    if (step === "2") {
        text = "Cable TV"
        setCurrentPagename("Confirm")
    }
    if (step === "3") {
        text = "Confirm"
        setCurrentPagename("Payment Options")


    }
    if (step === "4") {
        text = "Payment options"
        setCurrentPagename("Transaction PIN")




    }
    const onSubmit = async (data: any) => {
        if (step === "1") {
            dispatch(setSuccess(false));

            //Search Id
            const serviceID = cableProviders.find(
              (item: any) => item.name === data.serviceID?.name
            )?.serviceID;
            const image = cableProviders.find(
              (item: any) => item.name === data.serviceID?.name
            )?.image;
            try {
              const res = await verifyCableTV({
                serviceID,
                billersCode: data.billersCode,
              }).unwrap();
              if (res) {
                dispatch(
                  setCableDetails({
                    ...res,
                    ...data,
                    ...formVariation,
                    serviceID,
                    image,
                  })
                );
                handleNext();
              }
            } catch (error: any) {
              toast.error("An error occured during verification");
            }
          }
        if (step === "2") {
            handleNext()
        }
        if (step === "4") {

            const data: any = {
                category_sub : 'DSTV',
                serviceID: cableDetails?.serviceID,
                variation_code: cableDetails?.variation_code,
                billersCode: cableDetails?.billersCode,
                variation_amount: parseFloat(cableDetails?.variation_amount),
                pin: pin?.join(""),
                slug_id: wallet.paymentSlug,
                renewal: cableDetails?.renewal?.label?.toLowerCase(),
              };
              if (wallet.paymentSlug === 2 && wallet.signature) {
                data.signature = wallet.signature;
              }

            try {
                const res = await subscribe(data).unwrap()
                if (res) {
                    dispatch(setPin([]))
                    dispatch(setSuccess(true))


                }
            } catch (error: any)
            {
               toast.error('Subscription failed')
            }
        }


    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={style.data} >
            {
                isLoadingVerification || isLoadingBuy ? <LoadingSpinner /> : null
            }
            <div className='pl-[4rem]' onClick={handleBack}>
                <BackButton text={
                    text

                } link={
                    step === "1" ? "/subscriptions" : "/subscriptions?category=cable tv"
                } />
            </div>

            <section className={style.data__section}>

                {step === "1" ? <Step1 steps={steps} control={control} setValue={setValue} /> : null}

                {step === "2" ? <Step2 steps={steps} /> : null}

                {step === "3" ? <Step3 steps={steps} handleNext={handleNext} /> : null}



                {
                    step === "4" ? <Step4 /> : null
                }

                <div className='grid place-content-center place-items-center'>
                    {(step !== "4" && step !== "3") ? <button type={'submit'} className='btn-primary'>Continue</button> : null}

                </div>

            </section>

        </form>
    )

}

export default DataService




const Step1 = ({
    steps, control, setValue
}: {
    steps: string[], control: any, setValue: any
}) => {
    return (
        <div>
            <Heading title={"Create Cable TV Subscriptions"} desc="Put your data bills on reoccurring, " desc2="It's safe and we always deliver.." />
            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Cable TV Options", "Confirm Details", "Select Payment", "Enter Pin & Confirm"]} steps={steps} />
            </div>

            <CableForm control={control} setValue={setValue} />
        </div>
    )
}




const Step2 = ({
    steps
}: {
    steps: string[]
}) => {
    const { cableDetails } = useSelector((state: any) => state.cableService as any)
    const { user } = useSelector((state: any) => state.auth as any)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setAmountToFund(cableDetails?.service_charge ? formatLocalCurrency((parseFloat(cableDetails?.variation_amount) + parseFloat(cableDetails?.service_charge))?.toString())
            :
            cableDetails?.variation_amount))
    })
    const confirmData = [
        {
            "title": "Account Name",
            "value": cableDetails?.data?.content?.Customer_Name
        },
        {
            "title": "Current Bouquet(s)",
            "value": cableDetails?.data?.content?.Current_Bouquet
        },
        {
            "title": "Purchase Bouquet(s)",
            "value": cableDetails?.data?.content?.Current_Bouquet
        },
        {
            "title": "Due Date",
            "value": cableDetails?.data?.content?.Due_Date || "N/A"
        },
        {
            "title": "Phone Number",
            "value": user?.data?.mobile_number
        },
        {
            "title": "Renewal Status",
            "value": cableDetails?.renewal?.label
        },
        {
            "title": "Sub Total",
            "value": formatLocalCurrency(cableDetails?.variation_amount)
        },

        {
            "title": "Convenience Fee",
            "value": formatLocalCurrency(cableDetails?.service_charge)
        }
        ,
        {
            "title": "Total",
            "value": formatLocalCurrency((parseFloat(cableDetails?.variation_amount)
                + parseFloat(cableDetails?.service_charge))?.toString())
        },


    ]



    const [toggle, setToggle] = React.useState<boolean>(true)

    const handleToggle = () => {
        setToggle(!toggle)

    }

    return (
        <div>
            <Heading title={"Confirm Cable TV Purchase"} desc="Please confirm your Cable TV subscription" />

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Cable TV Options", "Confirm Details", "Select Payment", "Enter Pin & Confirm"]} steps={steps} />
            </div>
            <ConfirmForm handleSaveBeneficiary={handleToggle} saveBeneficiary={
                toggle
            } data={confirmData} src={cableDetails?.image} />
        </div>
    )

}


const Step3 = ({
    steps, handleNext
}: {
    steps: string[], handleNext: () => void
}) => {
    return (

        <div>

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`} >
                <ProgressTracker levels={["Cable TV Options", "Confirm Details", "Select Payment", "Enter Pin & Confirm"]} steps={steps} />
            </div>
            <ServicesPayment title='Confirm Subscription'  onClick={handleNext} />

        </div>
    )
}


const Step4 = () => {
    const { cableDetails } = useSelector((state: any) => state.cableService as any)
    return (
        <div className='grid place-content-center place-items-center pt-[4rem]' >
            <PinForm amount= {`Create Subscription`}
                successMessage={{
                    title: `Cable TV Subscription Created`,
                    message: <>{`Your have successfully created ${cableDetails?.name?.split(" ")[0]}  ${cableDetails?.name?.split(" ")[1]} subscription of `} <span>{formatLocalCurrency(cableDetails?.variation_amount)}</span></>,
                    buttonText: 'Dashboard',

                }}
            />
        </div >
    )
}