import React from "react";
import Item from "./item";
import style from "./transaction.module.scss";
import { useAppSelector } from "hooks/redux";
import { useState } from "react";
import Pagination from "components/Pagination/Pagination";
import { Grid } from "@chakra-ui/react";



interface Props {
  data: any[];
  handleClick: () => void;
}
const TransactionList: React.FC<Props> = ({ data, handleClick }) => {

  const [itemOffset, setItemOffset] = useState(0);



  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  const currentItems = data?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event : any) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;

    setItemOffset(newOffset);
  };












  return (
    <>
    <main className={style.list__container} >
      {currentItems?.map((item, index) => {
        return (
          <section className={style.list__item} key={index}>
            <Item item={item} handleClick={handleClick} />
          </section>
        );
      })}

    </main>
    <Grid  mt={'4rem'}>
        <Pagination pageCount={pageCount} onPageChange={handlePageClick} />
      </Grid>
    </>
  );
};

export default TransactionList;
