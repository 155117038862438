import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServicesInput from 'components/form/Services/Input';

import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useWatch } from "react-hook-form";

import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useSelectInsuranceProviderQuery } from "features/Services/Insurance/InsuranceApiSlice";
import SelectDropdown from "components/form/Services/SelectDropdown";
import FileInput from "components/form/Services/FileInput";
import DateInput from "components/form/Services/DateInput";




const HealthInsuranceForm = ({
  control,
  setValue,
  provider,
}: {
  control: any;
  setValue: any;
  provider: any;
}) => {


    const { data: insuranceType, isLoading } = useSelectInsuranceProviderQuery({...provider} , {
        skip: !provider
    });
    const [insurancePackageOptions , setInsurancePackageOptions] = useState<any[]>([])
    const [extraFields , setExtraFields] = useState<any[]>([])
    const [hospitals , setHospitals]  = useState<any>({})
    const [cityOptions , setCityOptions] = useState<any[]>([])
    const [hospitalOptions , setHospitalOptions] = useState<any[]>([])

    const selectedState = useWatch({ control, name: "state" });
    const selectedCity = useWatch({ control, name: "city" });



    const stateOptions = Object.keys(hospitals).map(state => ({
      label: state?.charAt(0).toUpperCase() + state?.slice(1),
      value: state
    }));





      useEffect(()=> {
        const _hospitalOptions = selectedCity
        ? Object.entries(hospitals[selectedState?.value][selectedCity?.value]).map(
            ([key, value] : any) => ({
              label:  value?.charAt(0).toUpperCase() + value?.slice(1),
              value: key
            })
          )
        : [];

        const _cityOptions = selectedState
        ? Object.keys(hospitals[selectedState?.value]).map(city => ({
            label: city?.charAt(0).toUpperCase() + city?.slice(1),
            value: city
          }))
        : [];
        setCityOptions(_cityOptions)
        setHospitalOptions(_hospitalOptions)


      }, [hospitals, selectedCity, selectedState])


    useEffect(()=> {
        if(insuranceType){
          setHospitals(insuranceType?.options_insurance?.content?.options)

          setExtraFields(insuranceType?.extra_fields_insurance?.content)
            setInsurancePackageOptions(insuranceType?.insurance?.content?.varations?.map((item:any)=> {
                return {
                     ...provider,
                    ...item,
                    extra_fields: extraFields,
                    label: item?.name,
                    value: item?.variation_code,
                    image: item?.image
                }
            }))

        }

    } , [ insuranceType, provider])









  return (
    <form className="flex items-center justify-center relative w-full">

      <div className={style.form}>
        <SelectDropdown name="state" control={control} rules={{ required: "Field cannot be empty." }} options={stateOptions} title={'State'} placeholder={'select option'} />
        <SelectDropdown name="city" control={control} rules={{ required: "Field cannot be empty." }} options={cityOptions} title={'City'} placeholder={'select option'} />
        <SelectDropdown name="selected_hospital" control={control} rules={{ required: "Field cannot be empty." }} options={hospitalOptions} title={'Hospital'} placeholder={'select option'} />
        <DropdownWithImage rules={{ required: "Field cannot be empty." }} control={control} options={insurancePackageOptions} name='variation' defaultValue= {insurancePackageOptions[0]} title={'Insurance Package'} placeholder={'select option'} />
        <ServicesInput name="phone" control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Phone Number'} placeholder={'phone number'} onChange={() => { }} value={''} type={'number'} maxLength={13} />
        <ServicesInput name='full_name' control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Full Name'} placeholder={'full name'} onChange={() => { }} value={''} type={'text'} maxLength={50} />

        <ServicesInput name='address' control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Address'} placeholder={'address'} onChange={() => { }} value={''} type={'text'} maxLength={50} />

        <DateInput showYearPicker={false} dateFormat="dd-MM-yyyy" name="date_of_birth" control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Date of Birth'} placeholder={'date of birth'}   />
        <FileInput name="Passport_Photo" control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Passport Photo'} placeholder={'passport photo'}   />
        <ServicesInput name='extra_info' control={control} rules={{ required: "Field cannot be empty." }} defaultValue='' label={'Extra Info'} placeholder={'extra info'} onChange={() => { }} value={''} type={'text'} maxLength={50} />


      </div>
    </form>
  );
};

export default HealthInsuranceForm;
