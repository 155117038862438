import React, { useContext, useState, useEffect } from "react";
import Heading from "./Heading/Heading";
import BackButton from "components/buttons/backbutton/BackButton";
import ProgressTracker from "components/ProgressTracker/ProgressTracker2";
import style from "./generalservicestyle.module.scss";
import AirtimeForm from "./Form/AirtimeForm";
import PinForm from "./Form/EnterPin";
import ConfirmForm from "./Form/ConfirmForm";

import ServicesPayment from "components/paymentOptions/ServicesPayment";
import DashboardContext from "context/Dashboard/DashboardContext";
import {
  useAirtimeProviderQuery,
  useBuyAirtimeMutation,
} from "features/Services/AirtimeService/AirtimeServiceApiSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  setDataProviders,
  setDataDetails,
} from "features/Services/DataService/DataSlice";
import { useForm } from "react-hook-form";
// import extractAmountAndData from 'utils/extractAmountAndData';
// import formatLocalCurrency from 'utils/formatLocalCurrency';
import { toast } from "react-hot-toast";
import { setSuccess, setPin } from "features/Services/ServicesSlice";
import useAddBeneficiary from "hooks/useAddBeneficiary";
import { setAmountToFund } from "features/Wallet/WalletSlice";
import formatLocalCurrency from "utils/formatLocalCurrency";

const DataService = () => {
  //use dispatch
  const dispatch = useAppDispatch();

  //GET ALL AIRTIME PROVIDERS
  const { data: providers } = useAirtimeProviderQuery(undefined);
  const dataServices = useAppSelector((state) => state.dataService as any);
  //SET PROVIDER
  useEffect(() => {
    if (providers) {
      dispatch(setDataProviders(providers?.content));
    }
  }, [providers, dispatch]);

  const [step, setStep] = React.useState<"1" | "2" | "3" | "4">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = useContext(DashboardContext);

  //add beneficiary
  const addBeneficiary = useAddBeneficiary();

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
  };

  let text = "All Services";
  if (step === "1") {
    setCurrentPagename("Buy Airtime");
  }
  if (step === "2") {
    text = "Airtime";
    setCurrentPagename("Confirm");
  }
  if (step === "3") {
    text = "Confirm";
    setCurrentPagename("Payment Options");
  }
  if (step === "4") {
    text = "Payment options";
    setCurrentPagename("Transaction PIN");
  }

  const { control, setValue, handleSubmit } = useForm();
  const pin = useAppSelector((state) => state.services.pin);
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );
  //BUY Airtime MUTATION
  const [buyAirtime, { isLoading }] = useBuyAirtimeMutation();
  const { add_beneficiary } = useAppSelector((state) => state.services as any);
  const wallet = useAppSelector((state) => state.wallet as any);

  const onSubmit = async (data: any) => {
    if (step === "1") {
      dispatch(setSuccess(false));
      //   const details = dataServices?.dataProviders?.find(
      //     (provider: any) => provider.serviceID === data?.serviceID?.value
      //   );

      dispatch(
        setDataDetails({
          variation_amount: data.variation_amount,
          billersCode: data.billersCode,
          renewal: data?.renewal?.value,
          ...data?.serviceID,
          serviceID: data?.serviceID?.value,
        })
      );
      handleNext();
    }
    if (step === "2") {
      handleNext();
    }

    if (step === "4") {
      // {

      //     "serviceID":"mtn-data",
      //     "variation_code":"mtn-100mb-1000",
      //     "billersCode":"08011111111",
      //     "pin":"1111",
      //     "slug_id":1,
      //     "renewal": "off",
      //     "add_beneficiary":true,
      //     "variation_amount":100

      // }

      const data : any = {
        serviceID: confirmDetails.serviceID,
        variation_code: confirmDetails.variation_code,
        phone: confirmDetails.billersCode,
        pin: pin.join(""),
        slug_id: wallet.paymentSlug,
        renewal: confirmDetails.renewal?.toLowerCase(),
        add_beneficiary,
        variation_amount: confirmDetails?.variation_amount
          ?.split("N")[1]
          ?.replaceAll(",", ""),
        // "variant": {
        //     "bonus": "100"
        // }
      };
      if (wallet.paymentSlug === 2 && wallet.signature) {
        data.signature = wallet.signature;
      }

      try {
        const res = await buyAirtime(data).unwrap();
        if (res) {
          await addBeneficiary({
            service: res?.message,
            beneficiary_number: confirmDetails.billersCode,
            provider: "Airtime",
          });
          dispatch(setPin([]));
          dispatch(setSuccess(true));
        }
      } catch (error: any) {
        if (error) {
          if (error.status === "PARSING_ERROR") {
            toast.error("An error occured");
          } else if (error.status === 403) {
            toast.error(error?.data?.error);
          } else {
            toast.error("Transaction failed. Please try again");
          }
        }
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.data}>
      {isLoading ? <LoadingSpinner /> : null}
      <div className="lg:pl-[2.5rem] pl-[1rem]" onClick={handleBack}>
        <BackButton
          text={text}
          link={step === "1" ? "/services" : "/services?category=airtime"}
        />
      </div>

      <section className={style.data__section}>
        {step === "1" ? (
          <Step1 control={control} setValue={setValue} steps={steps} />
        ) : null}

        {step === "2" ? <Step2 steps={steps} /> : null}

        {step === "3" ? <Step3 steps={steps} handleNext={handleNext} /> : null}

        {step === "4" ? <Step4 /> : null}

        <div className="grid place-content-center place-items-center">
          {step !== "4" && step !== "3" ? (
            <button type="submit" className="btn-primary">
              Continue
            </button>
          ) : null}
        </div>
      </section>
    </form>
  );
};

export default DataService;

const Step1 = ({
  steps,
  control,
  setValue,
}: {
  steps: string[];
  control: any;
  setValue: any;
}) => {
  return (
    <div>
      <Heading
        title={"Pay for Airtime"}
        desc="Pay for airtime bills safely, conveniently & easily."
        desc2="You can pay anytime and anywhere!"
      />
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Airtime Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>

      <AirtimeForm setValue={setValue} control={control} />
    </div>
  );
};

const Step2 = ({ steps }: { steps: string[] }) => {
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setAmountToFund(
        formatLocalCurrency(confirmDetails?.variation_amount as string)
      )
    );
  }, [confirmDetails, dispatch]);

  const confirmData = [
    {
      title: "Airtime Payment",
      value: confirmDetails?.variation_amount,
    },

    {
      title: "Phone Number",
      value: confirmDetails?.billersCode,
    },
    {
      title: "Renewal Status",
      value: confirmDetails?.renewal,
    },
    {
      title: "Service Fee",
      value: "NO",
    },
    {
      title: "Total",
      value: confirmDetails?.variation_amount,
    },
  ];

  return (
    <div>
      <Heading
        title={"Confirm Airtime Purchase"}
        desc="Please confirm your airtime purchase"
      />

      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Airtime Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ConfirmForm
        data={confirmData}
        saveBeneficiary={true}
        handleSaveBeneficiary={() => {}}
        src={
          confirmDetails?.image
            ? confirmDetails.image
            : "/services/dataImage.svg"
        }
      />
    </div>
  );
};

const Step3 = ({
  steps,
  handleNext,
}: {
  steps: string[];
  handleNext: () => void;
}) => {
  return (
    <div>
      <div
        className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}
      >
        <ProgressTracker
          levels={[
            "Airtime Options",
            "Confirm Details",
            "Select Payment",
            "Enter Pin & Pay",
          ]}
          steps={steps}
        />
      </div>
      <ServicesPayment onClick={handleNext} />
    </div>
  );
};

const Step4 = () => {
  const confirmDetails = useAppSelector(
    (state) => state.dataService?.dataDetails as any
  );

  return (
    <>
      <div className="grid place-content-center place-items-center pt-[2.5rem]">
        <PinForm
          amount={`Pay ${confirmDetails?.variation_amount}`}
          successMessage={{
            title: "Airtime Purchase Successful",
            message: (
              <>
                {`Your have successfully purchased  ${
                  confirmDetails?.name?.split(" ")[0] +
                  " " +
                  confirmDetails?.name?.split(" ")[1]
                } of `}
                <span>{confirmDetails?.variation_amount}</span>
              </>
            ),
            buttonText: "Dashboard",
            link: "/services",
          }}
        />
      </div>
    </>
  );
};
