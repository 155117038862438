import React, { useEffect } from 'react';
import style from './fundviatable.module.scss'
import { Checked, Unchecked, } from './Icons'
import { useAppDispatch } from 'hooks/redux'
import { setPayment, setSignature } from 'features/Wallet/WalletSlice';
import { setPaymentSlug } from 'features/Wallet/WalletSlice';
import { Box } from '@chakra-ui/react';


interface Props {
    heading?: string
    data: {
        provider: string,
        description: string,
        provider_image: string,
        signature ? :string


    }[]
}

const FundViaTable: React.FC<Props> = ({
    data, heading = "Fund via other option"
}) => {
    const dispatch = useAppDispatch()


    const [value, setValue] = React.useState<string>(data[0]?.provider)

    useEffect(() => {
        setValue(data[0]?.provider)
    }, [data])

    useEffect(() => {
        dispatch(setPayment({
            paymentUrl: '/v1/payment/sqaudco',
            paymentType: 'Squadco'
        }))

    }, [dispatch])



    const handleSelect = (input: any) => {
        if (input?.provider === 'Pay with wallet balance') {
            dispatch(setPaymentSlug(1))
        }
        if (input?.provider !== 'Pay with wallet balance') {
            dispatch(setPaymentSlug(2))
            dispatch(setSignature(input.signature))

        }

        setValue(input?.provider)
        if (input?.provider === "Squadco") {
            dispatch(setPayment({
                paymentUrl: '/v1/payment/sqaudco',
                paymentType: 'Squadco'
            }))
        }
        if (input?.provider === "PayStack") {
            dispatch(setPayment({
                paymentUrl: '/v1/payment/paystack',
                paymentType: 'PayStack'
            }))
        }
        if (input?.provider === "Flutterwave") {
            dispatch(setPayment({
                paymentUrl: '/v1/payment/flutterwave',
                paymentType: 'Flutterwave'
            }))

        }
        if (input?.provider === "Bitcoin") {
            dispatch(setPayment({
                paymentUrl: '/v1/payment/bitcoin',
                paymentType: 'Bitcoin'
            }))
        }
    }



    return (
        <section className={style.fundviaTable}>

            <h1>
                {heading}
            </h1>



            <section className={style.fundviaTable__container}>

                {
                    data?.map((item, index: number) => {
                     

                        return (
                            <Box cursor={'pointer'} key={index} className={style.fundviaTable__item} onClick={() => handleSelect(item)}>

                                {
                                    value === item?.provider ?
                                        <span >
                                            <Checked />
                                        </span> :
                                        <span >
                                            <Unchecked />
                                        </span>

                                }
                                <img src={item.provider_image} alt="" className='md:w-[59px] w-[40px] h-[auto]' />
                                <section className='grid '>
                                    <h5 className={style.fundviaTable__item__title} >
                                        {item.provider}
                                    </h5>
                                    <p className={style.fundviaTable__item__desc}>
                                        {
                                            item.description
                                        }
                                    </p>
                                </section>


                            </Box>
                        )
                    })
                }
            </section>

        </section>

    )


}

export default FundViaTable;