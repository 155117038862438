import React from 'react';
import style from './accountnumbertable.module.scss'
import copyToClipboard from 'utils/copyToClipboard'

interface Props {
    account: {
        account_number: string,
        bank: string,
        name: string
    }[]
}

const AccountNumberTable: React.FC<Props> = ({
    account
}) => {

    return (
        <section className={style.accountNumberTableContainer}>
            <section className="pt-[1.5rem] pb-[1.5rem] px-[2rem] text-center border-b border-b-[#D1D1D8] flex flex-col gap-[0.1rem] items-center justify-center">
                <h1 className={style.title}>
                    Fund wallet via Virtual Account
                </h1>
                <p className={style.sub__title}>
                    Use any of virtual account below to make transfer from your bank.
                </p>
            </section>
            <section className="pt-[1rem] pb-[2rem] border-b grid md:place-content-center  gap-[1.9rem]">

                {
                    account?.map((item, index: number) => {
                        return (
                            <div key={index} className={style.accountNumberTableItem}>
                                <h5>
                                    {item.name}
                                </h5>
                                <div onClick={() => copyToClipboard(item.account_number)}  className='flex items-center gap-4 cursor-pointer'>
                                    <span  className={style.account__number}> {item.account_number} </span>
                                    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8472 10.0047L17.9944 14.0106C17.2635 17.4702 15.8191 18.8693 13.1043 18.5818C12.6693 18.5435 12.1994 18.4572 11.6947 18.3231L10.2329 17.9397C6.60445 16.991 5.48198 15.0168 6.33471 11.011L7.18743 6.99558C7.36146 6.18099 7.57029 5.47183 7.83133 4.88724C8.84938 2.56808 10.5809 1.94516 13.4872 2.70224L14.9403 3.07599C18.5861 4.01516 19.6999 5.99891 18.8472 10.0047Z" fill="#246BFD" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.1041 18.5818C12.5646 18.9843 11.8859 19.3197 11.0593 19.6168L9.68451 20.1151C6.23009 21.3418 4.41152 20.3164 3.28905 16.5118L2.17529 12.7264C1.06152 8.92179 1.98386 6.90929 5.43827 5.68262L6.81308 5.18429C7.16983 5.05971 7.50918 4.95429 7.83113 4.88721C7.57009 5.47179 7.36126 6.18096 7.18723 6.99554L6.33451 11.011C5.48178 15.0168 6.60425 16.991 10.2327 17.9397L11.6945 18.323C12.1992 18.4572 12.6691 18.5435 13.1041 18.5818Z" fill="#246BFD" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </div>
                                <h5>
                                    {item.bank}

                                </h5>

                            </div>
                        )

                    })
                }
            </section>



        </section>
    )



}


export default AccountNumberTable;