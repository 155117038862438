import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import ChangeTransactionPin from 'container/TransactionPin/ChangeTransactionPin'

const TransactionPinPage = () => {
  return (
    <OtherDashboard>
        <ChangeTransactionPin />
    </OtherDashboard>
  )
}

export default TransactionPinPage