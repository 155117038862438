import CartWrapper from "./CartWrapper";
import OtherDashboard from "container/dashboard/OtherDashboard";

import React from "react";

const GiftCardPage = () => {
  return (
    <OtherDashboard>
      <CartWrapper />
    </OtherDashboard>
  );
};

export default GiftCardPage;
