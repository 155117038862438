function formatLocalCurrency(value: string): string {


    // Remove all non-numeric characters except for the decimal point
    if(value === undefined || value === null){
        return new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency:"NGN",
        }).format(Number(0.00));

    }



    
    const priceValue = value ? value?.replace(/[^\d.]/g, '') : '';
    const formattedValue = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency:"NGN",

    }).format(Number(priceValue));


    return formattedValue



}

export default formatLocalCurrency