import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles/global.scss'
import { store } from 'app/Store/Store';
import {Provider} from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react';

//breakpoints
const theme = extendTheme({
   breakpoints : {
    base: '0em', // 0px
    sm: '30em', // ~480px. em is a relative unit and is dependant on the font-size.
    md: '48em', // ~768px
    lg: '62em', // ~992px
    xl: '80em', // ~1280px
    '2xl': '96em', // ~1536px
    '3xl': '120em', // ~1920px
    '4xl': '144em', // ~2560px
    '5xl': '160em', // ~3072px
    '6xl': '192em', // ~4096px
    '7xl': '224em', // ~5120px
    '8xl': '256em', // ~6144px
    '9xl': '288em', // ~7168px

  },
  colors : {
    primary : 'var(--primary)'
  }

})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <ChakraProvider theme={theme} >
    <App />
    </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

