import React from "react";
import { Select } from "chakra-react-select";
import { useController } from "react-hook-form";
import { Grid, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { css } from "@emotion/react";


//blue scroll bar
const scrollbar = css`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background-color: var(--primary);
    background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--primary) 50%
    );
  }`



interface InputProps {
  title: string;
  placeholder: string;
  type?: string;
  isDisabled?: boolean
  control: any;
  name: string;
  rules?: any;
  errorMessage?: string;
  setValue?: (name: string, value: any) => void;

  defaultValue?: any;
  options: any[];
}

const DropdownWithImage: React.FC<InputProps> = ({
  control,
  options,
  name,
  title,
  errorMessage,
  placeholder,
  defaultValue,
  isDisabled,
  setValue,
}) => {
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules: {
      required: true,
    },
    defaultValue: defaultValue,
  });

  useEffect(() => {
    if (setValue && defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, setValue, name]);

  return (
    <Grid gap={".5rem"}>
      <Text
        as={"label"}
        color={"var(--dark-text) !important"}
        fontWeight={500}
        fontSize={{
          base: ".875rem",
          lg: "1rem",
        }}
      >
        {title}
      </Text>
      <FormControl isInvalid={errors[name] ? true : false}>
        {/* <Select
        useBasicStyles= {true}
        size={'lg'}
          {...field}
          options={options}
          focusBorderColor="blue.500"
          errorBorderColor="red.500"


        /> */}

        <Menu>
          <MenuButton
           isDisabled={isDisabled}
            _hover={{
              bg: "none",
            }}
            borderColor={field.value ? "var(--primary)" : "var(--gray-text)"}
            className={`md:w-[25.25rem] h-full rounded-[.625rem]  hover:border-primary `}
            textAlign={"left"}
            justifyContent={"space-between"}
            px={"1.25rem"}
            width={{
              base: "100%",
            }}
            variant={"outline"}
            fontSize={".875rem"}
            size={"lg"}
            as={Button}
            rightIcon={<TiArrowSortedDown />}
            textTransform={"capitalize"}
            py={"1.5rem"}
            // maxW={"70vw"}
          >
            <Flex
              gap=".5rem"
              align={"center"}
              fontWeight={field.value ? "600" : "normal"}
              color={field.value ? "var(--dark-text)" : "var(--text-1)"}
            >
              {field.value?.image ? (
                <Avatar src={field.value?.image} width={"1rem"} h={"1rem"} name={field.value?.label} />
              ) : null}
              <Text maxW={"100%"} overflow={"hidden"} fontSize={".875rem"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}

              >
                {field.value?.label || placeholder}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList py={'1rem'} css={scrollbar}  maxW={'80vw'}  overflowY={'auto'} gap={'.8rem'} maxHeight={{
            "lg": "30rem",
            "base": "25rem",

          }}>
            {options?.map((item, index) => (
              <MenuItem key={index} onClick={() => field.onChange(item)} >
                <Flex gap=".5rem" align={"center"}>
                  {item.image ? (
                    <Avatar src={item.image} width={"1rem"} h={"1rem"} />
                  ) : null}
                  <Text>{item.label}</Text>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <FormErrorMessage fontSize={".7rem"}>{errorMessage}</FormErrorMessage>
      </FormControl>
    </Grid>
  );
};

export default DropdownWithImage;
