import React from 'react'
import style from './form.module.scss'
import Input from 'components/form/inputs/Input'
import ConfirmDetails from '../ConfirmDetails/ConfirmDetails'
import PinComponent from 'components/form/Auth/Pin/Pin'
import { Grid } from '@chakra-ui/react'

// import {motion} from  'framer-motion'

const Form = ({ onChange, next, details  , amount , setPin}: {
    details: {
        amount: string
        mobile: string
        pin: string
    },
    setPin: (e: any) => void
    amount : string
    onChange: (e: any) => void
    next: '1' | "2" | "3" | "4"


}) => {
    let Component

    if (next === '1') {

        Component = <Details onChange={onChange} details={details} amount={amount} />


    }
    if (next === '3') {

        Component = <Pin onChange={onChange} value={details.pin} setPin={setPin} />



    }
    if (next === '2') {

        Component = <ConfirmDetails />



    }


        // const slideInVariants = {
        //   hidden: {
        //     x: '-100%',
        //   },
        //   visible: {
        //     x: 0,
        //     transition: {
        //       type: 'spring',
        //       damping: 10,
        //       stiffness: 100,
        //     },
        //   },
        // };



    return (
        <section
        // initial="hidden"
        // animate="visible"
        // variants={slideInVariants}

        className={style.form}>
            {Component}
        </section>
    )



}

export default Form;

const Details = ({
    onChange, details , amount
}: {
    onChange: (e: any) => void
    details: {
        amount: string
        mobile: string
    }
    amount : string
}) => {
    return <Grid w={{
        base:'100% !important',
        md:'30rem !important',

       '2xl':'35em !important',


    }}  className='border-[.1rem] rounded-[1.2rem]  border-[#B2B3B3] md:pt-[5.7rem] md:pb-[6.8rem]   px-[2rem] py-[4rem] '>
        <Input  type='text' name='mobile' onChange={onChange} value={details?.mobile} placeholder='phone number' label='Receiver’s Phone Number' />
        <Input label='Amount to Transfer' name='amount' onChange={onChange} value={details?.amount} placeholder='N1,000' type='text' />
    </Grid>
}

const Pin = ({
    onChange, value , setPin
}: {
    onChange: (e: any) => void
    value: string
    setPin : (e: any) => void
}) => {
    return( <div className='2xl:mb-[10rem]'>
         <PinComponent myPin={setPin}  title='' tokenNumber={4} />

    </div>)

}









