import { authApi } from "app/ApiSlice";


const DataApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
    //Data Endpoint
    getDataProvider: builder.query({
        query: () => `/v1/bill/data/provider`,


    }),

    selectDataProvider: builder.query({
        query: (provider) => ({
            url: `/v1/bill/data/service?service_id=${provider}`,
            method: 'GET',

        }),
    }),

    selectDataProviderByService : builder.query({
        query: ({name , id}) => ({
            url : `/v1/bill/data/service?name=${name}&service_id=${id}`,
            method : 'GET',
        })
    }),


    buyData: builder.mutation({
        query: (data) => ({
            url: `/v1/bill/data/buy`,
            method: 'POST',
            body: data
        })
    }),


})
})

export const {useGetDataProviderQuery , useSelectDataProviderQuery ,
     useBuyDataMutation , useSelectDataProviderByServiceQuery} = DataApiSlice