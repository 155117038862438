import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import ProfileContainer from 'container/Profile/ProfileContainer'

const ProfilePage = () => {
  return (
    <OtherDashboard >
        <ProfileContainer />
    </OtherDashboard>
  )
}

export default ProfilePage