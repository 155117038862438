import style from './chartheader.module.scss'
import { useState } from 'react'

//import dot icon
// import { BsDot } from 'react-icons/bs'
import React from 'react'
import Dropdown from 'components/GeneralDropdown/Dropdown'
import { Box } from '@chakra-ui/react'



const data = ["Last 7 days", "Last 14 days", "This Month", "Last 1 Month", "Last 3 Months" , "Last 6 Months" , "Last 1 Year"]

const ChartHeader = ({
    title , setOption
}: {
    title: string , setOption: (value: string) => void
}) => {

    const [dropdown, setDropdown] = useState<boolean>(false)
    const [value, setValue] = useState<string>(data[0])
    const handleSelect = (value: string) =>{
        setOption(value)
        setValue(value)

    }

    return (
        <div className={style.chartheader}>
            <h1>
                {title}
            </h1>
            <section className={style.chartheader__right}>
                <section className={style.chartheader__right__left} >
                    <article className={style.income} >
                        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4.5" r="4" fill="#29A073" />
                        </svg>

                        <span>Income</span>
                    </article>

                    <article className={style.expenses} >
                        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4.5" r="4" fill="#246BFD" />
                        </svg>

                        <span>Expenses</span>
                    </article>



                </section>
                <Box as='section' ml={'1rem'}  className={style.chartheader__right__right} >


                         <Dropdown data={data} handleSelect={handleSelect} value= {value} />





                </Box>

            </section>
        </div>
    )

}

export default ChartHeader;