import OtherDashboard from "container/dashboard/OtherDashboard";




import ServicesContainer from "container/services/Services";
import React from "react";
const ServicesPage = () => {

    return (
        <OtherDashboard>
            <ServicesContainer />
        </OtherDashboard>
    )
}

export default ServicesPage;
