import React from "react";
import { SVGS } from "constants/SVGS";
import {
  Flex,
  Grid,
  Text,
  Image,
  Icon,
  useClipboard,
  Button,
  Box,
  Table ,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,

} from "@chakra-ui/react";
import formatLocalCurrency from "utils/formatLocalCurrency";
import { useGetTransactionByIdQuery } from "features/Transactions/TransactionApiSlice";
import Loading from "components/Loading/Loading";

import { FaCopy } from "react-icons/fa";
import { toast } from "react-hot-toast";



interface ItemInterface {
  item: {
    payment_type: string;
    category: string;
    amount: string;
    request_id: string;
    transactions_amount: string;
  };
}

interface Transaction {
  status: string;
  product_name: string;
  unique_element: string;
  unit_price: number;
  quantity: number;
  service_verification: any;
  channel: string;
  commission: number;
  total_amount: number;
  discount: any;
  type: string;
  email: string;
  phone: string;
  name: string | null;
  convinience_fee: number;
  amount: number;
  platform: string;
  method: string;
  transactionId: string;
}

interface TransactionResponse {
  transactions: Transaction;

  response_description: string;
  requestId: string;
  amount: string;
  transaction_date: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  purchased_code: string;
  certUrl: string;
}

interface Data {
  token: string;
}

const justify: string = "flex-end";
const color: string = "gray.700";
const dark: string = "gray.900";
const fontBig: string = "1.1rem";
const templateColumn = "repeat(2 ,  1fr)";
const fontWeight: string = "700";

const ViewTransactions: React.FC<ItemInterface> = ({ item }) => {
  const { data, isLoading } = useGetTransactionByIdQuery(item?.request_id, {
    skip: !item?.request_id,
  });

  const transaction: TransactionResponse = data?.data?.content;
  const _data: Data = data?.data;

  const image = SVGS[item?.category];

  return (
    <Box>
      <Flex
        className="text-gray.700"
        //   color={"gray.700"}
        align={"center"}
        justify={"center"}
        flexDir={"column"}
      >
        <Image
          mb={"25px"}
          src={image}
          w={{ lg: "100px", base: "70px" }}
          h={{ lg: "100px", base: "70px" }}
        />
        <Box
          w={"100%"}
          h={".2rem"}
          bg={"gray.100"}
          borderRadius={"lg"}
          my={"2rem"}
        ></Box>
        {isLoading ? (
          <Loading />
        ) : (
          <TableContainer >
          <Table
            gap={{ base: "15px", lg: "20px", "2xl": "25px" }}
            color={color}
            fontSize={"1rem"}
            w={{
              base: "100%",
            }}
            px={{
              base: "1rem",
              lg: "2rem",
            }}
          >
            <Tbody>
            <Tr>
              <Td>
              <Text
                color={"gray.700"}
                fontSize={{
                  base: "1rem",
                }}
              >{`${item.category} Amount`}</Text>
              </Td>
              <Td>
              <Flex justify={justify}>
                <Text color={dark} fontSize={fontBig} fontWeight={fontWeight}>
                  {formatLocalCurrency(
                    transaction?.transactions.amount
                      ? transaction?.transactions?.amount?.toString()
                      : item?.transactions_amount
                  )}
                </Text>
              </Flex>
              </Td>
            </Tr>
            <Detail
              value={transaction?.transactions?.phone}
              title="Phone Number"
            />
            {/* //! TODO:   */}
            {/* <Detail
          title="Renewal Status"
          value={transaction?.response_description}
        /> */}
            <Detail
              title="Transaction Fee"
              value={formatLocalCurrency(
                transaction?.transactions?.convinience_fee?.toString()
              )}
            />

            <Detail
              title="Transaction Reference"
              value={transaction?.transactions?.transactionId}
            />
            <Detail title="Token" value={_data?.token} isToken={true} />
            <Detail
              title="Total"
              value={formatLocalCurrency(
                item.amount || item?.transactions_amount
              )}
              isLast={true}
            />
            </Tbody>
          </Table>
          </TableContainer>
        )}
      </Flex>
      <Flex justify={"flex-end"} mt={"2rem"} mr={"2rem"}>
        <Button
          colorScheme="messenger"
          padding={"1rem"}
          fontSize={"1rem"}
          color={"white"}
          bg={"var(--primary)"}
        >
          Print
        </Button>
      </Flex>
    </Box>
  );
};

export default ViewTransactions;

interface DetailInterface {
  title: string;
  value: string | undefined | number;
  isLast?: boolean;
  isToken?: boolean;
}

/**
 *
 * @param value
 * @pram isLast
 * @param title
 *
 * @returns
 */
const Detail: React.FC<DetailInterface> = ({
  value,
  title,
  isLast,
  isToken,
}) => {
  const { onCopy } = useClipboard(value as string);
  const handleCopy = () => {
    onCopy();
    toast.success("Copied to clipboard");
  };
  return value ? (
    <Tr>
      <Td>
      <Text fontWeight={isLast ? "700" : ""}>{title}</Text>
      </Td>
      <Td >
      <Flex justify={justify} maxW={'100%'}>
        <Text
          color={isToken ? "var(--primary)" : ""}
          fontWeight={fontWeight}
          fontSize={fontBig}


        >
          {value}
          {isToken && (
            <Text
              as={"span"}
              className="text-primary"
              onClick={handleCopy}
              cursor={"pointer"}
            >
              <Icon as={FaCopy} />
            </Text>
          )}
        </Text>
      </Flex>
      </Td>
    </Tr>
  ) : null;
};
