// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import { RESET_STORE } from './actions';

// Import your individual reducers
import AuthSlice from 'features/Auth/AuthSlice';
import ProfileSlice from 'features/Profile/ProfileSlice';
import TransactionSlice from 'features/Transactions/TransactionSlice';
import WalletSlice from 'features/Wallet/WalletSlice';
import DataSlice from 'features/Services/DataService/DataSlice';
import ServicesSlice from 'features/Services/ServicesSlice';
import InternetServiceSlice from 'features/Services/InternetService/InternetServiceSlice';
import ElectricityServiceSlice from 'features/Services/ElectricityService/ElectricityServiceSlice';
import CableSlice from 'features/Services/CableService/CableSlice';
import EducationSlice from 'features/Services/EducationService/EducationSlice';
import GiftCardServiceSlice from 'features/Services/GiftCardService/GiftCardServiceSlice';
import SubscriptionSlice from 'features/Subscription/SubscriptionSlice';
import BeneficiariesSlice from 'features/Beneficiaries/BeneficiariesSlice';
import InsuranceServiceSlice from 'features/Services/Insurance/InsuranceServiceSlice';
import { api, authApi } from 'app/ApiSlice';

const appReducer = combineReducers({
  services: ServicesSlice,
  profile: ProfileSlice,
  auth: AuthSlice,
  transaction: TransactionSlice,
  wallet: WalletSlice,
  dataService: DataSlice,
  internetService: InternetServiceSlice,
  electricityService: ElectricityServiceSlice,
  cableService: CableSlice,
  educationService: EducationSlice,
  giftCardService: GiftCardServiceSlice,
  subscription: SubscriptionSlice,
  beneficiary: BeneficiariesSlice,
  insuranceService: InsuranceServiceSlice,
  [api.reducerPath]: api.reducer,
  [authApi.reducerPath]: authApi.reducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
  if (action.type === RESET_STORE) {
    state = undefined; // Reset the state to undefined
  }
  return appReducer(state, action);
};

export default rootReducer;
