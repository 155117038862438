import OtherDashboard from "container/dashboard/OtherDashboard";
import ChangeTransactionPin from "container/TransactionPin/ChangeTransactionPin";
import React from "react";



const ChangeTransactionPinPage = () => {
    return (
        <OtherDashboard>
            <ChangeTransactionPin />
        </OtherDashboard >


    )

}

export default ChangeTransactionPinPage