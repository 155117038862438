import React, { useState } from "react";
import {
  Icon,
  Text,
  Flex,
  Box,
  PinInput,
  PinInputField,
  Button,
  useToast,
  Avatar,
  Grid,
} from "@chakra-ui/react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { useGetBvnQuery } from "features/BVN/BvnApiSlice";
import ModalContainer from "components/Modals/ModalContainer";

const ViewBVN = ({
  onClose,
  size,
  isOpen,
}: {
  onClose: () => void;
  size: string;
  isOpen: boolean;
}) => {
  const [pin, setPin] = useState("");
  const [bvn, setBvn] = useState<string | null>(null);
  const [myData, setMyData] = useState<any>(null);
  const { data, isLoading, error } = useGetBvnQuery(pin, {
    skip: !pin,
  });

  const toast = useToast();
  console.log(error);

  // {
  //   id: '35',
  //   user_id: '187',
  //   account_number: '9916579615',
  //   gtb_account_number: '1365528419',
  //   bvn: '22382399442',
  //   date_of_birth: '08/03/1994',
  //   logo_providus:
  //     'https://play-lh.googleusercontent.com/AKnXI1tg78pAiIy0SyS8zKoYcFbV-JD1zsvpL0NIvC4ku-t2c1BNglReiCJA42fdaIgd',
  //   logo_gtb: 'https://nigerianbanks.xyz/logo/guaranty-trust-bank.png',
  //   created_at: '2023-09-25 16:37:57',
  //   updated_at: '2023-09-25 16:37:57',
  //   show_providus: 'on',
  //   show_gtb: 'on'
  // }

  React.useEffect(() => {
    if (data) {
      setMyData(data?.data[0]);
      setBvn(data?.data[0]?.bvn);
    }
  }, [data]);

  const handleCopy = () => {
    if (bvn) {
      navigator.clipboard.writeText(bvn);
      toast({
        title: "Copied to clipboard.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  React.useEffect(() => {
    return () => {
      setBvn(null);
      setPin("");
      setMyData(null);
    };
  }, []);

  return (
    <ModalContainer size={size} isOpen={isOpen} onClose={onClose}>
      <Box p={4}>
        {!bvn ? (
          <Grid placeContent={"center"} gap={"1rem"} placeItems={"center"}>
            <Text
              color={"var(--primary)"}
              fontSize={{
                base: "xl",
                lg: "2xl",
              }}
              mb={4}
              fontWeight={"bold"}
            >
              Enter Transaction PIN
            </Text>
            <Text
              mb={4}
              w={{
                base: "100%",
                lg: "30ch",
              }}
              textAlign={"center"}
            >
              Please enter your 4-digit transaction PIN to access your BVN.
            </Text>
            <Flex align={"center"} gap={".5rem"}>
              <PinInput
                type="number"
                value={pin}
                onChange={(value) => setPin(value)}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </Flex>
            <Button
              bg={"var(--primary)"}
              color={"white"}
              _hover={{ bg: "var(--primary)", opacity: 0.8 }}
              mt={4}
              onClick={() => setPin(pin)}
              isDisabled={isLoading || !pin}
            >
              Continue
            </Button>
            {error ? (
              <Text fontSize={"sm"} color={"red"}>
                {error ? "Invalid Transaction Pin" : ""}
              </Text>
            ) : null}
          </Grid>
        ) : (
          <Grid placeContent={"center"} gap={"1rem"} placeItems={"center"}>
            <Text
              color={"var(--primary)"}
              fontSize={{
                base: "xl",
                lg: "2xl",
              }}
              mb={4}
              fontWeight={"bold"}
            >
              Your BVN
            </Text>
            <Flex
              align={"center"}
              gap={"1rem"}
              padding={".5rem 1.5rem"}
              border={"1px solid"}
              borderRadius={"md"}
              boxShadow={"md"}
              borderColor={"gray.300"}
            >

            
              <Text
                fontSize={{
                  base: "xl",
                  lg: "2xl",
                }}
                fontWeight={"bold"}
              >
                {bvn}
              </Text>
              <Icon
                as={HiOutlineClipboardCopy}
                boxSize={6}
                ml={4}
                cursor="pointer"
                onClick={handleCopy}
              />
            </Flex>
            <Button
              mt={"3rem"}
              variant={"outline"}
              colorScheme="red"
              onClick={onClose}
            >
              Close
            </Button>
          </Grid>
        )}
      </Box>
    </ModalContainer>
  );
};

export default ViewBVN;
