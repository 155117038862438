import React from 'react'



const CheckedIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#246BFD" />
            <path d="M8 12L11 15L17 9" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )

}

const NotCheckedIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="10" cy="10" r="8" fill="white" stroke="url(#paint0_linear_180_2242)" stroke-width="4" />
            <defs>
                <linearGradient id="paint0_linear_180_2242" x1="20" y1="20" x2="-3.7982" y2="13.0999" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#246BFD" />
                    <stop offset="1" stop-color="#6F9EFF" />
                </linearGradient>
            </defs>
        </svg>
    )

}


const LineIconChecked = () => {
    return (

        <svg width="300"  height="6" viewBox="0 0 300 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="300" height="6" rx="3" fill="url(#paint0_linear_166_1008)" />
            <defs>
                <linearGradient id="paint0_linear_166_1008" x1="300" y1="6" x2="298.167" y2="-20.5675" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#246BFD" />
                    <stop offset="1" stop-color="#6F9EFF" />
                </linearGradient>
            </defs>
        </svg>


    )
}

const LineIconNotChecked = () => {
    return (
        <svg width="250" height="6" viewBox="0 0 250 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="380" height="6" rx="3" fill="#E5E5E5" />
        </svg>


    )
}

const UnCheckedLine= ()=>{
    return(
        <svg width="250" height="6" viewBox="0 0 250 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="250" height="6" rx="3" fill="#E5E5E5"/>
</svg>


    )
}
const CheckedLine = ()=> {
    return(
        <svg width="200" height="6" viewBox="0 0 200 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="200" height="6" rx="3" fill="url(#paint0_linear_180_2234)"/>
<defs>
<linearGradient id="paint0_linear_180_2234" x1="200" y1="6" x2="197.267" y2="-20.4111" gradientUnits="userSpaceOnUse">
<stop stop-color="#246BFD"/>
<stop offset="1" stop-color="#6F9EFF"/>
</linearGradient>
</defs>
</svg>


    )
}


export { CheckedIcon, NotCheckedIcon, LineIconChecked, LineIconNotChecked , UnCheckedLine , CheckedLine  }




