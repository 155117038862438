import React from "react";
import Card from "./Card";
import style from "./cardlist.module.scss";
import Pagination from "components/Pagination/Pagination";
import { useAppDispatch } from "hooks/redux";
import { setPage } from "features/Services/GiftCardService/GiftCardServiceSlice";
import { useAppSelector } from "hooks/redux";


interface CardListProp {
  data: {
    image_path: string;
    name: string;
    price: string;
  }[];
  fullData?: any;
  handleClick: (item: {
    image_path: string;
    name: string;
    price: string;
  }) => void;
}

const CardList: React.FC<CardListProp> = ({ handleClick }) => {
    const { products : data, fullData } = useAppSelector(
    (state) => state.giftCardService
  );

  const pageCount = fullData?.meta?.total / fullData?.meta?.per_page;
  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(setPage(e.selected + 1));
  };

  return (
    <>
      <main className={style.cardlist}>
        {data?.map(
          (
            item: { image_path: string; name: string; price: string },
            index: number
          ) => {
            return <Card key={index} handleClick={handleClick} item={item} />;
          }
        )}
      </main>
      <div className="flex justify-end items-center mt-10">
        <Pagination pageCount={pageCount} onPageChange={onChange} />
      </div>
    </>
  );
};

export default CardList;
