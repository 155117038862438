/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import style from "./jaralink.module.scss";
import { useAppSelector } from "hooks/redux";
import { useDisclosure } from "@chakra-ui/react";
import LiveChat from "./LiveChat";
import ViewBVN from "./ViewBVN";
import { useNavigate } from "react-router-dom";

const JaraLink = ({
  link,
  title,
  icon,
  noRedirect,
}: {
  link: string;
  title: string;
  icon: string;
  noRedirect?: boolean;
}) => {
  const { user } = useAppSelector((state) => state.auth as any);
  sessionStorage.setItem("user", user?.data);
  sessionStorage.setItem("user_mail", user?.data?.email);
  sessionStorage.setItem("mail", user?.data?.email);
  sessionStorage.setItem("id", user?.data?.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const navigate = useNavigate();


  
  const handleOpen = () => {
    if (!link && !title?.toLowerCase()?.includes("bvn")) {
      onOpen();
    }
    if (title?.toLowerCase()?.includes("bvn")) {
      if (!user?.data?.virtual_account?.length) {
        navigate("/bvn-verification");
      } else {
        onViewOpen();
      }
    }
  };
  //unmount

  return (
    <Link
      to={link}
      onClick={handleOpen}
      className={style.jaralink}
      target={link && !noRedirect ? "__blank__" : ""}
    >
      <img src={icon} alt={title} className="w-[1.875rem] h-[1.875rem]" />

      <p>{title}</p>
      <LiveChat isOpen={isOpen} onClose={onClose} size="lg" />
      <ViewBVN isOpen={isViewOpen} onClose={onViewClose} size="lg" />
    </Link>
  );
};

export default JaraLink;
