import { useAddBeneficiaryMutation } from "features/Beneficiaries/BeneficiariesApiSlice";
import { useAppSelector } from "./redux";


const useAddBeneficiary = () => {
    const [addBeneficiary] = useAddBeneficiaryMutation();
    const services = useAppSelector((state) => state.services);

    let emptyResponse = (data: any) =>  data

  if (services.add_beneficiary) {
        return addBeneficiary
    }
    else {
        return emptyResponse

    }





};

export default useAddBeneficiary;


