import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServiceInputDropdown from "components/form/Services/DropdownInput";
import ServicesInput from "components/form/Services/Input";
import ServicesPlainDropdown from "components/form/Services/PlainDropdown";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import {
  useSelectEducationProviderQuery,
  useSelectEducationProviderByServiceQuery,
} from "features/Services/EducationService/EducationApiSlice";
import {
  setVariations,
  setFormVariation,
} from "features/Services/ServicesSlice";
import ReactLoading from "react-loading";
import { useWatch } from "react-hook-form";
import DropdownWithImage from "components/form/Services/ServiceSelect";

const EductionForm = ({
  control,
  setValue,
}: {
  control: any;
  setValue: any;
}) => {
  //dispatch
  const dispatch = useAppDispatch();
  const watch = useWatch({
    control,
    name: "serviceID",
  });
  const educationServices = useAppSelector(
    (state) => state.educationService as any
  );
  const variations = useAppSelector(
    (state) => state.services.variations as any
  );
  //Set Item

  //variation
  const [variation, setVariation] = useState<any[]>([]);
  const { data } = useSelectEducationProviderQuery(watch?.serviceID, {
    skip: !watch?.serviceID,
  });
  const { data: educationProvider, isLoading } =
    useSelectEducationProviderByServiceQuery(
      { name: watch?.name, id: watch?.serviceID },
      {
        skip: !watch?.name,
      }
    );
  const [defaultPlan, setDefaultPlan] = useState<string>("");

  //SELECT PROVIDER
  useEffect(() => {
    if (data) {
      setDefaultPlan(data.content?.varations[0]?.name);
      setVariation(data.content?.varations);
      //dispatch variation
    }
  }, [data]);

  useEffect(() => {
    if (educationProvider) {
      dispatch(setVariations(educationProvider.content?.varations));
    }
  }, [educationProvider, dispatch]);

  const watchVariation = useWatch({
    control,
    name: "variation_code",
  });

  useEffect(() => {
    dispatch(setFormVariation(watchVariation));
  }, [dispatch, watchVariation]);

  // console.log(watch?.serviceID

  const educationOptions = educationServices?.educationProviders?.map(
    (item: any) => ({
      ...item,
      value: item.serviceID,
      label: item.name,
      image: item.image,
    })
  );

  const variationOptions = variation?.map((item: any) => ({
    ...item,
    value: item.name,
    label: item.name,
  }));

  return (
    <form className="flex items-center justify-center relative w-full">
      {isLoading ? (
        <div className="grid place-content-center place-items-center absolute right-[50%] top-0">
          {" "}
          <ReactLoading
            type={"spinningBubbles"}
            color={"#246BFD"}
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <div className={style.form}>
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={educationOptions}
          setValue={setValue}
          control={control}
          name="serviceID"
          defaultValue={educationServices[0]?.name}
          title={"Exam Type"}
          placeholder={"Select Exam Type"}
        />
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={variationOptions}
          defaultValue={variationOptions[0]}
          title={"Select Exam"}
          name={"variation_code"}
          placeholder={"Select Exam"}
          type={""}
          control={control}
          setValue={setValue}
        />
        <ServicesInput
          rules={{
            // minLength: {
            //     value: 11, message: 'Phone number should have at least 11 digits.'
            // },
            required: "Field cannot be empty.",
          }}
          maxLength={11}
          defaultValue="1"
          setValue={setValue}
          label={"Quantity"}
          name={"quantity"}
          placeholder={"Select Quantity"}
          onChange={() => {
            return null;
          }}
          value={""}
          control={control}
        />

        <ServicesInput
          rules={{
            minLength: {
              value: 11,
              message: "Phone number should have at least 11 digits.",
            },
            required: "Field cannot be empty.",
          }}
          maxLength={11}
          defaultValue="08011111111"
          label={"Enter Phone Number"}
          name={"phone_number"}
          placeholder={"08011111111"}
          onChange={() => {
            return null;
          }}
          value={""}
          control={control}
        />

        {watch?.serviceID === "jamb" ? (
          <ServicesInput
            rules={{
              required: "Field cannot be empty.",
            }}
            maxLength={11}
            defaultValue=""
            label={"Enter Your Profile ID"}
            name={"billersCode"}
            placeholder={"profile id"}
            onChange={() => {
              return null;
            }}
            value={""}
            control={control}
          />
        ) : null}

        {/* <p>

                    Select Beneficiary
                </p> */}
        {/* <ServiceInputDropdown getItem={(item: any) => { }} setValue={setValue} control={control} data={[

                    "Off",
                    "Daily",
                    "Weekly",
                    "Monthly",
                    "3 Months",
                    "6 Months",
                    "12 Months",
                ]} defaultValue='Off' name='renewal' title={'Renewal Status'} placeholder={'Set renewal status'} /> */}
      </div>
    </form>
  );
};

export default EductionForm;
