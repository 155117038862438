export function setCookie(name: string, value: string | number, days: number): void {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }
}

const KeepUserLoggedInForDaysCookie = (days: number, value: string | number, name: string) => {
  setCookie(name, value ,  days);
};

export default KeepUserLoggedInForDaysCookie;
