import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: [],
  funding: [],
  transactionLoading: false,
  transactionDates: [],
  expenses: [],
  income: [],
  pages: [],
  page: 1,
  fundingPage: 1,
  searchedTransactions: [],
  search :'' ,
};

const transactionSlice = createSlice({
  initialState,
  name: "transaction",
  reducers: {
    setTransactions: (state, action) => {
      if (action?.payload?.data) {
        state.transactions = action?.payload?.data;
      }
    },
    setFunding: (state, action) => {
      if (action?.payload?.data) {
        state.funding = action?.payload?.data;
      }
    },
    setTransactionLoading: (state, action) => {
      state.transactionLoading = action.payload;
    },
    setTransactionDates: (state, action) => {
      state.transactionDates = action.payload;
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    setIncome: (state, action) => {
      state.income = action.payload;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setPage: (state) => {
      state.page = state.page + 1;
    },
    setSearchedTransactions: (state, action) => {
      state.searchedTransactions = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    }
  },
});

export default transactionSlice.reducer;
export const {
  setSearchedTransactions,
  setFunding,
  setTransactionDates,
  setTransactionLoading,
  setExpenses,
  setIncome,
  setTransactions,
  setPage,
  setPages,
  setSearch,
} = transactionSlice.actions;
