import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import SecurityContainer from 'container/Security/Security'


const SecurityPage = () => {
    return (
        <OtherDashboard>
            <SecurityContainer />
        </OtherDashboard>

    )
}

export default SecurityPage