import React , { useState} from 'react'
import Item from './RewardItem'
import style from './transaction.module.scss'
import { useAppSelector } from 'hooks/redux'
import Pagination from 'components/Pagination/Pagination'
import { Box } from '@chakra-ui/react'
interface  Props{

    data : any[]
    handleClick : ()=> void

}
const RewardList:React.FC<Props> = ({

    data , handleClick
}) => {
    const { rewardsData } = useAppSelector(state => state.profile)
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10;


    const endOffset = itemOffset + itemsPerPage;
    const myData = rewardsData?.data?.reward_history?.filter((item:any) => {
        return item.amount !== '0'
    })
    const currentItems = myData?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(myData?.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage) % myData?.length;

      setItemOffset(newOffset);
    };





  return (
    <>
    <main className={style.list__container}>

        {
            currentItems?.map((item:any, index: number) => {

                return (
                   ( <section key={index} >
                        <Item item={item} handleClick={handleClick} />
                    </section>)
                )
            })
        }


    </main>
    <Box mt={'2rem'}>
    <Pagination pageCount={pageCount} onPageChange= {handlePageClick} />
    </Box>
    </>
  )
}

export default RewardList