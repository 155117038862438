import React from 'react';
import Heading from './Heading/Heading';
import BackButton from 'components/buttons/backbutton/BackButton';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker2';
import style from './generalservicestyle.module.scss'
import { InsuranceForm1 ,  InsuranceForm2 , InsuranceForm3, InsuranceForm4 } from './Form/InputForm'
import PinForm from './Form/EnterPin';
import ConfirmForm from './Form/ConfirmForm';

import ServicesPayment from 'components/paymentOptions/ServicesPayment';
import ListInsurance from 'components/Insurance/ListInsurance';
import DashboardContext from 'context/Dashboard/DashboardContext';


const insuranceData = [
    {
        imageSrc : '/Services/selectInsurance.svg',
        title: "Third Party Motor Insurance Universal Insurance"
    },
    {
        imageSrc : '/Services/selectInsurance.svg',
        title: "Health Insurance - HMO"
    },{
        imageSrc: '/Services/selectInsurance.svg',
        title: "Personal Accident Insurance"
    },
    {
        imageSrc: '/Services/selectInsurance.svg',
        title: "Home Cover Insurance"
    }
]


const confirmData = [
    {
        "title": "Customer Name",
        "value": "Mr Riches"
    },
    {
        "title": "Debt",
        "value": "N5000"
    },
    {
        "title": "Provider",
        "value": "Ikeja Electric"
    },
    {
        "title": "Meter Number",
        "value": "1111111111111"
    },
    {
        "title": "Phone Number",
        "value": "08069567130"
    },
    {
        "title": "Address",
        "value": "Ikeja"
    }
    ,
    {
        "title": "Renewal",
        "value": "Off"
    }
    ,
    {
        "title": "Sub Total",
        "value": "N5,100.00"
    },
    {
        "title": "Convenience Fee",
        "value": "N100"
    },
    {
        "title": "Total",
        "value": "N5,100.00"
    }

]



const InsuranceService = () => {
    const [step, setStep] = React.useState<'1' | "2" | "3" | "4" | "5">("1")
    const [steps, setSteps] = React.useState<string[]>(["1"])
    const {setCurrentPagename} = React.useContext(DashboardContext)
    const [title  , setTitle] = React.useState<string>("Third Party Motor Insurance Universal Insurance")

    //handle insurance name
    const handleInsuranceName = (value: string) => {
        setTitle(value)
    }

    let heading =   <Heading title={"Third Party Motor Insurance - Universal Insurance"} desc="Pay for third party motor insurance-" desc2="universal insurance conveniently & easily." />
    let insuranceForm = <InsuranceForm1 />
    if(title === "Health Insurance - HMO"){
        heading =   <Heading title={"Health Insurance - HMO"} desc="Pay for health insurance - hmo" desc2="conveniently & easily." />
        insuranceForm = <InsuranceForm2 />
    }
    else if(title === "Personal Accident Insurance"){
        insuranceForm = <InsuranceForm3 />
        heading =   <Heading title={"Personal Accident Insurance"} desc="Pay for personal accident insurance" desc2="conveniently & easily." />
    }
    else if(title === "Home Cover Insurance"){
        insuranceForm = <InsuranceForm4 />
        heading =   <Heading title={"Home Cover Insurance"} desc="Pay for home cover insurance" desc2="conveniently & easily." />
    }

    const handleNext = () => {
        setSteps([...steps, step])


        if (step === "1") {
            setStep("2")

        }
        if (step === "2") {
            setStep("3")

        }
        if (step === "3") {
            setStep("4")
        }
        if (step === "4") {
            setStep("5")


        }

    }

    const handleBack = () => {
        setSteps(steps.slice(0, -1))


        if (step === "1") {
            setStep("1")

        }

        if (step === "2") {
            setStep("1")

        }
        if (step === "3") {
            setStep("2")
        }
        if (step === "4") {
            setStep("3")

        }
        if (step === "5") {
            setStep("4")


        }



    }
    if(step === "1"){
        setCurrentPagename("Insurance")
    }
    let text  = "All Services"
    if (step === "2") {
        text = "Type"
        setCurrentPagename("Buy Insurance")
    }
    if (step === "3") {
        text = "Internet"
        setCurrentPagename("Confirm")

    }
    if (step === "4") {
        text = "Confirm"
        setCurrentPagename("Payment options")


    }
    if (step === "5") {
        text = "Payment Options"
        setCurrentPagename("Transaction PIN")


    }

    return (
        <div className={style.data} >
            <div className='pl-[2.5rem]' onClick={handleBack}>
                <BackButton text={
                    text

                } link={
                    step === "1" ? "/services" : "/services?category=inssurance"
                } />
            </div>

            <section className={style.data__section}>

                {step === "1" ? <Step1 handleClick = {handleInsuranceName}  /> : null}

                {step === "2" ? <Step2 heading={heading} steps={steps} insuranceForm={insuranceForm} /> : null}

                {step === "3" ? <Step3 steps={steps} /> : null}



                {
                    step === "4" ? <Step4 handleNext = {handleNext} steps={steps}  /> : null
                }
                {
                    step === "5" ? <Step5    /> : null
                }

                <div className='grid place-content-center place-items-center'>
                {(step !== "5" && step !== "4") ? <button onClick={handleNext} className='btn-primary'>Continue</button> : null}

                </div>

            </section>

        </div>
    )

}

export default InsuranceService


const Step1 =  ({
    handleClick
}: {
    handleClick : (value : string) => void
}) => {
    return(
        <div>

                <Heading title={"Select type of Insurance"} desc="Pay for insurance bills safely, conveniently & easily." desc2=" You can pay anytime and anywhere!" />
                <ListInsurance handleClick={handleClick} data={insuranceData} />
        </div>

    )
}

const Step2 = ({
    steps , insuranceForm , heading
}: {
    steps: string[] ,
    insuranceForm : any
    heading : any
}) => {
    return (
        <div>
            <div>

            </div>
            {heading}
            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Insurance Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>

            {insuranceForm}
        </div>
    )
}




const Step3 = ({
    steps
}: {
    steps: string[]
}) => {

const [toggle, setToggle] = React.useState<boolean>(true)

const handleToggle = () => {
    setToggle(!toggle)

}

    return (
        <div>
            <Heading title={"Confirm Insurance Purchase"} desc="Please confirm your insurance purchase" />

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`}>
                <ProgressTracker levels={["Insurance Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>
            <ConfirmForm handleSaveBeneficiary={handleToggle} saveBeneficiary={
                toggle
            } data={confirmData} src='/services/electricityImage.svg' />
        </div>
    )

}


const Step4 = ({
    steps , handleNext
}: {
    steps: string[] , handleNext : () => void
}) => {
    return (

        <div>

            <div className={`${style.progress__tracker} grid place-content-center w-full place-items-center`} >
                <ProgressTracker levels={["Insurance Options", "Confirm Details", "Select Payment", "Enter Pin & Pay"]} steps={steps} />
            </div>
            <ServicesPayment  onClick={handleNext} />

        </div>
    )
}


const Step5 = () => {
    return (
        <div className='grid place-content-center pt-[2.5rem]' >

            <PinForm amount='Pay N50,000' />
        </div >
    )
}