import RequestOTP from "container/resetpin/RequestOtp";

import React from "react";





const  RequestPinReset = () => {
    return (
        <RequestOTP />
    )

}

export default RequestPinReset