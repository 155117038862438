import React from 'react'
import style from './creditcard.module.scss'
import { Link } from 'react-router-dom'
const EmptyCard = () => {
  return (
    <Link to='/bvn-verification' className={style.creditcard}>
        <div className={style.creditcard__message}>
            <h4>Get Virtual Account</h4>
            <p>Please complete your onboarding process to generate your virtual accounts for ease of funding</p>
        </div>



    </Link>
  )
}

export default EmptyCard