import { authApi } from "app/ApiSlice";


const CableApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
    //Cable Endpoint
    getCableProvider: builder.query({
        query: () => `/v1/bill/tv/provider`,


    }),

    selectCableProvider: builder.query({
        query: (provider) => ({
            url: `/v1/bill/tv/service?service_id=${provider}`,
            method: 'GET',

        }),
    }),

    selectCableProviderByService : builder.query({
        query: ({name , id}) => ({
            url : `/v1/bill/tv/service?name=${name}&service_id=${id}`,
            method : 'GET',
        })
    }),


    buyCable: builder.mutation({
        query: (tv) => ({
            url: `/v1/bill/tv/buy`,
            method: 'POST',
            body: tv
        })
    }),

    verifyCable: builder.mutation({
        query: (tv) => ({
            url: `/v1/bill/tv/verify`,
            method: 'POST',
            body: tv
        })
    }),



})
})

export const {useGetCableProviderQuery , useSelectCableProviderQuery ,
     useBuyCableMutation , useSelectCableProviderByServiceQuery,
     useVerifyCableMutation
    } = CableApiSlice