import React from "react";
import { Select } from "chakra-react-select";
import { useController } from "react-hook-form";
import { Grid, Text, FormControl, FormErrorMessage } from "@chakra-ui/react";
import style from './servicesinput.module.scss'
import { useEffect } from "react";

//blue scroll bar

interface InputProps {
  title: string;
  placeholder: string;
  type?: string;
  isDisabled?: boolean;
  control: any;
  name: string;
  rules?: any;
  errorMessage?: string;
  setValue?: (name: string, value: any) => void;

  defaultValue?: any;
  options: any[];
}

const SelectDropdown: React.FC<InputProps> = ({
  control,
  options,
  name,
  title,
  errorMessage,
  placeholder,
  defaultValue,
  isDisabled,
  setValue,
}) => {
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules: {
      required: true,
    },
    defaultValue: defaultValue,
  });

  useEffect(() => {
    if (setValue && defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, setValue, name]);

  return (
    <Grid gap={".5rem"}>
      <Text
        as={"label"}
        color={"var(--dark-text) !important"}
        fontWeight={500}
        fontSize={{
          base: ".875rem",
          lg: "1rem",
        }}
      >
        {title}
      </Text>
      <FormControl isInvalid={errors[name] ? true : false} >
        <Select
          {...field}
          useBasicStyles
          focusBorderColor="#246BFD"
          options={options}
          size={"lg"}
          placeholder={placeholder}
          className={style.select__dropdown}
        

        />

        <FormErrorMessage fontSize={".7rem"}>{errorMessage}</FormErrorMessage>
      </FormControl>
    </Grid>
  );
};

export default SelectDropdown;
