import { authApi } from 'app/ApiSlice'


const BeneficiaryApiSlice = authApi.injectEndpoints({

    endpoints: (builder) => ({
        Beneficiary: builder.query({
            query: (id) => ({
                url: `/v1/beneficiary/getall`,
                method: "GET"
            }),
            providesTags: ['Beneficiary']

        }),
        deleteBeneficiary: builder.mutation({
            query: (data) => ({
                url: 'v1/beneficiary/remove',
                method: "DELETE",
                body: data

            })
            , invalidatesTags: ['Beneficiary']

        }),
        addBeneficiary: builder.mutation({
            query: (data) => ({
                 url : '/v1/beneficiary/add',
                 method : "POST",
                 body : data
            }),
            invalidatesTags: ['Beneficiary']
        })

    })

})


export const { useBeneficiaryQuery , useDeleteBeneficiaryMutation , useAddBeneficiaryMutation } = BeneficiaryApiSlice