
import React from 'react';



const Iconly = ({children}: {
    children: any
}) => {
    return (
        <>
            {children}
            </>
    )

}

export default Iconly
