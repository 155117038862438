


import React, { useContext, useEffect } from 'react'
import DashboardContext from 'context/Dashboard/DashboardContext'
// import WalletInfo from 'components/walletTransfer/WalletInfo/WalletInfo'
import ConfirmFund from 'components/fundwallet/ConfirmFund'
import BackButton from 'components/buttons/backbutton/BackButton'
import PaymentOptions from 'components/paymentOptions/PaymentOptions'
import Modal from 'components/successpage/Modal'
import LoginSucess from 'components/successpage/LoginSuccessful'
import formatLocalCurrency from 'utils/formatLocalCurrency'
import { useAppSelector } from 'hooks/redux'
import {
    useFundWalletMutation,
    useVerifyBTCMutation,
    useBitcoinCallbackMutation,
    useFlutterCallbackMutation,
    usePaystackCallbackMutation,
    useSquadcoCallbackMutation
} from 'features/Wallet/WalletApiSlice'
import LoadingSpinner from 'components/Spinner/Spinner'
import { useNavigate , useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'








const FundWalletContainer = () => {
    const [flutterCallback, { isLoading: isLoadingFlutter, isSuccess: isFlutterSuccess }] = useFlutterCallbackMutation()
    const [paystackCallback, { isLoading: isLoadingPaystack, isSuccess: isPaystackSuccess }] = usePaystackCallbackMutation()
    const [squadcoCallback, { isLoading: isLoadingSquadco, isSuccess: isSquadcoSuccess }] = useSquadcoCallbackMutation()
    const wallet = useAppSelector(state => state.wallet)
    const [fundwallet, { isLoading }] = useFundWalletMutation()
    const [verifyBTC, { isLoading: isLoadingBTC }] = useVerifyBTCMutation()
    const [bitcoinCallback, { isLoading: isLoadingCallback, isSuccess }] = useBitcoinCallbackMutation()
    const [url, setUrl] = React.useState<string>("")

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(()=> {
        const iframe =  document.getElementById("iframe");
        // console.log(iframe)

    } , [])




    const onClick = async () => {
        // const formData = new FormData();
        const amount = parseInt(wallet.amountToFund)
        // formData.append('amount', amount as string )
        // formData.append(`channels` , "['card']")


        const data = {
            body: {
                amount: wallet.payment.paymentType === "PayStack" ? `${amount}` : `${amount}`, channels: ['card']
            },
            url: wallet.payment.paymentUrl

        }
        try {
            const res = await fundwallet(data).unwrap()
            if (res) {
                // console.log(res)
                if (res.btc_now) {
                    let verify = await verifyBTC(res.address).unwrap()



                    await bitcoinCallback(
                        {


                            "addr": verify.addr,
                            "txid": verify.txid,
                            "value": verify.value,
                            "status": verify.status,


                        }
                    ).unwrap()


                    window.location.href = res.url;


                }

                else {
                    window.location.href = res.url;
                    // switch (wallet.payment.paymentType) {
                    //     case "Squadco":
                    //         await squadcoCallback(res).unwrap()
                    //         break;
                    //     case "Flutterwave":
                    //         await flutterCallback(res).unwrap()
                    //         break;
                    //     case "PayStack":
                    //         await paystackCallback(res).unwrap()
                    //         break;







                    // }
                }
            }

















        } catch (error: any) {
            console.log(error)
            toast.error('An error occured')





        }



    }
    const {
        setCurrentPagename, setBgContentColor, setBgSideMenuColor, setBgTopMenuColor
    } = useContext(DashboardContext)

    const [open, setOpen] = React.useState<boolean>(false)

    useEffect(() => {
        setCurrentPagename('Fund Wallet')
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgContentColor ? setBgContentColor("#FAFAFA") : null
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null

    }, [setBgContentColor, setBgSideMenuColor, setCurrentPagename, setBgTopMenuColor])
    const [next, setNext] = React.useState<string>('1')

    const handleNext = () => {
        if (next === "1") {
            setNext("2")
        }




    }

    const handleBack = () => {
        setNext("1")
    }


    return (
        <>
            {/* {url ? (
                <iframe
                    id='iframe'
                    allowFullScreen
                    src={url}
                    title='pay now'
                    width="100%"
                    height="100%"
                    style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999 }}
                ></iframe>
            ) : null} */}

            {
                isLoading || isLoadingFlutter || isLoadingPaystack || isLoadingSquadco || isLoadingBTC || isLoadingCallback ? <LoadingSpinner /> : null
            }
            <main className={'py-[1.438rem] md:px-[2.25rem] px-[1.25rem] '} >
                {next === "2" ? <div onClick={handleBack}>
                    <BackButton text={"Payment options"} link={'/fund-wallet'} />
                </div> : null}



                {
                    next === "2" ? <PaymentOptions handleClick={onClick} /> : <ConfirmFund handleClick={handleNext} />
                }

            </main>
            {
                isSuccess || isFlutterSuccess || isPaystackSuccess || isSquadcoSuccess ? <Modal handleClose={() => setOpen(false)}
                    open={isSuccess}>
                    <LoginSucess message={<>Your have successfully funded your wallet with<span>  {formatLocalCurrency(wallet.amountToFund)} </span> </>} title={'Wallet Funding Successful'} buttonText={'Dashboard'} />



                </Modal> : null
            }
        </>
    )
}


export default FundWalletContainer;