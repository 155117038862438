import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    cableProviders : [],
    cableDetails: [],
}


const cableSlice = createSlice({
    name : "cable",
    initialState,
    reducers : {
        setCableProviders : (state, action) => {
            state.cableProviders = action.payload
        },
        setCableDetails : (state, action) => {
            state.cableDetails = action.payload

        }
    }

})

export const {setCableProviders , setCableDetails} = cableSlice.actions
export default cableSlice.reducer
