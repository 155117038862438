import { authApi } from "app/ApiSlice";

const BvnApiSlice = authApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyBvn: builder.mutation({
      query: (body) => ({
        url: `/v1/bvn/verify`,
        method: "POST",
        body,
      }),
    }),
    getBvn: builder.query({
      query: (pin) => ({
        url: `/v1/bvn/display?pin=${pin}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useVerifyBvnMutation, useGetBvnQuery } = BvnApiSlice;
