import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    educationProviders : [],
    educationDetails: [],
}


const educationSlice = createSlice({
    name : "education",
    initialState,
    reducers : {
        setEducationProviders : (state, action) => {
            state.educationProviders = action.payload
        },
        setEducationDetails : (state, action) => {
            state.educationDetails = action.payload

        }
    }

})

export const {setEducationProviders , setEducationDetails} = educationSlice.actions
export default educationSlice.reducer
