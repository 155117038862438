import React from 'react'
import style from './form.module.scss'
import {useAppDispatch , useAppSelector} from 'hooks/redux'
import { setAddBeneficiary } from 'features/Services/ServicesSlice'


interface ConfirmFormProps {
    data: any[]
    src: string
    saveBeneficiary? : boolean
    handleSaveBeneficiary? : ()=> void


}



const ConfirmForm: React.FC<ConfirmFormProps> = ({ data, src , saveBeneficiary , handleSaveBeneficiary }) => {
    const dispatch = useAppDispatch()
    const {add_beneficiary} = useAppSelector(state => state.services)

  const   handleAddBeneficiary = ()=> {
    dispatch(setAddBeneficiary(!add_beneficiary))

    }
    return (
        <div className="grid lg:place-content-center mt-[2.5rem]">
            <main className={style.confirm}>
                <div className='w-full grid place-content-center pb-[1.5rem]'>
                <img src={src} alt='confirm' className='w-[5rem] h-[3.75rem]' />
                </div>



                <section className={style.confirm__container}>
                    {
                        data?.map((item: any, index: number) => {
                            let lastIndex = data?.length - 1
                            return (
                                <div key={index} className={style.confirm__item}>
                                    <p  className={
                                        lastIndex === index ? style.confirm__item__last : ''
                                    }>{item.title}</p>
                                    <h3>{item.value}</h3>
                                </div>
                            )


                        })
                    }
                </section>

            </main>
            {
                    handleSaveBeneficiary && saveBeneficiary?
                    <div className={style.confirm__save} onClick={handleAddBeneficiary}>

                        <h3>
                            Save Beneficiary
                        </h3>
                        <img className='w-[2.75rem] h-[1.5rem]'
                        src = {add_beneficiary ? "/Services/toggleOn.svg" : "/Services/toggleOff.svg"} alt='save beneficiary' />

                    </div> : null
                }
        </div>
    )

}



export default ConfirmForm
