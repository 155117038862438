import { authApi } from "app/ApiSlice";

const GiftCardApiSlice = authApi.injectEndpoints({
  endpoints: (builder) => ({
    getGiftCardProducts: builder.query({
      query: (page) => ({
        url: `/v1/gift/products?page=${page}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),

      providesTags: ["gift"],
    }),
    searchGiftCardProducts: builder.query({
      query: (name) => ({
        url: `/v1/gift/products?name=${name}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),

      providesTags: ["gift"],
    }),

    getGiftCardProduct: builder.query({
      query: (id) => ({
        url: `/v1/gift/product?product_id=${id}`,
        method: "GET",

        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      }),
    }),

    buyGiftCard: builder.mutation({
      query: (data) => ({
        url: `/v1/gift/orders`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetGiftCardProductsQuery,
  useGetGiftCardProductQuery,
  useBuyGiftCardMutation,
  useSearchGiftCardProductsQuery,
} = GiftCardApiSlice;
