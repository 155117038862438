import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Grid,
  Container,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ErrorPageProps {
  title: string;
  description: string;
  svgImage: string;
}

const Error: React.FC<ErrorPageProps> = ({ title, description, svgImage }) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  return (
    <Flex justify={"center"} h={"90vh"} align={"center"}>
      <Container
        px={{ base: 4, md: 8 }}
        py={8}
        border={"2px solid"}
        borderRadius={"md"}
        borderColor={"red.600"}
        mt={"2rem"}
        boxShadow={"lg"}
      >
        <Grid gap={"2rem"} placeItems={"center"}>
          <Image
            src={svgImage}
            alt="Error"
            boxSize={{ base: "100px", md: "150px" }}
            mb={6}
          />
          <Heading
            as="h1"
            size={{ base: "xl", md: "2xl" }}
            mb={4}
            textAlign={"center"}
          >
            {title}
          </Heading>
          <Text fontSize={{ base: "md", md: "xl" }}>{description}</Text>
          <Button colorScheme="red" onClick={handleGoBack} mt={8}>
            Dashboard
          </Button>
        </Grid>
      </Container>
    </Flex>
  );
};

export default Error;
