import React, { useEffect, useState } from "react";
import CreditCard from "components/creditcard/CreditCard";
import EmptyCard from "components/creditcard/EmptyCard";
import { useAppSelector } from "hooks/redux";

// const cardData : any = [
// {
//     accountNumber: '0216791399',
//     bankName: 'Providus Bank',
//     name: "Adewolu Olayemi"
// },
// {
//     accountNumber: '0216791399',
//     bankName: 'GTB Bank',
//     name: "Adewolu Olayemi"
// }
// ]
const CardManagement = () => {
  const [cardData, setCardData] = useState<any[]>([]);
  const { user = {} } = useAppSelector((state: any) => state.auth as any);

  useEffect(() => {
    if (user?.data?.virtual_account) {
      setCardData(user.data.virtual_account);
    }
  }, [user]);

  const [index, setIndex] = React.useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // setIndex(indx => indx === 0 ? 1 : indx - 1)
      if (user?.data?.virtual_account?.length > 1) {
        setIndex((indx) => (indx === 0 ? 1 : indx - 1));
      } else {
        setIndex(0);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return cardData?.length < 1 ? (
    <EmptyCard />
  ) : (
    <div className="">
      <CreditCard data={cardData[index]} />

      <div className="flex gap-[0.438rem] my-0 mx-[auto] w-full justify-center pt-[0.575rem]">
        {cardData.map((item: any, ind: number) => {
          return index === ind ? (
            <svg
              key={item}
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#246BFD" />
            </svg>
          ) : (
            <svg
              key={item}
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill="#929EAE" />
            </svg>
          );
        })}
      </div>
    </div>
  );
};

export default CardManagement;
