import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    electricityProviders: [], electricityService: {} , electricityDetails: {} , tokenDetails: {}
}

const electricityServiceSlice = createSlice({
    name: 'electricityService',
    initialState,
    reducers: {
        setElectricityProviders: (state, action) => {
            state.electricityProviders = action.payload;
        },
        setElectricityService: (state, action) => {
            state.electricityService = action.payload;

        },
        setElectricityDetails: (state, action) => {
            state.electricityDetails = action.payload;


        },
        setTokenDetails: (state, action) => {
            state.tokenDetails = action.payload;



        }

    }

})

export const { setElectricityProviders , setElectricityService , setElectricityDetails , setTokenDetails } = electricityServiceSlice.actions;
export default electricityServiceSlice.reducer;