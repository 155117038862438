import { authApi } from 'app/ApiSlice'


const ProfileApiSlice = authApi.injectEndpoints({

    endpoints: (builder) => ({
        profile: builder.query({
            query: (id) => ({
                url: `/v1/profile/user?id=${id}`,
                method: "GET"
            }),
            providesTags: ['Profile']

        }),
        updateProfile: builder.mutation({
            query: (data) => ({
                url: '/v1/profile/update',
                method: "POST",
                body: data

            })
            , invalidatesTags: ['Profile']

        }),
        getRewards: builder.query({
            query: () => ({
                url: '/v1/profile/reward_wallet',
                method: "GET"
            })
            , providesTags: ['Rewards']
        }),
        redeemReward: builder.mutation({
            query: (data) => ({
                url: '/v1/profile/redeem_wallet',
                method: "POST",
                body: data
            })
            , invalidatesTags: ['Rewards']
        }),


    })

})


export const { useProfileQuery , useUpdateProfileMutation , useGetRewardsQuery , useRedeemRewardMutation } = ProfileApiSlice