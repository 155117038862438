import style from './nameinfo.module.scss'
import Avartar from '../nameavartar/Avartar'
import React from 'react'

const NameInfo = ({
    borderRadius , title , description , image
}: {
borderRadius? :  'five' ,
    title : string ,
    description : string ,
    image : string
})=> {

return(
    <section className={style.nameinfo}>
        <Avartar radius={borderRadius} image={image} />
        <article>
        <h1>
            {title}
        </h1>
        <p>
            {description}
        </p>
        </article>

    </section>
)


}

export default NameInfo;