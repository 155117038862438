import { authApi } from "app/ApiSlice";


const EducationApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
    //Education Endpoint
    getEducationProvider: builder.query({
        query: () => `/v1/bill/education/provider`,


    }),

    selectEducationProvider: builder.query({
        query: (provider) => ({
            url: `/v1/bill/education/service?service_id=${provider}`,
            method: 'GET',

        }),
    }),

    selectEducationProviderByService : builder.query({
        query: ({name , id}) => ({
            url : `/v1/bill/education/service?name=${name}&service_id=${id}`,
            method : 'GET',
        })
    }),


    buyEducation: builder.mutation({
        query: (education) => ({
            url: `/v1/bill/education/buy`,
            method: 'POST',
            body: education
        })
    }),

    verifyEducation: builder.mutation({
        query: (education) => ({
            url: `/v1/bill/education/verify`,
            method: 'POST',
            body: education
        })
    }),



})
})

export const {useGetEducationProviderQuery , useSelectEducationProviderQuery ,
     useBuyEducationMutation , useSelectEducationProviderByServiceQuery,
     useVerifyEducationMutation
    } = EducationApiSlice