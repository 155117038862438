import SelectWallet from "./SelectWallet";
import WalletTransferForm from "./WalletTransferForm";
import { useState, useContext, useEffect, useRef } from "react";
import style from "./wallettransfer.module.scss";
import BackButton from "components/buttons/backbutton/BackButton";
import React from "react";
import DashboardContext from "context/Dashboard/DashboardContext";

const WalletTransfer = () => {
  const [wallet, setWallet] = useState<boolean>(true);
  const handleSelectWallet = () => {
    setWallet(!wallet);
  };
  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  useEffect(() => {
    setCurrentPagename("Wallet Transfer");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FAFAFA") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);
  const [next, setNext] = useState<"1" | "2" | "3">("1");
  const handleClick = () => {
    if (next === "1") {
      return setNext("1");
    } else if (next === "2") {
      return setNext("1");
    } else if (next === "3") {
      return setNext("2");
    }
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, [next]);

  return (
    <section ref={scrollContainerRef}>
      {next === "1" ? null : (
        <div className={style.back__button} onClick={handleClick}>
          {" "}
          <BackButton
            text={next === "2" ? "Confirm transfer" : "Enter transaction PIN"}
          />{" "}
        </div>
      )}
      <div>
        {wallet ? (
          <WalletTransferForm next={next} setNext={setNext} />
        ) : (
          <SelectWallet
            wallet={wallet}
            handleSelectWallet={handleSelectWallet}
          />
        )}
      </div>
    </section>
  );
};

export default WalletTransfer;
