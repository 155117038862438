import {createSlice } from '@reduxjs/toolkit'

const initialState :  {
    insuranceProviders: any
    provider: {
        name: string,
        image: string,
        serviceID:string,
        option?: string

    },
    providerDetails: any,
    formData: any
    insuranceDetails: any
} = {
    insuranceProviders: [],
    provider: {
        name: '',
        image: '',
        serviceID:'',
        option: ''

    },
    providerDetails: [],
    formData: {},
    insuranceDetails: {}


}


const insuranceServiceSlice = createSlice({
    name: 'insurance',
    initialState,
    reducers: {
        setInsuranceProviders: (state, action) => {
            state.insuranceProviders = action.payload
        },
        setInsuranceProvider: (state, action) => {
            state.provider = action.payload

        },
        setProviderDetails: (state, action) => {
            state.providerDetails = action.payload

        },
        setFormData: (state, action) => {
            state.formData = action.payload

        },
        setInsuranceDetails: (state, action) => {
            state.insuranceDetails = action.payload

        }
    },
})


export default insuranceServiceSlice.reducer
export const {setInsuranceProviders , setInsuranceProvider , setProviderDetails , setFormData , setInsuranceDetails} = insuranceServiceSlice.actions