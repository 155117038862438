import { createSlice } from "@reduxjs/toolkit";


    type ProfileState = {
        profile : any,
        rewardsData: any
    }


    const initialState : ProfileState = {
        profile : null,
        rewardsData: {}
    }
const ProfileSlice= createSlice({
    initialState  ,

    name : "profile",
    reducers : {
        setProfile : (state, action) => {
            state.profile = action.payload

        },
        setRewardsData : (state , action) => {
            state.rewardsData = action.payload
        }

    }

})

export const { setProfile  , setRewardsData} = ProfileSlice.actions;
export default ProfileSlice.reducer;