function formatTimestamp(timestamp: string): string {
    // Create a Date object from the input timestamp
    const date = new Date(timestamp);

    // Helper function to get the month name
    function getMonthName(monthIndex: number): string {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      return months[monthIndex];
    }

    // Helper function to pad a number with leading zeros
    function padWithZero(number: number): string {
      return number.toString().padStart(2, '0');
    }

    // Format the date as '12 Apr 2023 at 04:30'
    return `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()} at ${padWithZero(date.getHours())}:${padWithZero(date.getMinutes())}`;
  }

export default formatTimestamp