import React from 'react'
import HelpContainer from 'container/Help&Support/HelpContainer'
import OtherDashboard from 'container/dashboard/OtherDashboard'

const HelpPage = () => {
  return (
    <OtherDashboard>
        <HelpContainer />
    </OtherDashboard>
  )
}

export default HelpPage