import { useState, useEffect } from 'react';

function useUltimateSearch(data, search = '', debounceTime = 500) {


  const [myData, setMyData] = useState(data);


  useEffect(() => {
    // Implementing debouncing with setTimeout
    const debounceTimer = setTimeout(() => {
      const filteredData = searchArrayInArrayOfObjects(data, search);
      setMyData(filteredData);
    }, debounceTime);


    return () => clearTimeout(debounceTimer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data, debounceTime, ]);

  // Helper function to search through objects
  function searchArray(obj, searchTerm) {
    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'object') {
        // Recursively search through nested objects or arrays
        if (searchArray(value, searchTerm?.trim()?.toLowerCase())) {
          return true;
        }
      } else if (
        typeof value === 'string' &&
        value?.trim()?.toLowerCase().includes(searchTerm.trim()?.toLowerCase())
      ) {
        // Check if the string includes the search term
        return true;
      }
    }

    return false;
  }




  // Helper function to search through an array of objects
  function searchArrayInArrayOfObjects(array, searchTerm) {
    const results = [];

    array?.forEach((obj) => {
      if (searchArray(obj, searchTerm)) {
        results.push(obj);
      }
    });

    return results;
  }



  return { myData, search,};
}

export default useUltimateSearch;