import React from 'react';
import style from './category.module.scss';


import { Link  , useLocation} from 'react-router-dom';




const Category = ({
    bgColor, icon, title , sub
}: {
    bgColor: string, icon: any, title: string , sub?: boolean

}) => {
    const pathname = useLocation().pathname;


    return (
        <Link className={style.category} to={`${pathname}?category=${title.toLowerCase()}`}>
            <div className={style.category__image} style={{ backgroundColor: bgColor }}>
                <img src={icon} alt="icon" width={30} height={30} />
            </div>
            <h3>{title}</h3>
        </Link>
    )

}


export default Category;