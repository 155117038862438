import React from 'react'
import Banner from 'components/GiftCard/Banner/Banner'
import CardList from 'components/GiftCard/Card/CardList'
import style from './dashboard.module.scss'


const data: {
    image_path: string
    name : string
     price: string
}[] = []


const GiftCardDashboard = ({
    handleClick
}: {
    handleClick: (value: {
        image_path: string
        name : string
         price: string
    }) => void
}) => {
    return (
        <section className={style.dashboard}>

            <Banner />
            <CardList handleClick = {handleClick} data={data} fullData={{}} />

        </section>
    )

}

export default GiftCardDashboard