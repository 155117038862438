


import React from 'react';
import {ReactNode} from 'react'



const ContainerWrapper = ({
    children
}: {
    children: ReactNode
}) => {
    return (
        <main className="grid h-[100vh]  max-w-[100vw] w-full overflow-x-hidden m-0  lg:grid-cols-2 bg-white">
            {children}
            <section className="hidden lg:block w-full h-[100vh] 2xl:max-h-[100vh] xl:min-h-[100vh]">
                <img src="/signIn.png" alt="jarapay login" width={675} height={900} className="w-full h-full max-h-[100%] max-w-full" />
            </section>


        </main>
    )


}

export default ContainerWrapper;