import React from 'react'
import style from './actiontext.module.scss'



const ActionText: React.FC<actionTextprop> = ({
    text, onClick
}) => {
    return (
        <p onClick={onClick} className={`${style.action__text} hover:text-primary`} >

            {text}
        </p>
    )
}

export default ActionText