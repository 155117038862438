function formatDateToDDMMYYYY(date: Date): string {
    if( date === undefined || date === null){ return '';}

    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return  `${year}/${month}/${day}`;
  }


  export default formatDateToDDMMYYYY;




