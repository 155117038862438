import { createSlice  } from "@reduxjs/toolkit";


const  initialState = {
    internetProviders : []
}


const internetSlice = createSlice({
    name : "internetSlice",
    initialState,
    reducers : {
        setInternetProviders : (state, action) => {
            state.internetProviders = action.payload;

        }
    }
})


export default internetSlice.reducer

export const {setInternetProviders } = internetSlice.actions
