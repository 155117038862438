import { authApi } from "app/ApiSlice";


const internetSerciceApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
        getInternetProviders: builder.query({
            query: () => "/v1/bill/internet/provider"
        }),
        getInternetService: builder.query({
            query: (id) => `/v1/bill/internet/service?service_id=${id}`
        }),
        getInternetVariant : builder.query({
            query: ({name  , service_id}) => `/v1/bill/internet/service?name=${name}&service_id=${service_id}`
        }),
        verifyInternetService : builder.mutation({
            query: (data) => ({
                url: "/v1/bill/internet/verify",
                method: "POST",
                body: data
            })
        }),
        payInternetService : builder.mutation({
            query: (data) => ({
                url: "/v1/bill/internet/buy",
                method: "POST",
                body: data
            })

        }),
    }),

})




export const { useGetInternetProvidersQuery , useGetInternetServiceQuery  ,
     useGetInternetVariantQuery , useVerifyInternetServiceMutation,
     usePayInternetServiceMutation
 } = internetSerciceApiSlice;