import React, { useState, useContext } from "react";
import GiftCardDashboard from "./Dashboard";
import DashboardContext from "context/Dashboard/DashboardContext";
import GiftCardShop from "components/GiftCard/Shop/Shop";
import BackButton from "components/buttons/backbutton/BackButton";
import CartContainer from "components/GiftCard/Cart/CartContainer";
import PinForm from "../Form/EnterPin";
import ServicesPayment from "components/paymentOptions/ServicesPayment";
import { useBuyGiftCardMutation } from "features/Services/GiftCardService/GiftCardApiSlice";
import { useAppSelector  , useAppDispatch} from "hooks/redux";
import { useForm } from "react-hook-form";
import LoadingSpinner from "components/Spinner/Spinner";
import CurrencyConverter from "utils/CurrencyConverter";
import { setSuccess } from "features/Services/ServicesSlice";
import toast from "react-hot-toast";

function GiftCardContainer() {
  const [value, setValue] = useState<{
    image_path: string;
    name: string;
    price: string;
  }>({
    image_path: "",
    name: "",
    price: "",
  });

  const [step, setStep] = React.useState<"1" | "2" | "3" | "4" | "5">("1");
  const [steps, setSteps] = React.useState<string[]>(["1"]);
  const { setCurrentPagename } = useContext(DashboardContext);
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth) as any;
  const { third_party_email, cart } = useAppSelector(
    (state) => state.giftCardService
  );
  const { pin } = useAppSelector((state) => state.services);
  const order_items = cart.map((item: any) => {
    return {
      product_id: +item.id,
      quantity: parseInt(item.quantity),
      recipients: [
        {
          channel: "EMAIL",
          contact: user?.data?.email,
        },
      ],
    };
  });
  let _name = cart?.map((item: any) => {
    return item.name;
  });
  //convert to string with comma
  let name = _name.toString();

  const quantities = cart?.reduce((acc: any, item: any) => {
    return acc + parseInt(item.quantity);
  }, 0);

  const total = cart.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);

  const { handleSubmit } = useForm({});

  const [buyGiftCard, { isLoading }] = useBuyGiftCardMutation();

  const onSubmit = async () => {
    const data = {
        order_items,
        variation_amount: total,
        third_party : third_party_email ? true: false ,
        pin:  +pin?.join(',')?.replace(/,/g, ''),
        "add_beneficiary":false,
        "slug_id":1,
        third_party_email,
        name,
        quantities,

      };
      try {
        const res = await buyGiftCard(data).unwrap();
        if (res.status) {
            dispatch(setSuccess(true))


        }

      } catch (error : any) {
        console.log(error)
        toast.error(error?.data?.error ? error?.data?.error: 'Something went wrong. Please try again' )

      }

  };

  const handleNext = () => {
    setSteps([...steps, step]);

    if (step === "1") {
      setStep("2");
    }
    if (step === "2") {
      setStep("3");
    }
    if (step === "3") {
      setStep("4");
    }
    if (step === "4") {
      setStep("5");
    }
  };

  const handleBack = () => {
    setSteps(steps.slice(0, -1));

    if (step === "1") {
      setStep("1");
    }

    if (step === "2") {
      setStep("1");
    }
    if (step === "3") {
      setStep("2");
    }
    if (step === "4") {
      setStep("3");
    }
    if (step === "5") {
      setStep("4");
    }
  };

  if (step === "1") {
    setCurrentPagename("card");
  }
  let text = "All Services";
  if (step === "2") {
    text = "Shop";
    setCurrentPagename(value.name);
  }
  if (step === "3") {
    text = value.name;
    setCurrentPagename("Checkout");
  }
  if (step === "4") {
    text = "Checkout";
    setCurrentPagename("Payment Options");
  }
  if (step === "5") {
    text = "Payment Options";
    setCurrentPagename("Transaction PIN");
  }

  const handleClick = (value: {
    image_path: string;
    name: string;
    price: string;
  }) => {
    setValue(value);
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? <LoadingSpinner /> : null}
      {step === "1" ? <GiftCardDashboard handleClick={handleClick} /> : null}
      <div className="lg:pt-[0.75rem] lg:pl-[2.5rem] lg:pr-[2.25rem] lg:pb-[3.25rem] px-[0.25rem] py-[2rem]">
        <div onClick={handleBack}>
          {step !== "1" ? (
            <BackButton text={text} link="/services/?category=gift card" />
          ) : null}
        </div>
        {step === "2" ? (
          <GiftCardShop
            handleNext={() => handleNext()}
            data={value}
            handleClick={(value: {
              image_path: string;
              name: string;
              price: string;
            }) => setValue(value)}
          />
        ) : null}
        {/* <CartContainer /> */}
        {step === "3" ? (
          <CartContainer onClick={handleNext} setStep={setStep} />
        ) : null}
        {step === "4" ? <ServicesPayment onClick={handleNext} /> : null}

        {step === "5" ? (
          <div className="mt-[2.5rem] 2xl:mt-[3.125rem]">
            <PinForm

              amount={CurrencyConverter(total)}

              successMessage={{
                title: "Giftcard Purchase Successful",
                message: (
                  <>
                    Your have successfully purchased Gift Card of{" "}
                    <span>{CurrencyConverter(total)} </span>
                  </>
                ),
                buttonText: "Dashboard",
                link: "/services",
              }}
            />
          </div>
        ) : null}
      </div>
    </form>
  );
}

export default GiftCardContainer;
