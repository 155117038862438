import React from 'react'
import OtherDashboard from 'container/dashboard/OtherDashboard'
import ElectricityToken from 'container/ElectricityToken/ElectricityToken'

const ElectricityTokenPage = () => {
  return (
<OtherDashboard>
    <ElectricityToken />
</OtherDashboard>
  )
}

export default ElectricityTokenPage