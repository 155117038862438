import ContainerWrapper from "../container/Container";

import Spinner from "components/Spinner/Spinner";
import Input from "components/form/Auth/Input";

//import InputDropdown from "components/form/Auth/InputDropdown";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useGetSecurityQuestionQuery,
  useAnswerSecurityQuestionMutation,
} from "features/Auth/AuthApiSlice";
import { Toaster, toast } from "react-hot-toast";
import { Grid } from "@chakra-ui/react";
import Dropdown from "components/form/DropdownWithImage";


// interface IDefaultValues {
//     id : string;
//     question_id : string;
//     answer : string;
// }
const SecurityRegistration = () => {
  const navigate = useNavigate();

  const id = localStorage.getItem("id");
  const [item, getItem] = useState<any>({});

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      id,
      question_id: item?.id,
      answer: "",
    },
  });

  const { data } = useGetSecurityQuestionQuery({});


  const options = data?.data?.map((item: any) => {
    return {
      label: item.question,
      value: item.id,
    };
  });








  const [answerSecurityQuestion, { isLoading }] =
    useAnswerSecurityQuestionMutation();

  const handleSubmitHandler = async (data: any) => {


    const formData = new FormData();
    formData.append("id", id as string);
    formData.append("question_id", data?.question?.value);
    formData.append("answer", data.answer);

    try {

      const res = await answerSecurityQuestion(formData).unwrap();

      localStorage.setItem("data", JSON.stringify(res));

      if (res.status) {
        navigate("/sign-up/pin-registration");
      }
    } catch (error: any) {
      if (error) {
        toast.error("An error occured, Please try again");
      }
    }
  };

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <Grid
        as={"form"}
        gap={{
          base: "2rem",
          lg: "4rem",
          "2xl": "6rem",
        }}
        maxH={'100vh'}
        overflow={"hidden"}


        onSubmit={handleSubmit(handleSubmitHandler)}
        placeContent={"center"}
        padding={"1rem"}
      >
        <Grid
          gap={{
            base: "1rem",
            lg: "2rem",

          }}
        >
          <img
            onClick={() => {
              navigate(-1);
            }}
            width={15}
            height={19}
            src="/Auth/Arrow.svg"
            alt="back"
            className="w-[1.5rem] h-[1.9rem] "
          />

          <div>
            <h1 className="text-[#212121] md:text-[1.875rem] text-[1.25rem] font-[700] mb-[0.5rem]">
              Security Question
            </h1>
            <h6 className="font-[400] text-[#212121] md:text-[1rem] text-[0.875rem]">
              Secure your account with security question
            </h6>
          </div>
        </Grid>
        <Grid>
          <div className="flex flex-col gap-[1.4375rem] ">
            {/* <InputDropdown
              rules={{
                required: {
                  value: true,
                  message: "Please select question",
                },
              }}
              title="Select Question"
              placeholder="select question"
              data={data}
              control={control}
              name={"question"}
              setValue={setValue}
              getItem={getItem}
            /> */}
            <Dropdown errorMessage= 'Required' name="question" control={control} options={options}   title="Select Question"
              placeholder="select question"/>
            <Input
              rules={{
                required: "Please enter answer",
                minLength: {
                  value: 2,
                  message: "Minimum of 2 characters",
                },
              }}
              name="answer"
              control={control}
              title="Enter Answer"
              placeholder="dog"
            />
          </div>
        </Grid>

        <div className="grid place-items-center md:block">
          <button
            type="submit"
            disabled={isLoading}
            className=" disabled:cursor-not-allowed  btn-primary  transition-all"
          >
            Continue
          </button>
        </div>
      </Grid>
    </ContainerWrapper>
  );
};

export default SecurityRegistration;
