

export const header = [
    "services" ,  "DATE/TIME" , "amount" , "status"
]



export const data = [


  {
    img : '/Transactions/entertainment.svg',
    name: 'Netflix Subscription',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : 'N890.90',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    status: 'Redeemed'
  },
  {
    img : '/Transactions/software.svg',
    name: 'Figma Subscription',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : '- N30000',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    status : 'Disbursed'
  },
  {
    img : '/Transactions/technology.svg',
    name: 'Bitcoin Transaction',
    company :'Apple. Inc',
    type : 'Mobile',
    amount : 'N3000',
    date : '14 Apr 2022',
    time   : 'at 8:00 PM',
    status : 'Unpaid',

  },







]