

// import Input from 'components/form/inputs/Input';
import style from './wallettransfer.module.scss'

import { useState, useContext, useEffect } from 'react'
import Form from 'components/walletTransfer/Form/Form';
import DashboardContext from 'context/Dashboard/DashboardContext';
import React from 'react';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker';
import formatCurrency from 'utils/FormatCurrency';
import PhoneNumberFormatter from 'utils/PhoneNumberFormatter';
import LoginSuccessful from 'components/successpage/LoginSuccessful';
import Modal from 'components/successpage/Modal';
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useConfirmReceiverDetailMutation, useTransferToWalletMutation } from 'features/Wallet/WalletApiSlice'
import LoadingSpinner from 'components/Spinner/Spinner';
import { toast, Toaster } from 'react-hot-toast'
import { setWalletTranferDetails } from 'features/Wallet/WalletSlice';





const WalletTransferForm = ({
    next, setNext
}: {
    next: "1" | "2" | "3" | "4", setNext: any
}) => {
    const { setCurrentPagename } = useContext(DashboardContext)



    const [details, setDetails] = useState({
        amount: '',
        pin: '',
        mobile: ''

    })
    
    const [pin, setPin] = useState<string[]>([])

    useEffect(() => {
        setCurrentPagename('Wallet Transfer')
    }, [setCurrentPagename])










    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        let formatter: string







        if (name === 'amount') {
            formatter = `${formatCurrency(value)}`
        }
        else {
            formatter = PhoneNumberFormatter(value)
        }

        if (value === "NGN 0" || formatter === "NGN 0") {
            formatter = ''
        }

        setDetails(prev => (
            {
                ...prev, [
                    name
                ]: formatter
            }
        ))

    }
    const [nextLevels, setNextLevels] = useState<string[]>([
        "1"
    ])



    const nextStep = () => {
        //add new strings to nextLevels array
        setNextLevels([...nextLevels, "new"])

        if (next === '1') {
            setNext('2')
        }
        else if (next === '2') {
            setNext('3')

        }
        else if (next === '3') {
            setNext('4')

        }

    }
    //Get user
    const { user = {} } = useAppSelector(state => state.auth as any)
    //get current wallet info
    const wallet = useAppSelector(state => state.wallet as any)

    //Get user wallet
    const [confirmReceiverDetail, { isLoading }] = useConfirmReceiverDetailMutation()
    //fund wallet
    const [transferToWallet, { isLoading: isTransferLoading }] = useTransferToWalletMutation()
    const dispatch = useAppDispatch()



    const { handleSubmit
    } = useForm()

    const onSubmit = async () => {
        const amount = details.amount?.replace(/[^\d.]/g, '');
        //remove the first character and add +234
        const receiver_phone_number = details.mobile;
        const sender_wallet_id = user?.data?.wallet?.id

    if(next === "1")  {  try {
            const res = await confirmReceiverDetail({
                amount, receiver_phone_number, sender_wallet_id
            }).unwrap()
            if (res) {
                dispatch(setWalletTranferDetails(res))
                nextStep()
            }



        } catch (error: any) {

            if (error?.status === 403) {

                if (error?.data?.error) {
                    toast.error(error?.data?.error)
                }
            }


        }}

        else if(next === "3") {


            try {
                const res = await transferToWallet({
                    "sender_wallet_id": parseInt(user?.data?.wallet?.id),
                    "receiver_wallet_id": parseInt(wallet.walletTranferDetails.data?.receiver?.id),
                    "amount": parseInt(wallet.walletTranferDetails?.data?.amount),
                    "pin": pin.join(""),
                  }

                  ).unwrap()

                  if(res){
                    dispatch(setWalletTranferDetails({}))
                    nextStep()

                  }

            }
            catch(error: any){
                if( error?.originalStatus === 429){
                    toast.error('Too many requests, please try again later')
                }
                else if (error?.status === 403) {

                    if (error?.data?.error) {
                        toast.error(error?.data?.error)
                    }
                }
                else if(error?.data?.error){
                    toast.error(error?.data?.error)
                }
                else{
                    toast.error('An error occured, please try again')
                }
            }


        }
        else{
            nextStep()
        }







    }





    return (
        <>
            <Toaster toastOptions={{

                style: {
                    fontSize: "18px",
                }
            }} />
            {

                isLoading || isTransferLoading ? <LoadingSpinner /> : null
            }
            <form onSubmit={handleSubmit(onSubmit)} className={style.wallettransfer} >
                {next === '1' ?
                    <h2 >
                        Transfer funds from your wallet to another wallet on JaraPay
                    </h2> : null}
                <div>
                    {
                        next === "3" ? <h1 className={style.wallettransfer__pin}>Enter Your Pin</h1> : null
                    }
                    {
                        next === "3" ? <h6 className={style.wallettransfer__pin__text}>
                            Please enter your transaction PIN to confirm transfer
                        </h6 > : null
                    }
                </div>



                <section className={style.wallettransfer__middle}>
                    {next !== "3" ? <ProgressTracker levels={["Receiver Details", "Confirm Details", "Enter PIN"]} steps={nextLevels} /> : null}
                    <Form setPin={setPin} onChange={onChange} next={next} details={details} amount={''} />
                </section>



                <button type={'submit'} disabled={((details.amount === '' || details.mobile === '')&& (next !== "2" && next !== "3") )|| (details.pin[3] === '' && next ==="3") || isLoading || isTransferLoading} className='  btn-primary  transition-all disabled:cursor-not-allowed' >
                    {
                        next === '1' ? 'Continue' : "Confirm Transfer"
                    }
                </button>





            </form >

            {
                next === "4" ? <Modal handleClose={() => setNext("3")} open={next === "4"}>
                    <LoginSuccessful link={'/'} buttonText='Go Home' title='Transfer Successful' message={
                        <>Your transfer from your wallet to <span> {wallet.walletTranferDetails?.data?.full_name}</span>was successful.</>
                    } />
                </Modal> : null
            }
        </>
    )
}


export default WalletTransferForm;