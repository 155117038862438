import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptions: [],
    supscription: {},
    postData: {}
}


const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        getSubscriptions: (state, action) => {
            state.subscriptions = action.payload
        },
        getSubscription: (state, action) => {
            state.subscriptions = action.payload
        },

        setPosttData: (state, action) => {
            state.postData = action.payload
        },
    },
})



export const {
    getSubscription, getSubscriptions, setPosttData
} = subscriptionSlice.actions


export default subscriptionSlice.reducer