import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServicesInput from "components/form/Services/Input";

import { useAppSelector, useAppDispatch } from "hooks/redux";

import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useSelectInsuranceProviderQuery } from "features/Services/Insurance/InsuranceApiSlice";
import DateInput from "components/form/Services/DateInput";
import FileInput from "components/form/Services/FileInput";

const ThirdPartyForm = ({
  control,
  setValue,
  provider,
}: {
  control: any;
  setValue: any;
  provider: any;
}) => {
  const { data: insuranceType, isLoading } = useSelectInsuranceProviderQuery(
    { ...provider },
    {
      skip: !provider,
    }
  );
  const [insurancePackageOptions, setInsurancePackageOptions] = useState<any[]>(
    []
  );
  const [extraFields, setExtraFields] = useState<any[]>([]);

  useEffect(() => {
    if (insuranceType) {
      setExtraFields(insuranceType?.extra_fields_insurance?.content);
      setInsurancePackageOptions(
        insuranceType?.insurance?.content?.varations?.map((item: any) => {
          return {
            ...provider ,
            ...item,
            label: item?.name,
            value: item?.variation_code,
            image: item?.image,

          };
        })
      );
    }
  }, [insuranceType]);

  

  return (
    <form className="flex items-center justify-center relative w-full">
      <div className={style.form}>
        <DropdownWithImage
          rules={{ required: "Field cannot be empty." }}
          control={control}
          options={insurancePackageOptions}
          name="variation"
          defaultValue={insurancePackageOptions[0]}
          title={"Insurance Package"}
          placeholder={"select option"}
        />
        <ServicesInput
          name="phone"
          control={control}
          rules={{ required: "Field cannot be empty." }}
          defaultValue=""
          label={"Phone Number"}
          placeholder={"phone number"}
          onChange={() => {}}
          value={""}
          type={"number"}
          maxLength={13}
        />
        {/* Address */}
        <ServicesInput
          control={control}
          rules={{
            required: "Field cannot be empty.",
          }}
          name="Contact_Address"
          label="Address"
          placeholder="address"
          type={""}
        />

        {extraFields
          ? extraFields?.map((item: any, index: number) => {
              return item?.optionName === "Year_of_Make" ? (
                <DateInput
                  key={index}
                  name={item?.optionName}
                  control={control}
                  rules={{ required: "Field cannot be empty." }}
                  defaultValue=""
                  label={item?.optionLabel}
                  placeholder={item?.optionLabel?.toLowerCase()}
                />
              ) : item?.optionName === "Upload_Document" ? (
                <FileInput
                  key={index}
                  name={item?.optionName}
                  control={control}
                  rules={{ required: "Field cannot be empty." }}
                  defaultValue=""
                  label={item?.optionLabel}
                  placeholder={item?.optionLabel?.toLowerCase()}
                />
              ) : (
                <ServicesInput
                  key={index}
                  name={item?.optionName}
                  control={control}
                  rules={{ required: "Field cannot be empty." }}
                  defaultValue=""
                  label={item?.optionLabel}
                  placeholder={item?.optionLabel?.toLowerCase()}
                  onChange={() => {}}
                  value={""}
                  type={item?.optionName === "Year_of_Make" ? "date" : ""}
                />
              );
            })
          : null}
        <ServicesInput
          name={"Engine_Number"}
          control={control}
          rules={{
            required: "Field cannot be empty.",
          }}
          label="Engine Number"
          placeholder="engine number"
          type={""}
        />
      </div>
    </form>
  );
};

export default ThirdPartyForm;
