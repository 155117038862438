import React from 'react';
import style from './cashinfo.module.scss'
import CurrencyButton from 'components/buttons/CurrencyButton/CurrencyButton'
import formatPrice from 'utils/FormatCurrency';
// import {motion , AnimatePresence} from 'framer-motion'
import {setAmountToFund} from 'features/Wallet/WalletSlice'
import {useAppDispatch} from 'hooks/redux'
import { useEffect } from 'react';



const WalletInfo= () => {
  const [value , setValue] = React.useState<string>("")
  const dispatch = useAppDispatch()



  const handleChange = (e: any) =>{
    const value = e.target.value
    setValue(formatPrice(value))



  }
  useEffect(() => {
    dispatch(setAmountToFund(value))
  }, [dispatch, value])



  return(  <section className={style.cashinfo} >
        <article>
            <CurrencyButton />
        </article>
        <input onChange={handleChange} className={style.cashinfo__input} placeholder='N0.00' value={value} />

    </section>
  )

}

  export default WalletInfo;


