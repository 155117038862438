import React from 'react'
import style from './form.module.scss'
import ProfileInput from 'components/Profile/ProfileInput'
import DateInput from 'components/form/Services/DateInput'

const Form = ({
    bvn,
    control,
    onChange,
}: {
    bvn: string,
    control: any,
    onChange: any
}) => {
    return (
        <section className={style.form}>
            <div className='flex flex-col gap-[1.625rem] border-[0.08125rem] rounded-[0.975rem] border-[#B2B3B3] md:pt-[3.5625rem] md:pb-[4.25rem] md:pl-[5.8375rem] md:pr-[5.8375rem] px-[1.25rem] py-[2.5rem] w-full'>
                <ProfileInput
                    rules={{
                        required: 'BVN is required',
                        maxLength: {
                            value: 11,
                            message: 'BVN must be 11 characters'
                        },
                        minLength: {
                            value: 11,
                            message: 'BVN must be 11 characters'
                        }
                    }}

                    maxLength={11}
                    type='text'
                    control={control}
                    name='bvn'
                    placeholder='111111111111'
                    label='Bank Verification Number'
                />
                <DateInput
                    rules={{
                        required: 'Date of birth is required',
                    }}
                    // type='date'
                    defaultValue={''}

                    name='date_of_birth'
                    control={control}
                    label={'Date of Birth'}
                    placeholder='YYYY/MM/DD'
                    dateFormat='dd-MM-yyyy'
                    showYearPicker =  {false}

                />
            </div>
        </section>
    )
}

export default Form;
