import React from "react";
import TransactionInfo from "components/nameinfo/TransactionInfo";
import style from "./transaction.module.scss";
// import Modal from 'components/successpage/Modal'
// import SuccessMessage from './SuccessMessage'
import formatLocalCurrency from "utils/formatLocalCurrency";

// {
//     img : '/Transactions/software.svg',
//     name: 'Figma Subscription',
//     company :'Apple. Inc',
//     type : 'Mobile',
//     amount : '10000',
//     date : '14 Apr 2022',
//     time   : 'at 8:00 PM',
//     reference : '123456789',
//   },
interface Prop {
  item: any;
  handleClick: () => void;
}
const FundItem: React.FC<Prop> = ({ item, handleClick = () => {} }) => {
  return (
    <>
      <div className={style.list_item}>
        <TransactionInfo
          borderRadius="five"
          title={item.payment_type}
          description={item.company}
          image={"/Services/sv-7.svg"}
        />
        <p className={style.type}>{item.payment_type}</p>
        <p
          className={`${style.amount} ${
            item.transation_type === "debit" || item.trasaction_type === "debit"
              ? style.debit
              : ""
          }`}
        >
          {`${
            item.transation_type === "debit" || item.trasaction_type === "debit"
              ? "-"
              : ""
          }${
            item.amount
              ? formatLocalCurrency(item.amount)
              : formatLocalCurrency(item?.transactions_amount)
          }`}
        </p>
        <div className={style.date_and_time}>
          <p className={style.date}>{item.date}</p>
          <p className={style.time}>{item.time}</p>
        </div>
        <p className={style.reference}>
          {item?.request_id
            ? item?.request_id
            : item?.transactions_mobile_number}
        </p>
        <button
          onClick={() => {}}
          className={`btn-primary max-w-[71px] text-[14px] font-semibold transition  ${style.btnn} ${style.btn}`}
        >
          View
        </button>
      </div>
    </>
  );
};

export default FundItem;
