import React from 'react';
import CreatePin from 'container/resetpin/CreatePin';



const CreatePinPage = () => {
    return (
        <div>
            <CreatePin />
        </div>
    )

}


export default CreatePinPage;