import React, { useState, useEffect } from "react";
import ContainerWrapper from "../container/Container";
import { useNavigate } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";
import Pin from "components/form/Auth/Pin/Pin";
import { useCreateLoginPinMutation } from "features/Auth/AuthApiSlice";
import { useForm } from "react-hook-form";
import Modal from "components/successpage/Modal";
import LoginSuccessful from "components/successpage/LoginSuccessful";
import Spinner from "components/Spinner/Spinner";
import { Grid } from "@chakra-ui/react";

// interface IDefaultValues {

//     id: string
//     login_pin: string
//     confirm_pin: string

//   }
const CreatePin = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>([]);
  const [ConfirmOtp, setConfirmOtp] = useState<string[]>([]);
  const [matchOtp, setMatchOtp] = useState<boolean>(false);
  const [createLoginPin, { isLoading, isSuccess }] =
    useCreateLoginPinMutation();

  useEffect(() => {
    if (otp.join("") === ConfirmOtp.join("")) {
      setMatchOtp(true);
    } else {
      setMatchOtp(false);
    }
  }, [otp, ConfirmOtp]);

  const { handleSubmit } = useForm({});

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    const id = localStorage.getItem("create_id");

    formData.append("id", id as string);
    formData.append("login_pin", otp.join(""));
    formData.append("confirm_pin", ConfirmOtp.join(""));

    try {
      await createLoginPin(formData).unwrap();
    } catch (error: any) {
      toast.error("Something went wrong , please try again later");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSuccess) {
        navigate("/sign-in");
      }
    }, 7000);
    return () => clearTimeout(timer);
  }, [isSuccess, navigate]);

  return (
    <ContainerWrapper>
      <Toaster
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoading ? <Spinner /> : null}
      <Grid
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        placeContent={'center'}
        gap={{

          base: "4rem",
          xl: "5rem",
          '2xl': "6rem"
        }}

      >
        <img
          onClick={() => navigate(-1)}
          width={15}
          height={19}
          src="/Auth/Arrow.svg"
          alt="back"
          className="w-[0.9375rem] h-[1.1875rem] "
        />
        <div className="">
          <h1 className="text-dark text-[1.875rem] font-semibold mb-[0.5rem]">
            Create Login PIN
          </h1>
          <h6 className="font-[400] text-[#4F4F4F] text-[1rem] ">
            Enter your new set of PIN below
          </h6>
        </div>
        {/* //!! TODO: O */}
        <div className="relative flex flex-col gap-[2rem] 2xl:gap-[3rem] md:gap-[2.125rem] ">
          <Pin myPin={setOtp} title="New Login PIN" tokenNumber={6} />
          <Pin
            myPin={setConfirmOtp}
            title="Confirm New Login PIN"
            tokenNumber={6}
          />
          <p className="text-red text-[0.75rem] absolute bottom-[-1.25rem]">
            {ConfirmOtp.join("")?.length > 0 && matchOtp === false
              ? "PIN do not match"
              : ""}
          </p>
        </div>

        <button
          disabled={
            ConfirmOtp.join("")?.length < 6 ||
            otp.join("")?.length < 6 ||
            matchOtp === false ||
            isLoading
          }
          className="  btn-primary  transition-all"
        >
          Create Pin
        </button>
      </Grid>
      {isSuccess ? (
        <Modal handleClose={() => {}} open={false}>
          <LoginSuccessful
            title="Congratulations"
            message={<>Your Login PIN Has Been Successfully Updated</>}
            buttonText="Login"
            link="/sign-in"
          />
        </Modal>
      ) : null}
    </ContainerWrapper>
  );
};

export default CreatePin;
