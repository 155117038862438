import React from "react";
import style from "./insurance.module.scss";

interface Props {
  item: any;
  handleClick: (value: string) => void;
  selected: string;
  handleSelect: (value: string) => void;
}

const SelectInsurance: React.FC<Props> = ({
  handleClick,
  selected,
  handleSelect,
  item,
}) => {
 

  const handleSelectInsurance = (value: string) => {
    handleSelect(value);
    handleClick(value);
  };

  return (
    <section
      className={`${style.item} ${
        selected === item?.name ? style.item__selected : ""
      }`}
      onClick={() => handleSelectInsurance(item?.name)}
    >
      <div className={style.image__container}>
        {selected === item?.name ? (
          <svg
            className="absolute top-[.2rem] right-[.3rem]"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="9"
              fill="#39CE8A"
              stroke="#FBFBFD"
              stroke-width="2"
            />
            <path
              d="M14.2344 8.22347L9.42252 13.0354C9.25031 13.2076 9.02744 13.2887 8.79444 13.2887C8.57157 13.2887 8.34871 13.2076 8.17649 13.0354L5.76547 10.6345C5.42103 10.2799 5.42103 9.72277 5.76547 9.37833C6.1099 9.0339 6.6772 9.0339 7.02163 9.37833L8.79444 11.1511L12.9783 6.96731C13.3227 6.62288 13.89 6.62288 14.2344 6.96731C14.5789 7.32187 14.5789 7.87904 14.2344 8.22347Z"
              fill="white"
            />
          </svg>
        ) : null}

        <img
          className="w-full h-full object-cover "
          src={item?.image}
          alt="insurance"
        />
      </div>
      <p className={`${style.item__title}`}>{item?.name}</p>
    </section>
  );
};

export default SelectInsurance;
