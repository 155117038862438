import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServiceInputDropdown from "components/form/Services/DropdownInput";
import ServicesInput from "components/form/Services/Input";
import ServicesPlainDropdown from "components/form/Services/PlainDropdown";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import {
  useSelectDataProviderQuery,
  useSelectDataProviderByServiceQuery,
} from "features/Services/DataService/DataApiSlice";
import {
  setVariations,
  setFormVariation,
} from "features/Services/ServicesSlice";
import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useWatch } from "react-hook-form";
import { useDisclosure } from "@chakra-ui/react";
import SelectBeneficiaryModal from "components/Beneficiaries/SelectBeneficiaryModal";
import useRenderRenewalStatus from "hooks/useRenderRenewalStatus";

const DataForm = ({ control, setValue }: { control: any; setValue: any }) => {
  //dispatch
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dataServices = useAppSelector((state) => state.dataService as any);
  const variations = useAppSelector(
    (state) => state.services.variations as any
  );
  //Set Item
  const [serviceID, setServiceID] = useState("");
  const [serviceName, setServiceName] = useState<any>("");
  const service_id = useWatch({ control, name: "serviceID" });
  //variation
  const [variation, setVariation] = useState<any[]>([]);
  const { data } = useSelectDataProviderQuery(service_id?.serviceID, {
    skip: !service_id?.serviceID,
  });
  const { data: dataProvider } = useSelectDataProviderByServiceQuery(
    {
      name: serviceName,
      id: serviceID,
    },
    {
      skip: !serviceName || !serviceID,
    }
  );
  const [defaultPlan, setDefaultPlan] = useState<string>("");

  //SELECT PROVIDER
  useEffect(() => {
    if (data) {
      console.log(data);
      setDefaultPlan(data.content?.varations[0]?.name);
      setVariation(data.content?.varations);
      //dispatch variation
    }
  }, [data]);

  useEffect(() => {
    if (dataProvider) {
      dispatch(setVariations(dataProvider.content?.varations));
    }
  }, [dataProvider, dispatch]);

  const getItem = (item: string) => {
    const service_id = dataServices?.dataProviders?.find(
      (provider: any) => provider.name === item
    );

    setServiceID(service_id?.serviceID);
    setServiceName(service_id?.name as any);
  };

  const getVariation = (item: string) => {
    const variation_ = variations?.find(
      (variation: any) => variation?.name === item
    ) as any;

    dispatch(setFormVariation(variation_));

    // const service_id = dataServices?.dataProviders?.find((provider: any) => provider.name === item)

    // setServiceID(service_id?.serviceID);
    // setServiceName(service_id?.name as any);
  };

  // "variation_amount":100
  // const service = watch('serviceID');
  // const variation_code = watch('variation_code');
  // const billersCode = watch('billersCode');
  // const renewal = watch('renewal');
  // console.log(service , variation_code, billersCode , renewal ,)

  const dataOptions = dataServices?.dataProviders?.map((item: any) => ({
    ...item,
    value: item.serviceID,
    label: item.name,
    image: item.image,
  }));

  const variationOptions = variation?.map((item: any) => ({
    ...item,
    value: item.name,
    label: item.name,
  }));

  const { data: dataValue } = useAppSelector((state) => state.beneficiary);
  const renewalStatusData = useRenderRenewalStatus();

  return (
    <form className="flex items-center justify-center w-full">
      <div className={style.form}>
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={dataOptions}
          setValue={setValue}
          control={control}
          name="serviceID"
          defaultValue={dataServices[0]?.name}
          title={"Select Network"}
          placeholder={"Select network"}
        />
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={variationOptions}
          defaultValue={variationOptions[0]}
          title={"Select Network Plan"}
          name={"variation_code"}
          placeholder={"Select plan"}
          type={""}
          control={control}
          setValue={setValue}
        />
        <ServicesInput
          rules={{
            minLength: {
              value: 11,
              message: "Phone number should have at least 11 digits.",
            },
            required: "Field cannot be empty.",
          }}
          maxLength={11}
          label={"Enter Phone Number"}
          name={"billersCode"}
          placeholder={"08011111111"}
          onChange={() => {
            return null;
          }}
          value={""}
          control={control}
          defaultValue={dataValue as unknown as string}
          setValue={setValue}
        />
        <p className="hover:font-bold cursor-pointer" onClick={onOpen}>
          Select Beneficiary
        </p>
        <DropdownWithImage
          setValue={setValue}
          control={control}
          options={renewalStatusData}
          defaultValue={renewalStatusData[0]}
          name="renewal"
          title={"Renewal Status"}
          placeholder={"Set renewal status"}
        />
      </div>
      <SelectBeneficiaryModal
        category="Data"
        isOpen={isOpen}
        onClose={onClose}
      />
    </form>
  );
};

export default DataForm;
