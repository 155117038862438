import React, { FC } from 'react';
import { NotCheckedIcon, } from './TrckerImages/Icons'
import styles from './ProgressTracker.module.scss'

interface ProgressTrackerProps {
    levels: string[],
    steps: string[],


}


const ProgressTracker3: FC<ProgressTrackerProps> = ({
    levels, steps,
}) => {
    return (
        <div className='flex items-center'>
            {
                levels.map((level, index) => {
                    //check if index is the last level
                    const isLastLevel = index === levels.length - 1;
                    //get step by index
                    const step = steps[index];
                    return (
                        step ?
                            <div key={index} className='relative flex items-center' >
                                 {/* <div className='absolute top-[50%] h-[1rem]  w-[99%] bg-primary'></div> */}
                                <div>
                                    <img src="/Services/checked.svg" alt ="checked" />
                                    <h3 className={`${styles.title} ${isLastLevel ? styles.titleLastLevel : ""
                                        }`}> {level} </h3>
                                </div>
                                {isLastLevel ? null : <img src='/Services/blueline.svg' alt='' />}

                            </div>
                            :
                            <div key={index} className='relative flex items-center' >
                          
                                <div>
                                    <NotCheckedIcon />
                                    <h3 className={`${styles.title} ${isLastLevel ? styles.titleLastLevel : ""
                                        }`}> {level} </h3>
                                </div>
                                {isLastLevel ? null : <img className='' src='/Services/grayline.svg' alt='' />}


                            </div>
                    )



                })
            }

        </div>
    )

}

export default ProgressTracker3;
