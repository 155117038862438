import { authApi } from "app/ApiSlice";


const electricityServiceApiSlice = authApi.injectEndpoints({
    endpoints: (builder) => ({
        getElectricityProviders: builder.query({
            query: () => '/v1/bill/electricity/provider',
        }),
        getElectricityService: builder.query({
            query: (id) => `/v1/bill/electricity/service?service_id=${id}`
        }),
        verifyElectricityService: builder.mutation({
            query: (data) => ({
                url: "/v1/bill/electricity/verify",
                method: "POST",
                body: data
            }) ,
        }),
        payElectricityService: builder.mutation({
            query: (data) => ({
                url: "/v1/bill/electricity/buy",
                method: "POST",
                body: data
            }),
        })

    })
})

export const { useGetElectricityProvidersQuery,
     useGetElectricityServiceQuery
    , useVerifyElectricityServiceMutation ,
    usePayElectricityServiceMutation
} = electricityServiceApiSlice