import React, { useContext, useEffect } from "react";
import ActionText from "components/ActionText/ActionText";
import JaraLink from "components/link/JaraLink";
import DashboardContext from "context/Dashboard/DashboardContext";
import { useDisclosure } from "@chakra-ui/react";
import AccountDeletion from "./AccountDeletion";
import { useLogoutMutation } from "features/Auth/LogoutApiSlice";
import { useCookie } from "react-use";
// import { api, authApi } from "app/ApiSlice";
// import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";

const data = [
  {
    link: "",
    title: "BVN",
    icon: "/Security/security.svg",
  },
  {
    link: "/change-transaction-pin",
    title: "Change Transaction PIN",
    icon: "/Security/security.svg",
  },
  {
    link: "/reset-pin",
    title: "Change Login PIN",
    icon: "/Security/security.svg",
  },
];

const SecurityContainer = () => {
  const {
    setCurrentPagename,
    setBgContentColor,
    setBgSideMenuColor,
    setBgTopMenuColor,
  } = useContext(DashboardContext);

  useEffect(() => {
    setCurrentPagename("Security");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgContentColor ? setBgContentColor("#FAFAFA") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgSideMenuColor ? setBgSideMenuColor("#F5F5F5") : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setBgTopMenuColor ? setBgTopMenuColor("#FAFAFA") : null;
  }, [
    setBgContentColor,
    setBgSideMenuColor,
    setCurrentPagename,
    setBgTopMenuColor,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deletionStatus, setDeletionStatus] = React.useState<
    "confirm" | "processing"
  >("confirm");

  const [logout, { isLoading }] = useLogoutMutation();
  const [, , DeleteCookie] = useCookie("jara_access");
  // const dispatch = useDispatch();

  const handleDeleteAccount = async () => {
    try {
      await logout({})?.unwrap();
      DeleteCookie();
      // dispatch(api.util.resetApiState());
      // dispatch(authApi.util.resetApiState());
      setDeletionStatus("processing");
    } catch (error) {
      toast.error("Error deleting account");
    }

    // Perform account deletion logic here
  };
  return (
    <main className="p-2 md:px-[4.75rem] py-[3.375rem]">
      <section className="grid gap-4 2xl:gap-8 xl:gap-6 lg:gap-4">
        {data.map((item, index) => (
          <JaraLink
            noRedirect={true}
            key={index}
            link={item.link}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </section>
      <div className="grid place-content-center py-[2.5rem] md:py-[3.8125rem] cursor-pointer ">
        <ActionText onClick={onOpen as any} text={"Delete Account"} />
      </div>
      <AccountDeletion
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDeleteAccount}
        deletionStatus={deletionStatus}
        isLoading={isLoading}
      />
    </main>
  );
};

export default SecurityContainer;
