import React, { useState, useEffect } from "react";
import style from "./form.module.scss";
import ServiceInputDropdown from "components/form/Services/DropdownInput";
import ServicesInput from "components/form/Services/Input";
import ServicesPlainDropdown from "components/form/Services/PlainDropdown";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import {
  useSelectCableProviderQuery,
  useSelectCableProviderByServiceQuery,
} from "features/Services/CableService/CableApiSlice";
import {
  setVariations,
  setFormVariation,
} from "features/Services/ServicesSlice";
import ReactLoading from "react-loading";
import DropdownWithImage from "components/form/Services/ServiceSelect";
import { useWatch } from "react-hook-form";
import { useDisclosure } from "@chakra-ui/react";
import SelectBeneficiaryModal from "components/Beneficiaries/SelectBeneficiaryModal";
import useRenderRenewalStatus from "hooks/useRenderRenewalStatus";

const CableTVForm = ({
  control,
  setValue,
}: {
  control: any;
  setValue: any;
}) => {
  //dispatch
  const dispatch = useAppDispatch();
  const watch = useWatch({
    control,
    name: "serviceID",
  });

  const cableServices = useAppSelector((state) => state.cableService as any);
  const variations = useAppSelector(
    (state) => state.services.variations as any
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  //variation
  const [variation, setVariation] = useState<any[]>([]);
  const { data } = useSelectCableProviderQuery(watch?.serviceID, {
    skip: !watch?.serviceID,
  });
  const { data: cableProvider, isLoading } =
    useSelectCableProviderByServiceQuery(
      { name: watch?.label, id: watch?.serviceID },
      {
        skip: !watch?.serviceID,
      }
    );
  const [defaultPlan, setDefaultPlan] = useState<string>("");

  //SELECT PROVIDER
  useEffect(() => {
    if (data) {
      setVariation(data.content?.varations);
      //dispatch variation
    }
  }, [data]);

  useEffect(() => {
    if (cableProvider) {
      dispatch(setVariations(cableProvider.content?.varations));
    }
  }, [cableProvider, dispatch]);

  const watchVariation = useWatch({
    control,
    name: "variation_code",
  });
  useEffect(() => {
    dispatch(setFormVariation(watchVariation));
  }, [dispatch, watchVariation]);

  // console.log(watch?.serviceID

  // "variation_amount":100
  // const service = watch('serviceID');
  // const variation_code = watch('variation_code');
  // const billersCode = watch('billersCode');
  // const renewal = watch('renewal');
  // console.log(service , variation_code, billersCode , renewal ,)

  const cableOptions = cableServices?.cableProviders?.map((item: any) => ({
    ...item,
    value: item.serviceID,
    label: item.name,
    image: item.image,
  }));

  const variationOptions = variation?.map((item: any) => ({
    ...item,
    value: item.name,
    label: item.name,
  }));

  const { tv } = useAppSelector((state) => state.beneficiary);
  const renewalStatusData = useRenderRenewalStatus();


  return (
    <form className="flex items-center justify-center relative w-full">
      {isLoading ? (
        <div className="grid place-content-center place-items-center absolute right-[50%] top-0">
          {" "}
          <ReactLoading
            type={"spinningBubbles"}
            color={"#246BFD"}
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <div className={style.form}>
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={cableOptions}
          control={control}
          name="serviceID"
          title={"Select Provider"}
          placeholder={"Select provider"}
        />
        <DropdownWithImage
          rules={{
            required: "Field cannot be empty.",
          }}
          options={variationOptions}
          defaultValue={variationOptions[0]}
          title={"Select Plan"}
          name={"variation_code"}
          placeholder={"Select plan"}
          type={""}
          control={control}
        />
        <ServicesInput
          rules={{
            // minLength: {
            //     value: 11, message: 'Phone number should have at least 11 digits.'
            // },
            required: "Field cannot be empty.",
          }}
          maxLength={11}
          label={"Smart Card Number or Phone Number"}
          name={"billersCode"}
          placeholder={"Smart Card Number or Phone Number"}
          onChange={() => {
            return null;
          }}
          value={""}
          control={control}
          setValue={setValue}
          defaultValue={tv as unknown as string}
        />
        <p className="hover:font-bold cursor-pointer" onClick={onOpen}>
          Select Beneficiary
        </p>
        <DropdownWithImage
          setValue={setValue}
          control={control}
          options={renewalStatusData}
          defaultValue={renewalStatusData[0]}
          name="renewal"
          title={"Renewal Status"}
          placeholder={"Set renewal status"}
        />
      </div>
      <SelectBeneficiaryModal
        isOpen={isOpen}
        onClose={onClose}
        category="tv"
      />
    </form>
  );
};

export default CableTVForm;
