export default function extractAmountAndData(inputString: string): { amount: string, dataSize: string } | null {
    // Check for null or undefined input
    if (inputString === null || inputString === undefined) {
      return null;
    }

    console.log(inputString)

    const stringArray = inputString.split(" ");
    let amount = "";
    let dataSize = "";
    for (let i = 0; i < stringArray.length; i++) {
        //if amount starts with N or amount , next index is Naira or naira
        if ((stringArray[i].startsWith('N') && stringArray[i]  !== 'Naira')|| stringArray[i + 1] === "Naira" || stringArray[i + 1] === "naira") {


        amount = stringArray[i];
      }

 else if (stringArray[i].includes("MB") || stringArray[i].includes("GB") || stringArray[i].includes("TB") || stringArray[i].includes("PB") || stringArray[i].includes("EB")) {
        dataSize = stringArray[i];
      }

    }
    return { amount, dataSize };

}







